import './App.css';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Login from './Components/Login/Login';
import MyProfile from './Components/MyProfile/MyProfile';
import ProtectedRoute from './Utils/ProtectedRoute';
import Dashboard from './Components/Dashboard/Dashboard';
import { useDispatch, useSelector } from "react-redux";
import ForgotPassword from './Components/ForgotPassword/ForgotPassword';
import OtpValidation from './Components/OtpValidation/OtpValidation';
import ResetPassword from './Components/ResetPassword/ResetPassword';
import Header from './layout/Header/Header';
import Notifications from './Components/Notifications/Notifications';
import ChangePassword from './Components/ChangePassword/ChangePassword';
import { useEffect } from 'react'
import Products from './Components/AddShipment/Products/Products';
import TransportationMode from './Components/AddShipment/TransportationMode/TransportationMode';
import SubcategoryOfProduct from './Components/AddShipment/SubcategoryOfProduct/SubcategoryOfProduct';
import PackageDimensionDetails from './Components/AddShipment/PackagingDimensionDetails/PackagingDimensionDetails';
import PalletDetails from './Components/AddShipment/PalletDetails/PalletDetails';
import PalletDimensionDetail from './Components/AddShipment/PalletDimensionDetail/PalletDimensionDetail';
import PalletWrapDetails from './Components/AddShipment/PalletWrapDetails/PalletWrapDetails';
import PackagingDeatails from './Components/AddShipment/PackagingDetails/PackagingDetails';
import Dunnage from './Components/AddShipment/Dunnage/Dunnage';
import DunnageSubcategory from './Components/AddShipment/DunnageSubcategory/DunnageSubcategory';
import VisualizationOfLoadedTruck from './Components/AddShipment/VisualizationOfLoadedTruck/VisualizationOfLoadedTruck';
import { loginData } from './Store/loginReducer';
import InspectionnReport from './Components/InspectionReport/InspectionnReport';
import EditInspectionReport from './Components/InspectionReport/EditInspectionReport';
import DestinationReport from './Components/DestinationReport/DestinationReport';
import AddDestinationReport from './Components/DestinationReport/AddDestinationReport';
import EditDestinationReport from './Components/DestinationReport/EditDestinationReport';
import EditShipment from './Components/EditShipment/EditShipment';
import ViewLogs from './Components/ViewLogs/ViewLogs';

function App() {
  const location = useLocation();
  const dispatch = useDispatch()
  let { loggedIn, userData } = useSelector((state: any) => state.user.data);
  let { shipmentData } = useSelector((state: any) => state.shipment.data);
  let arr = JSON.parse(localStorage.getItem("storageData"));

  //  Code for clearing browsers cache
  // useEffect(() => {
  //   if ('caches' in window) {
  //     caches.keys().then((names) => {
  //       names.forEach((name) => {
  //         caches.delete(name);
  //       });
  //     });
  //   }
  // }, []);

  if (arr) {
    loggedIn = true;
    if (userData.length === 0) {
      dispatch(loginData(arr[0]))
    }
  }
  else loggedIn = false;

  useEffect(() => {
    if (arr) loggedIn = true;
    else loggedIn = false;
  }, [loggedIn]);

  userData = JSON.parse(localStorage.getItem("storageData"));
  if (userData && userData.length > 0) {
    if (location.pathname === '/login') {
      return <Navigate to="/dashboard" replace={true} />;
    }
  }

  return (
    <div className="App">
      {loggedIn && <Header />}
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/otp-validation' element={<OtpValidation />} />
        <Route path='/my-profile' element={<ProtectedRoute isLoggedIn={loggedIn} ><MyProfile /></ProtectedRoute>} />
        <Route path='/change-password' element={<ProtectedRoute isLoggedIn={loggedIn} ><ChangePassword /></ProtectedRoute>} />
        <Route path='/notifications' element={<ProtectedRoute isLoggedIn={loggedIn} ><Notifications /></ProtectedRoute>} />
        <Route path='/view-logs/:shipmentId' element={<ProtectedRoute isLoggedIn={loggedIn} ><ViewLogs /></ProtectedRoute>} />
        <Route path='/dashboard' element={<ProtectedRoute isLoggedIn={loggedIn} ><Dashboard /></ProtectedRoute>} />
        <Route path='/shipment-mode' element={<ProtectedRoute isLoggedIn={loggedIn} ><TransportationMode /></ProtectedRoute>} />
        <Route path='/add-new-product' element={<ProtectedRoute isLoggedIn={loggedIn} ><Products /></ProtectedRoute>} />
        <Route path='/subcategories-of-product' element={<ProtectedRoute isLoggedIn={loggedIn} ><SubcategoryOfProduct /></ProtectedRoute>} />
        <Route path='/packaging-details' element={<ProtectedRoute isLoggedIn={loggedIn} ><PackagingDeatails /></ProtectedRoute>} />
        <Route path='/packaging-dimension-details' element={<ProtectedRoute isLoggedIn={loggedIn} ><PackageDimensionDetails /></ProtectedRoute>} />
        <Route path='/pallet-details' element={<ProtectedRoute isLoggedIn={loggedIn} ><PalletDetails /></ProtectedRoute>} />
        <Route path='/pallet-dimension-details' element={<ProtectedRoute isLoggedIn={loggedIn} ><PalletDimensionDetail /></ProtectedRoute>} />
        <Route path='/pallet-wrap-details' element={<ProtectedRoute isLoggedIn={loggedIn} ><PalletWrapDetails /></ProtectedRoute>} />
        <Route path='/dunnage' element={<ProtectedRoute isLoggedIn={loggedIn} ><Dunnage /></ProtectedRoute>} />
        <Route path='/dunnage-subcategories' element={<ProtectedRoute isLoggedIn={loggedIn} ><DunnageSubcategory /></ProtectedRoute>} />
        <Route path={`/visualization-of-loaded-truck/:shipmentId/:userId`} element={<ProtectedRoute isLoggedIn={loggedIn} ><VisualizationOfLoadedTruck /></ProtectedRoute>} />
        <Route path='/inspection-report/:id' element={<ProtectedRoute isLoggedIn={loggedIn} ><InspectionnReport /></ProtectedRoute>} />
        <Route path='/inspection-report/:id/edit' element={<ProtectedRoute isLoggedIn={loggedIn} ><EditInspectionReport /></ProtectedRoute>} />
        <Route path='/destination-report/:id' element={<ProtectedRoute isLoggedIn={loggedIn} ><DestinationReport /></ProtectedRoute>} />
        <Route path='/destination-report/:id/add' element={<ProtectedRoute isLoggedIn={loggedIn} ><AddDestinationReport /></ProtectedRoute>} />
        <Route path='/destination-report/:id/edit' element={<ProtectedRoute isLoggedIn={loggedIn} ><EditDestinationReport /></ProtectedRoute>} />
        <Route path='/edit-shipment/:shipmentId' element={<ProtectedRoute isLoggedIn={loggedIn} ><EditShipment /></ProtectedRoute>} />
        <Route path='*' element={<Navigate to={loggedIn ? '/dashboard' : '/login'} />} />
      </Routes>
    </div>
  );
}

export default App;
