import { useState, useEffect } from 'react'
import backIcon from '../../Assets/images/back-icon.svg'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import URLS from '../../Helpers/Constants';
import { Icon } from 'semantic-ui-react';
import { enqueueSnackbar } from 'notistack';

function EditDestinationReport() {
  const [destinationInput, setDestinationInput] = useState({
    "loadShift": {
      "images": [],
      "description": ""
    },
    "packagingFailure": {
      "images": [],
      "description": ""
    },
    "palletFailure": {
      "images": [],
      "description": ""
    },
    "dunnageFailure": {
      "images": [],
      "description": ""
    },
    "waterDamage": {
      "images": [],
      "description": ""
    },
    "excessiveImpact": {
      "images": [],
      "description": ""
    },
    "other": {
      "images": [],
      "description": ""
    }
  });

  const { id } = useParams();
  let arr = JSON.parse(localStorage.getItem("storageData"));
  let GetToken = ""
  if (arr) GetToken = `?access_token=${arr[0].id}`;
  const navigate = useNavigate();

  const getDestinationReport = (orderId: any) => {
    axios.post(`${URLS.BACKEND_URL}/api/destination-reports/get-destination-reports${GetToken}`, { orderId }).then((res) => {
      setDestinationInput(res.data.message[0]);
    }).catch((e) => {
      if (e.response.data.error.message === 'could not find accessToken') {
        localStorage.clear();
        navigate('/login');
      }
    });
  }

  useEffect(() => {
    getDestinationReport(id);
  }, []);

  const addInput = (e) => {
    const { name, value } = e.target;
    setDestinationInput((prevInput) => ({
      ...prevInput,
      [name]: {
        ...prevInput[name],
        description: value,
      },
    }));
  };

  const handleImageAdd = (e: any) => {
    if (e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/tiff") {
      if (e.target.files[0].size <= 15728640) {
        axios.post(`${URLS.BACKEND_URL}/api/profileImages/destination-report-images/upload`, { profile: e.target.files[0] }, { headers: { "Content-Type": "multipart/form-data" } })
          .then((res) => {
            let imageName = res.data.result.files.profile[0].name;
            let data = { ...destinationInput };
            data[e.target.name].images.push(imageName);
            setDestinationInput(data);
          })
          .catch((err) => {
            console.log(err)
          })
      }
      else {
        enqueueSnackbar("Please enter an image having size less than 15 mb!",
          { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
      }
    }
    else {
      enqueueSnackbar("Only images are accepted as destination report image. And type must be jpg/jpeg/png/tiff!",
        { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
    }
  }

  // Updating the current report
  const handleUpdateReport = () => {
    axios.patch(`${URLS.BACKEND_URL}/api/destination-reports/${arr[0].destinationReportId}${GetToken}`,
      {
        ...destinationInput
      }).then((res) => {
        if (res) {
          navigate(`/destination-report/${id}`)
        }
      }).catch((err) => console.log(err))
  }

  // Handle cancel
  const handleCancel = (str: any, index: any) => {
    let data = { ...destinationInput }
    data[str].images.splice(index, 1);
    setDestinationInput({ ...data })
  }
  return (
    <div className="main-content">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="goto-back">
              <Link to={`/destination-report/${id}`}>
                <img className="" src={backIcon} alt="" />
                <span> Edit Report </span>
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          {/* <div className="col-lg-9 mx-auto">
            <div className="form-group custom-input">
              <select className="form-select" aria-label="Default select example">
                <option selected>Pallet 1</option>
              </select>
            </div>
          </div> */}
          <div className="row mx-auto">
            <div className="col-lg-9 mx-auto">
              <div id="accordionExample" className="accordion">

                <div className="card">
                  <div id="headingOne" className="card-header bg-white shadow-sm border-0">
                    <h2 className="mb-0">
                      <button type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne"
                        className="btn text-dark font-weight-bold collapsible-link">Load Shift</button>
                    </h2>
                  </div>
                  <div id="collapseOne" aria-labelledby="headingOne" data-parent="#accordionExample" className="collapse show cus-accordion">
                    <div className="card-body p-4">
                      <div className="add-report">
                        <div className="cmt-box">
                          <div className="form-group">
                            <label htmlFor="comment">Comment:</label>
                            <textarea className="form-control" rows={5} name='loadShift' id="comment" value={destinationInput.loadShift.description} onChange={addInput}></textarea>
                          </div>
                        </div>
                        <div className="pic-details">
                          <div className="no-of-pic float-start image-container">
                            <ul>
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                {destinationInput.loadShift.images.map((data, index) => (
                                  <div style={{ position: "relative", height: "100%", width: "100%", display: "flex", flexDirection: "row" }}>
                                    <li>
                                      <Icon name='cancel' size='small' className='cancel-mark' onClick={() => handleCancel("loadShift", index)} />
                                      <a href={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/destination-report-images/download/${data}`} target="_blank" rel='noreferrer'>
                                        <img src={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/destination-report-images/download/${data}`} alt="" />
                                      </a>
                                    </li>
                                  </div>
                                ))}
                              </div>
                            </ul>
                          </div>
                          <div className="p-image2 float-end">
                            <i className="fa fa-camera upload-button" onClick={() => document.getElementById("imageadd1").click()}></i>
                            <input className="file-upload" name='loadShift' type="file" id='imageadd1' accept="image/png, image/gif, image/jpeg" onChange={(e) => handleImageAdd(e)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div id="headingTwo" className="card-header bg-white shadow-sm border-0">
                    <h2 className="mb-0">
                      <button type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                        aria-controls="collapseTwo"
                        className="btn collapsed text-dark font-weight-bold collapsible-link">Primary packaging failure</button>
                    </h2>
                  </div>
                  <div id="collapseTwo" aria-labelledby="headingTwo" data-parent="#accordionExample" className="collapse cus-accordion">
                    <div className="card-body p-4">
                      <div className="add-report">
                        <div className="cmt-box">
                          <div className="form-group">
                            <label htmlFor="comment">Comment:</label>
                            <textarea className="form-control" rows={5} id="comment" name='packagingFailure' value={destinationInput.packagingFailure.description} onChange={addInput}></textarea>
                          </div>
                        </div>
                        <div className="pic-details">
                          <div className="no-of-pic float-start image-container">
                            <ul>
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                {destinationInput.packagingFailure.images.map((data, index) => (
                                  <div style={{ position: "relative", height: "100%", width: "100%", display: "flex", flexDirection: "row" }}>
                                    <li>
                                      <Icon name='cancel' size='small' className='cancel-mark' onClick={() => handleCancel("packagingFailure", index)} />
                                      <a href={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/destination-report-images/download/${data}`} target="_blank" rel='noreferrer'>
                                        <img src={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/destination-report-images/download/${data}`} alt="" />
                                      </a>
                                    </li>
                                  </div>
                                ))}
                              </div>
                            </ul>
                          </div>
                          <div className="p-image2 float-end">
                            <i className="fa fa-camera upload-button" onClick={() => document.getElementById("imageadd2").click()}></i>
                            <input className="file-upload" name='packagingFailure' type="file" id='imageadd2' accept="image/png, image/gif, image/jpeg" onChange={(e) => handleImageAdd(e)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- End -->  */}

                {/* <!-- Accordion item 3 --> */}
                <div className="card">
                  <div id="headingThree" className="card-header bg-white shadow-sm border-0">
                    <h2 className="mb-0">
                      <button type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                        aria-controls="collapseThree"
                        className="btn collapsed text-dark font-weight-bold collapsible-link">Pallet Failure</button>
                    </h2>
                  </div>
                  <div id="collapseThree" aria-labelledby="headingThree" data-parent="#accordionExample" className="collapse cus-accordion">
                    <div className="card-body p-4">
                      <div className="add-report">
                        <div className="cmt-box">
                          <div className="form-group">
                            <label htmlFor="comment">Comment:</label>
                            <textarea className="form-control" rows={5} id="comment" name='palletFailure' value={destinationInput.palletFailure.description} onChange={addInput}></textarea>
                          </div>
                        </div>
                        <div className="pic-details">
                          <div className="no-of-pic float-start image-container">
                            <ul>
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                {destinationInput.palletFailure.images.map((data, index) => (
                                  <div style={{ position: "relative", height: "100%", width: "100%", display: "flex", flexDirection: "row" }}>
                                    <li>
                                      <Icon name='cancel' size='small' className='cancel-mark' onClick={() => handleCancel("palletFailure", index)} />
                                      <a href={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/destination-report-images/download/${data}`} target="_blank" rel='noreferrer'>
                                        <img src={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/destination-report-images/download/${data}`} alt="" />
                                      </a>
                                    </li>
                                  </div>
                                ))}
                              </div>
                            </ul>
                          </div>
                          <div className="p-image2 float-end">
                            <i className="fa fa-camera upload-button" onClick={() => document.getElementById("imageadd3").click()}></i>
                            <input className="file-upload" name='palletFailure' type="file" id='imageadd3' accept="image/png, image/gif, image/jpeg" onChange={(e) => handleImageAdd(e)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- End -->  */}

                {/* <!-- Accordion item 4 --> */}
                <div className="card">
                  <div id="headingThree" className="card-header bg-white shadow-sm border-0">
                    <h2 className="mb-0">
                      <button type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false"
                        aria-controls="collapseThree"
                        className="btn collapsed text-dark font-weight-bold collapsible-link">Dunnage Failure</button>
                    </h2>
                  </div>
                  <div id="collapseFour" aria-labelledby="headingThree" data-parent="#accordionExample" className="collapse cus-accordion">
                    <div className="card-body p-4">
                      <div className="add-report">
                        <div className="cmt-box">
                          <div className="form-group">
                            <label htmlFor="comment">Comment:</label>
                            <textarea className="form-control" rows={5} id="comment" name='dunnageFailure' value={destinationInput.dunnageFailure.description} onChange={addInput}></textarea>
                          </div>
                        </div>
                        <div className="pic-details">
                          <div className="no-of-pic float-start image-container">
                            <ul>
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                {destinationInput.dunnageFailure.images.map((data, index) => (
                                  <div style={{ position: "relative", height: "100%", width: "100%", display: "flex", flexDirection: "row" }}>
                                    <li>
                                      <Icon name='cancel' size='small' className='cancel-mark' onClick={() => handleCancel("dunnageFailure", index)} />
                                      <a href={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/destination-report-images/download/${data}`} target="_blank" rel='noreferrer'>
                                        <img src={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/destination-report-images/download/${data}`} alt="" />
                                      </a>
                                    </li>
                                  </div>
                                ))}
                              </div>
                            </ul>
                          </div>
                          <div className="p-image2 float-end">
                            <i className="fa fa-camera upload-button" onClick={() => document.getElementById("imageadd4").click()}></i>
                            <input className="file-upload" name='dunnageFailure' type="file" id='imageadd4' accept="image/png, image/gif, image/jpeg" onChange={(e) => handleImageAdd(e)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- End -->  */}

                {/* <!-- Accordion item 5 --> */}
                <div className="card">
                  <div id="headingThree" className="card-header bg-white shadow-sm border-0">
                    <h2 className="mb-0">
                      <button type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false"
                        aria-controls="collapseThree"
                        className="btn collapsed text-dark font-weight-bold collapsible-link">Water Damage</button>
                    </h2>
                  </div>
                  <div id="collapseFive" aria-labelledby="headingThree" data-parent="#accordionExample" className="collapse cus-accordion">
                    <div className="card-body p-4">
                      <div className="add-report">
                        <div className="cmt-box">
                          <div className="form-group">
                            <label htmlFor="comment">Comment:</label>
                            <textarea className="form-control" rows={5} id="comment" name='waterDamage' value={destinationInput.waterDamage.description} onChange={addInput}></textarea>
                          </div>
                        </div>
                        <div className="pic-details">
                          <div className="no-of-pic float-start image-container">
                            <ul>
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                {destinationInput.waterDamage.images.map((data, index) => (
                                  <div style={{ position: "relative", height: "100%", width: "100%", display: "flex", flexDirection: "row" }}>
                                    <li>
                                      <Icon name='cancel' size='small' className='cancel-mark' onClick={() => handleCancel("waterDamage", index)} />
                                      <a href={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/destination-report-images/download/${data}`} target="_blank" rel='noreferrer'>
                                        <img src={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/destination-report-images/download/${data}`} alt="" />
                                      </a>
                                    </li>
                                  </div>
                                ))}
                              </div>
                            </ul>
                          </div>
                          <div className="p-image2 float-end">
                            <i className="fa fa-camera upload-button" onClick={() => document.getElementById("imageadd5").click()}></i>
                            <input className="file-upload" name='waterDamage' type="file" id='imageadd5' accept="image/png, image/gif, image/jpeg" onChange={(e) => handleImageAdd(e)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div id="headingThree" className="card-header bg-white shadow-sm border-0">
                    <h2 className="mb-0">
                      <button type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false"
                        aria-controls="collapseThree"
                        className="btn collapsed text-dark font-weight-bold collapsible-link">Excessive Impact</button>
                    </h2>
                  </div>
                  <div id="collapseSix" aria-labelledby="headingThree" data-parent="#accordionExample" className="collapse cus-accordion">
                    <div className="card-body p-4">
                      <div className="add-report">
                        <div className="cmt-box">
                          <div className="form-group">
                            <label htmlFor="comment">Comment:</label>
                            <textarea className="form-control" rows={5} id="comment" name='excessiveImpact' value={destinationInput.excessiveImpact.description} onChange={addInput}></textarea>
                          </div>
                        </div>
                        <div className="pic-details">
                          <div className="no-of-pic float-start image-container">
                            <ul>
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                {destinationInput.excessiveImpact.images.map((data, index) => (
                                  <div style={{ position: "relative", height: "100%", width: "100%", display: "flex", flexDirection: "row" }}>
                                    <li>
                                      <Icon name='cancel' size='small' className='cancel-mark' onClick={() => handleCancel("excessiveImpact", index)} />
                                      <a href={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/destination-report-images/download/${data}`} target="_blank" rel='noreferrer'>
                                        <img src={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/destination-report-images/download/${data}`} alt="" />
                                      </a>
                                    </li>
                                  </div>
                                ))}
                              </div>
                            </ul>
                          </div>
                          <div className="p-image2 float-end">
                            <i className="fa fa-camera upload-button" onClick={() => document.getElementById("imageadd6").click()}></i>
                            <input className="file-upload" name='excessiveImpact' type="file" id='imageadd6' accept="image/png, image/gif, image/jpeg" onChange={(e) => handleImageAdd(e)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div id="headingThree" className="card-header bg-white shadow-sm border-0">
                    <h2 className="mb-0">
                      <button type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false"
                        aria-controls="collapseThree"
                        className="btn collapsed text-dark font-weight-bold collapsible-link">Other</button>
                    </h2>
                  </div>
                  <div id="collapseSeven" aria-labelledby="headingThree" data-parent="#accordionExample" className="collapse cus-accordion">
                    <div className="card-body p-4">
                      <div className="add-report">
                        <div className="cmt-box">
                          <div className="form-group">
                            <label htmlFor="comment">Comment:</label>
                            <textarea className="form-control" rows={5} id="comment" name='other' value={destinationInput.other.description} onChange={addInput}></textarea>
                          </div>
                        </div>
                        <div className="pic-details">
                          <div className="no-of-pic float-start image-container">
                            <ul>
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                {destinationInput.other.images.map((data, index) => (
                                  <div style={{ position: "relative", height: "100%", width: "100%", display: "flex", flexDirection: "row" }}>
                                    <li>
                                      <Icon name='cancel' size='small' className='cancel-mark' onClick={() => handleCancel("other", index)} />
                                      <a href={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/destination-report-images/download/${data}`} target="_blank" rel='noreferrer'>
                                        <img src={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/destination-report-images/download/${data}`} alt="" />
                                      </a>
                                    </li>
                                  </div>
                                ))}
                              </div>
                            </ul>
                          </div>
                          <div className="p-image2 float-end">
                            <i className="fa fa-camera upload-button" onClick={() => document.getElementById("imageadd7").click()}></i>
                            <input className="file-upload" name='other' type="file" id='imageadd7' accept="image/png, image/gif, image/jpeg" onChange={(e) => handleImageAdd(e)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- End -->  */}

              </div>
              {/* <!-- End -->  */}
            </div>
          </div>
          <div className="row mt-2 mx-auto">
            <div className="col-md-12">
              <div className="form-group custom-input mt-4 text-center">
                <button className="btn-style-one text-center" onClick={handleUpdateReport} > Save </button>
                <button type="button" className="btn btn-secondary cancel-report" data-dismiss="modal" onClick={() => navigate(`/destination-report/${id}`)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default EditDestinationReport