import { Link, useNavigate } from 'react-router-dom';
import './Login.css';
import Logo from '../../Assets/img/logo.png';
import { FieldGroup, FormBuilder, Validators, AbstractControl, FieldControl } from 'react-reactive-form';
import { login } from '../../Services/commonService';
import { loginData } from '../../Store/loginReducer';
import { useDispatch } from "react-redux";
import { useSnackbar } from 'notistack'

function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar()
    const loginForm = FormBuilder.group({
        email: ["", [Validators.required, Validators.email]],
        password: ["", [Validators.required, Validators.minLength(8)]]
    });
    // ------------ Function for submitting form data -----------------------------------------------------

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (loginForm.valid) {
            let data = await login({ ...loginForm.value, role: "shipping_manager" });
            if (data.user) {
                dispatch(loginData(data));
                navigate('/dashboard');
            } else {
                enqueueSnackbar(data.response.data.error.message,
                { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            }
        } else {
            loginForm.markAsTouched();
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 d-flex align-items-center justify-content-center logo-bg">
                        <div className="logo text-center">
                            <Link to="#"><img src={Logo} alt="Logo" /></Link>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="login_right_side">
                            <div className="login_form">
                                <h2 className="text-white text-center pb-5">Login</h2>
                                <FieldGroup
                                    control={loginForm}
                                    render={({ pristine, invalid, pending, value }: AbstractControl) => (
                                        <form onSubmit={handleSubmit}>
                                            <FieldControl
                                                name='email'
                                                render={({ handler, touched, hasError }: AbstractControl) => (
                                                    <div className="form-group">
                                                        <label htmlFor="email">Email</label>
                                                        <input type="email" className="form-control form-control1 mb-0" {...handler()} id="email" />
                                                        <div className='mb-2'>
                                                            <span className='errorText'>
                                                                {(touched &&
                                                                    ((hasError("required") && "Email is required") ||
                                                                        (hasError("email") &&
                                                                            "Please enter a valid email")))}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                            <FieldControl
                                                name='password'
                                                render={({ handler, touched, hasError }: AbstractControl) => (
                                                    <div className="form-group">
                                                        <label htmlFor="password">Password</label>
                                                        <div className="position-relative d-flex align-items-center">
                                                            <input type="password" className="form-control form-control1 password_input mb-0" {...handler()} id="password" />
                                                        </div>
                                                        <div className="invalid-feedback d-block errorText mb-2">
                                                            <span className='errorText'>
                                                                {(touched &&
                                                                    ((hasError("required") && "Password is required")))}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                            <div className="form-group d-flex align-items-center">
                                                <Link to='/forgot-password' className="secondry-color">Forgot password?</Link>
                                            </div>
                                            <div className="form-group custom-input">
                                                <button type="submit" className="btn-style-one btn btn_default text-center afterhover">
                                                    {/* <div className="spinner-border text-light align-top" role="status"></div> */}
                                                    <span>Submit</span>
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;