import { useState, useEffect } from 'react';
import ShipItLogo from '../../Assets/images/ship-it-logo.png';
import HomeIcon from '../../Assets/images/home-icon.svg';
import PlusIcon from '../../Assets/images/plus-icon.svg';
import DropIcon from '../../Assets/images/drop-arrow.svg';
import Notification from '../../Assets/images/notification.svg';
import ChangePass from '../../Assets/images/change-pass.svg';
import Settings from '../../Assets/images/settings.svg';
import ProfileSettings from '../../Assets/images/profile-setting.svg';
import PrivacyPolicy from '../../Assets/images/privacy-policy.svg';
import Help from '../../Assets/images/help.svg';
import Logout from '../../Assets/images/logout.svg';
import "./Header.css";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { logOutData } from '../../Store/loginReducer';
import URLS from '../../Helpers/Constants'
import { enqueueSnackbar } from 'notistack';
function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [userData, setUserData] = useState({ id: "" });
  // let { shipmentMode } = useSelector((state: any) => state.shipment.data.shipmentData);
  let arr = JSON.parse(localStorage.getItem("storageData"));
  useEffect(() => {
    if (arr) {
      setUserData(arr[0]);
    };
  }, [])

  let handleLogOut = () => {
    axios.post(`${URLS.BACKEND_URL}/api/users/logout?access_token=${userData?.id}`).then((res: any) => {
      if (res.status === 204) {
        navigate('/login');
        dispatch(logOutData(false))
        localStorage.clear();
      }
    }).catch((e) => console.log(e))
  }
  // Function for showing message 
  const handleShowMessage = () => {
    enqueueSnackbar("Coming Soon ...!!", { variant: 'warning', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <header>
              <div className="row d-flex align-items-center header-container">
                <div className="col-2 text-start">
                  <div className="logo-header d-flex align-items-center pl-3"> <img src={ShipItLogo} alt="" /></div>
                </div>
                <div className="col-4 text-start">
                  <div className="d-flex align-items-center pl-3 home"> <Link to="/dashboard"><img className="pr-2" src={HomeIcon} style={{ marginBottom: 5 }} alt='' />&nbsp; Home </Link></div>
                </div>
                <div className="col-6 text-end">
                  <div className="User_profile float-right">
                    <nav className="navbar navbar-light navbar-expand-sm">
                      <div className="collapse navbar-collapse" id="navbar-list-4">
                        <ul className="navbar-nav mng-top-right">
                          {arr[0]?.user?.roleName === "shipping_manager" && <li>
                            <Link className="add-btn d-flex justify-content-center" to="/shipment-mode">
                              <img src={PlusIcon} alt="" />
                              &nbsp;Add Shipment
                            </Link>
                          </li>}
                          <li className="nav-item dropdown"> <a className="nav-link dropdown-toggle float-start" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <span className="user-drop"><img src={Settings} alt="" /></span> </a>
                            <div className="dropdown-menu custom-top-dropdwon-menu" aria-labelledby="navbarDropdownMenuLink">
                              <img src={DropIcon} className="drop-arrow" alt='' />
                              <Link className="dropdown-item" to="/my-profile">
                                <img src={ProfileSettings} className="mr-2" alt='' /> &nbsp; Profile Settings
                              </Link>
                              <Link className="dropdown-item" to="/change-password">
                                <img src={ChangePass} className="mr-2" alt='' /> &nbsp; Change Password
                              </Link>
                              <Link className="dropdown-item" to="#" onClick={handleShowMessage}>
                                <img src={PrivacyPolicy} className="mr-2" alt='' /> &nbsp; Privacy & Policy
                              </Link>
                              <Link className="dropdown-item" to="#" onClick={handleShowMessage}>
                                <img src={Help} className="mr-2" alt='' /> &nbsp; Help
                              </Link>
                              <Link to="#" className="dropdown-item logout-custom" onClick={handleLogOut}>
                                <img src={Logout} className="mr-1" alt='' /> &nbsp; Log Out</Link>
                            </div>
                          </li>
                          <li>
                            <div className="notification">
                              {/* <p className="notification-count">2</p> */}
                              <Link to="/notifications"><img src={Notification} className="mr-1" /></Link>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </header>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header