import { useEffect } from 'react';
import './Dashboard.css';
import Shipments from '../Shipments/Shipments';
import '../../Assets/css/style.css'
import { useDispatch } from 'react-redux';
import { emptyShipmentReducer } from '../../Store/shipmentReducer';

const Dashboard = () => {
  const dispatch = useDispatch();
  // remove session storage and shipment reducer
  useEffect(() => {
    window.sessionStorage.clear();
    dispatch(emptyShipmentReducer(
      {
        products: [],
        subcategories: [],
        packagingDetails: {},
        packageDimensions: [],
        palletDetail: [],
        palletDimensions: {},
        palletWrapDetails: [],
        dunnage: [],
        dunnageSubcategory: [],
        otherSpecifications: [],
        palletWrapSpecifications: {}
      }
    ));
  }, []);

  const handlePage = (page: any) => {

  }
  return (
    <>
      <Shipments />
    </>
  )
}

export default Dashboard;