import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import ActiveSteps from '../ActiveSteps/ActiveSteps';
import backIcon from '../../../Assets/images/back-icon.svg'
import axios from 'axios';
import URLs from '../../../Helpers/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { addPalletDetail, addPalletDimensions, addShipmentId, emptyShipmentReducer, removePalletDetail } from '../../../Store/shipmentReducer';
import { enqueueSnackbar } from 'notistack';
import { emptyDunnageDimensionsReducer } from '../../../Store/dunnageDimensionReducer';
// import GetToken from '../../../Helpers/StorageFunction'

const PalletDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pallet, setPallet] = useState([]);
  const [selectedPallet, setSelectedPallet] = useState([]);
  const [checkedPallet, setCheckedPallet] = useState('Palletized');
  const [newPalletDimensions, setNewPalletDimensions] = useState([]);
  let { palletWrapDetails, palletDetail, otherSpecifications, products } = useSelector((state: any) => state.shipment.data.shipmentData);
  let { shipmentData } = useSelector((state: any) => state.shipment.data);
  let editShipmentDetails = JSON.parse(sessionStorage.getItem("editShipmentDetails"));
  let arr = JSON.parse(localStorage.getItem("storageData"));
  let GetToken = ""
  if (arr) GetToken = `?access_token=${arr[0].id}`

  window.onload = (event: any) => {
    if (editShipmentDetails) {
      navigate(`/edit-shipment/${editShipmentDetails._id}`);
    }
    else {
      if (arr[0]?.user?.roleName === "shipping_manager") navigate('/shipment-mode')
      else navigate('/dashboard');
    }
    // if (event) {
    //     navigate('/shipment-mode')
    // }
  };

  useEffect(() => {
    setSelectedPallet(palletDetail);
    // logic for the clearing further data from the reducer
    if (!editShipmentDetails) {
      dispatch(emptyShipmentReducer(
        {
          palletDimensions: {},
          palletWrapDetails: [],
          dunnage: [],
          dunnageSubcategory: [],
          palletWrapSpecifications: {},
          otherType: "pallet"
        }
      ));
      dispatch(emptyDunnageDimensionsReducer(true));
    }
  }, []);


  useEffect(() => {
    axios.post(`${URLs.BACKEND_URL}/api/products/get-products-icon${GetToken}`, { modelName: "pallet-details", productId: palletDetail[0]?.id ? palletDetail[0]?.productId : products[0]?.id }).then((res) => {
      setPallet(res.data.message);
      let data = [...res.data.message];
      if (palletDetail.length > 0) {
        setSelectedPallet(palletDetail);
      }
      else if (editShipmentDetails?.palletDetails?.length > 0) {
        let newData = data.filter((item: any) => {
          if (item.id === editShipmentDetails?.palletDetails[0]._id || item.id === editShipmentDetails?.palletDetails[0].id) {
            return true;
          }
        });
        setSelectedPallet(newData);
      }
      else {
        data.pop();
        selectedPallet.length === 0 && setSelectedPallet(data);
      }
    }).catch((e) => {
      if (e.response.data.error.message === 'could not find accessToken') {
        localStorage.clear();
        navigate('/login');
      }
    });

    if (checkedPallet !== 'Palletized') {
      dispatch(removePalletDetail(true));
    }
  }, [checkedPallet]);

  let handleCheck = (e) => {
    let selectedPallet = e.target.value;
    let newData = pallet.filter((data) => {
      if (data.name === selectedPallet) {
        return true;
      }
    });
    setSelectedPallet(newData);
  }

  let handleSubmit = () => {
    if (editShipmentDetails) {
      navigate(`/edit-shipment/${editShipmentDetails._id}`);
    }
    else {
      if (checkedPallet == "Palletized") {
        if (selectedPallet.length > 0) {
          dispatch(addPalletDetail(selectedPallet));
          dispatch(addPalletDimensions({}));
          navigate('/pallet-dimension-details');
        }
      } else {
        dispatch(removePalletDetail(true));
        // Code for creating the shipment in both the cases 
        // Adding an item pallet dimension
        // if (palletDetail.length === 0) {
        let palletDimensionsStatic = {
          "palletId": null,
          "productId": null,
          "palletDimension": {
            "length": 40,
            "width": 40,
            "height": 0
          },
          "palletWeight": 0
        }
        dispatch(addPalletDimensions(palletDimensionsStatic));
        // ------------ Adding initial shipment --------------------------------------------
        let arr = JSON.parse(localStorage.getItem("storageData"));
        let productIds = shipmentData.products.map((data: any) => data.id);
        let productSubcategoryIds = shipmentData.subcategories.map((data: any) => data.id);
        let packagingDetailIds = Object.keys(shipmentData.packagingDetails).map((data) => shipmentData.packagingDetails[data].id);
        let shipmentMode = "";
        // if (shipmentData.shipmentMode?.id) {
        //   shipmentMode = shipmentData.shipmentMode.id
        // }
        // else {
        //   shipmentMode = "645cd0d1148c0a6210ce4ef8"
        // }
        shipmentMode = shipmentData.shipmentMode.id;

        // logic for getting array of ids of pallet Wrap

        let shipmentDataFinal = {
          "shippingManagerId": arr[0]?.userId,
          "shipmentModeId": shipmentMode,
          "productId": productIds,
          "productSubcategoryId": productSubcategoryIds,
          "packageDetailId": packagingDetailIds,
          "packageDimension": shipmentData.packageDimensions,
          "palletDimension": palletDimensionsStatic,
          "OtherSpecifications": shipmentData.otherSpecifications,
          "palletized": false
        }
        let oldShipment = localStorage.getItem("shipmentId");
        if (oldShipment) localStorage.removeItem("shipmentId");
        axios.post(`${URLs.BACKEND_URL}/api/shipments/add-new-shipment${GetToken}`, shipmentDataFinal).then((res) => {
          if (res.data.message.id) {
            axios.post(`${URLs.BACKEND_URL}/api/shipments/load-calculator/`, { shipmentId: res.data.message.id, userId: arr[0].userId }).then((data) => {
              dispatch(addShipmentId(res.data.message.id));
              localStorage.setItem("shipmentId", res.data.message.id);
              localStorage.setItem("grossWeight", data?.data?.unityData?.grossWeight);
              navigate(`/visualization-of-loaded-truck/${res.data.message.id}/${arr[0].userId}`)
            }).catch(err => {
              axios.delete(`${URLs.BACKEND_URL}/api/shipments/${res.data.message.id}${GetToken}`).then(del => {
                enqueueSnackbar(err.response.data.error.message ? err.response.data.error.message : "Loading container error..!!", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
              }).catch(err => { });
            });
          }
          else {
            enqueueSnackbar("Please try again", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
          }
        }).catch((e) => {
          if (e.response.data.error.message === 'could not find accessToken') {
            localStorage.clear();
            navigate('/login');
          }
        });
      }
    }
  }

  // const handleUpdate = () => {
  //   if (checkedPallet === 'Palletized') {
  //     if (selectedPallet.length > 0) {
  //       // dispatch(addPalletDetail(selectedPallet));
  //       let editData = { ...editShipmentDetails, "palletDetails": selectedPallet };
  //       let data = {
  //         "palletDetailId": selectedPallet[0].id,
  //       };
  //       axios.patch(`${URLs.BACKEND_URL}/api/shipments/${editShipmentDetails._id}${GetToken}`, data).then((res) => {
  //         if (res.data.productId) {
  //           console.log('Shipment updated completely..!!');
  //           sessionStorage.setItem("editShipmentDetails", JSON.stringify(editData));
  //           navigate(`/edit-shipment/${editShipmentDetails._id}`)
  //         }
  //       }).catch((e) => {
  //         if (e.response.data.error.message === 'could not find accessToken') {
  //           localStorage.clear();
  //           navigate('/login');
  //         }
  //       });
  //     }
  //   }
  //   else {
  //     // step 1 create pallet dimension 
  //     let palletDimensionsStatic = {
  //       "palletId": null,
  //       "productId": null,
  //       "palletDimension": {
  //         "length": 40,
  //         "width": 40,
  //         "height": 0
  //       },
  //       "palletWeight": 0
  //     }
  //     axios.post(`${URLs.BACKEND_URL}/api/pallet-dimension-details/${editShipmentDetails.palletDimensions[0]._id}/replace${GetToken}`, palletDimensionsStatic).then((res) => {
  //       if (res.data.id) {
  //         let data = [];
  //         data.push(res.data)
  //         setNewPalletDimensions(data)
  //       }
  //     });

  //     if(newPalletDimensions.length>0){
  //       let editData = { ...editShipmentDetails, "palletDetails": [],"palletDimensions":newPalletDimensions,"palletWrapdetails":[] };
  //       let data = {
  //         "palletDetailId": null,
  //         "palletWrapdetailId":null
  //       };
  //       // Update data
  //       axios.patch(`${URLs.BACKEND_URL}/api/shipments/${editShipmentDetails._id}${GetToken}`, data).then((res) => {
  //         if (res.data.productId) {
  //           console.log('Shipment updated completely..!!');
  //           sessionStorage.setItem("editShipmentDetails", JSON.stringify(editData));
  //         }
  //       }).catch((e) => {
  //         if (e.response.data.error.message === 'could not find accessToken') {
  //           localStorage.clear();
  //           navigate('/login');
  //         }
  //       });
  //       // Add view logs
  //       const view_data = {
  //         "userId": arr[0].userId,
  //         "subject": "Pallet Details",
  //         "shipmentId": editShipmentDetails._id
  //       }
  //       axios.post(`${URLs.BACKEND_URL}/api/view_logs/${GetToken}`, view_data).then((res) => {
  //           if (res.data.id) {
  //               console.log('View logs added completely..!!');
  //               navigate(`/edit-shipment/${editShipmentDetails._id}`);
  //           }
  //       }).catch((e)=>{
  //           if ( e.response.data.error.message === 'could not find accessToken' ) {
  //             localStorage.clear();
  //             navigate('/login');
  //           }
  //       });
  //     }

  //   }
  // }

  return (
    <div className="main-content">
      <div className="container">
        <div className="row mt-2">
          <ActiveSteps />
          <div className="col-md-12">
            <div className={"goto-back " + (editShipmentDetails?._id && 'disableDiv')}>
              <Link to="/packaging-dimension-details">
                {!editShipmentDetails && <img className="" src={backIcon} alt="" />}
                <span> Pallet Details </span>
              </Link>
            </div>
          </div>

          {/* <!--------------------------------- Start Tabs Data ---------------------------------------> */}

          <div className="mng_shipment_data">
            <div className="tab-content tab-content-cus">
              <div className="tab-pane active" id="tabs-1" role="tabpanel">
                <div className="package-dia">
                  <div className="row mt-3">
                    <div className="col-md-5">
                      <div className="row">
                        <div className="col-12 mb-3">
                          <h6>Is it palletized?</h6>
                        </div>
                        <div className="col-6">
                          <div className="form-check">
                            <input className="form-check-input" type="radio" disabled={editShipmentDetails && true} name="flexRadioDefault" id="flexRadioDefault1" value='Palletized' onChange={(e) => setCheckedPallet(e.target.value)} checked={checkedPallet === 'Palletized' ? true : false} />
                            <label className="form-check-label" > Yes </label>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value='NotPalletized' disabled={editShipmentDetails && true} onChange={(e) => setCheckedPallet(e.target.value)} />
                            <label className="form-check-label" > No </label>
                          </div>
                        </div>

                        <div className="col-12 mt-5">
                          {checkedPallet === 'Palletized' && <div className="form-group custom-input">
                            <select className="form-select" aria-label="Default select example" disabled={editShipmentDetails && true} value={selectedPallet[0]?.name} defaultValue={`2-Way`} onChange={handleCheck}>
                              <option value='2-Way' >2 - Way</option>
                              <option value='4-Way' >4 - Way</option>
                            </select>
                          </div>}
                        </div>

                      </div>
                    </div>
                    <div className="col-md-7 text-center">
                      {checkedPallet === 'Palletized' && <img className="img-fluid" src={selectedPallet[0]?.icon?.iconUrl} width="278" height="222" alt="" />}
                    </div>
                    <div className="col-md-4">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!---------------------------------------------  End Tab Data  ----------------------------------------------->  */}

        </div>
        <div className="mt-2">
          <div className="col-md-12">
            <div className="form-group custom-input mt-4 text-center">
              <button className="btn-style-one text-center" onClick={handleSubmit} >
                {!editShipmentDetails ? `NEXT` : `BACK`}
              </button>
            </div>
          </div>
        </div>
        {/* {editShipmentDetails?.packagingDetails?.length > 0 && <div className="mt-2">
          <div className="col-md-12">
            <div className="form-group custom-input mt-4 text-center">
              <button className="btn-style-one text-center" onClick={handleUpdate} >
                UPDATE
              </button>
            </div>
          </div>
        </div>} */}
      </div>
    </div>
  )
}

export default PalletDetails