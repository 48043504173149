import { useState, useEffect } from 'react'
import backIcon from '../../Assets/images/back-icon.svg'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import URLS from '../../Helpers/Constants';
import { Icon } from 'semantic-ui-react'
import { enqueueSnackbar } from 'notistack';

function EditInspectionReport() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [insepectionInput, setInspectionInput] = useState({
        "doorsAreOpen": {
            "images": [],
            "status": false
        },
        "floorIsClear": {
            "images": [],
            "status": false
        },
        "anyVisibleLightFromCeiling": {
            "images": [],
            "status": false
        },
        "anyVisibleLightFromSidewalls": {
            "images": [],
            "status": false
        },
        "holesOnFlooring": {
            "images": [],
            "status": false
        },
        "identificationNumber": ""
    });

    let arr = JSON.parse(localStorage.getItem("storageData"));
    let GetToken = ""
    if (arr) GetToken = `?access_token=${arr[0].id}`;
    // Function for getting the inpection report 
    const getInspectionReport = (orderId: any) => {
        axios.post(`${URLS.BACKEND_URL}/api/inspection-reports/get-all-inspection-report${GetToken}`, { orderId }).then((res) => {
            setInspectionInput({ ...res.data.message[0] });
        }).catch((e) => {
            if (e.response.data.error.message === 'could not find accessToken') {
                localStorage.clear();
                navigate('/login');
            }
        });
    }
    // Use Effect for single mount
    useEffect(() => {
        getInspectionReport(id);
    }, []);

    // Adding conditions of redio buttons 
    const addInput = (e) => {
        let data = { ...insepectionInput };
        e.target.value === 'Yes' ? data[e.target.name].status = true : data[e.target.name].status = false;
        setInspectionInput(data);
    }
    // Image adding function
    const handleImageAdd = (e: any) => {
        if (e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/tiff") {
            if (e.target.files[0].size <= 15728640) {
                axios.post(`${URLS.BACKEND_URL}/api/profileImages/inspection-report-images/upload`, { profile: e.target.files[0] }, { headers: { "Content-Type": "multipart/form-data" } })
                    .then((res) => {
                        let imageName = res.data.result.files.profile[0].name;
                        let data = { ...insepectionInput };
                        data[e.target.name].images.push(imageName);
                        setInspectionInput(data);
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
            else {
                enqueueSnackbar("Please enter an image having size less than 15 mb!",
                    { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }
        }
        else {
            enqueueSnackbar("Only images are accepted as inspection report image. And type must be jpg/jpeg/png/tiff!",
                { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
        }
    }
    // Updating the current report
    const handleUpdateReport = () => {
        axios.patch(`${URLS.BACKEND_URL}/api/inspection-reports/${arr[0].inspectionReportId}${GetToken}`,
            {
                ...insepectionInput
            }).then((res) => {
                if (res) {
                    navigate(`/inspection-report/${id}`)
                }
            }).catch((err) => console.log(err))
    }

    // Handle cancel
    const handleCancel = (str: any, index: any) => {
        let data = { ...insepectionInput }
        data[str].images.splice(index, 1);
        setInspectionInput({ ...data })
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="goto-back">
                        <Link to="/dashboard"><img className="" src={backIcon} alt="" />
                            <span> Inspect Report </span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-9">
                    <p>Identification No.</p>
                </div>
                <div className="col-md-3">
                    <div className="form-group custom-input">
                        <input type="text" className="form-control" placeholder="Identification No." value={insepectionInput.identificationNumber} onChange={(e)=>setInspectionInput({...insepectionInput,"identificationNumber":e.target.value})} />
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-12" style={{ margin: 3 }}>
                    <div className="ins-report">
                        <div className="add-report">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="cmt-box">
                                        <h5>Did doors open and close properly?</h5>
                                        <div className="form-check float-start">
                                            <input className="form-check-input" type="radio" name="doorsAreOpen" id="flexRadioDefault1" value="Yes" onChange={addInput} checked={insepectionInput.doorsAreOpen.status} />
                                            <label className="form-check-label " > Yes </label>
                                        </div>
                                        <div className="form-check float-start mx-5">
                                            <input className="form-check-input" type="radio" name="doorsAreOpen" id="flexRadioDefault2" value="No" onChange={addInput} checked={!insepectionInput.doorsAreOpen.status} />
                                            <label className="form-check-label " > No </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <hr />
                                    <div className={insepectionInput.doorsAreOpen.images.length > 0 ? "pic-details" : "pic-details2"}>
                                        {insepectionInput.doorsAreOpen.images.length > 0 && <div className="no-of-pic py-0 my-0 float-start image-container">
                                            <ul>
                                                <div style={{ display: "flex", flexDirection: "row" }}>
                                                    {insepectionInput.doorsAreOpen.images.map((data, index) => (
                                                        <div style={{ position: "relative", height: "100%", width: "100%", display: "flex", flexDirection: "row" }}>
                                                            <li>
                                                                <Icon name='cancel' size='small' className='cancel-mark' onClick={() => handleCancel("doorsAreOpen", index)} />
                                                                <a href={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/inspection-report-images/download/${data}`} target="_blank" rel='noreferrer'>
                                                                    <img src={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/inspection-report-images/download/${data}`} alt="" />
                                                                </a>
                                                            </li>
                                                        </div>
                                                    ))}
                                                </div>
                                            </ul>
                                        </div>}
                                        <div className="checktwo">
                                            <i className="fa fa-camera upload-button" onClick={() => document.getElementById("imageadd1").click()}></i>
                                            <input className="file-upload" name='doorsAreOpen' type="file" id='imageadd1' accept="image/png, image/gif, image/jpeg, image/jpg" onChange={(e) => handleImageAdd(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12" style={{ margin: 3 }}>
                    <div className="ins-report">
                        <div className="add-report">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="cmt-box">
                                        <h5>Did floor clear  properly?</h5>
                                        <div className="form-check float-start">
                                            <input className="form-check-input" type="radio" name="floorIsClear" id="flexRadioDefault1" value="Yes" onChange={addInput} checked={insepectionInput.floorIsClear.status} />
                                            <label className="form-check-label " > Yes </label>
                                        </div>
                                        <div className="form-check float-start mx-5">
                                            <input className="form-check-input" type="radio" name="floorIsClear" id="flexRadioDefault2" value="No" onChange={addInput} checked={!insepectionInput.floorIsClear.status} />
                                            <label className="form-check-label " > No </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <hr />
                                    <div className={insepectionInput.floorIsClear.images.length > 0 ? "pic-details" : "pic-details2"}>
                                        {insepectionInput.floorIsClear.images.length > 0 && <div className="no-of-pic py-0 my-0 float-start image-container">
                                            <ul>
                                                <div style={{ display: "flex", flexDirection: "row" }}>
                                                    {insepectionInput.floorIsClear.images.map((data, index) => (
                                                        <div style={{ position: "relative", height: "100%", width: "100%", display: "flex", flexDirection: "row" }}>
                                                            <li>
                                                                <Icon name='cancel' size='small' className='cancel-mark' onClick={() => handleCancel("floorIsClear", index)} />
                                                                <a href={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/inspection-report-images/download/${data}`} target="_blank" rel='noreferrer'>
                                                                    <img src={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/inspection-report-images/download/${data}`} alt="" />
                                                                </a>
                                                            </li>
                                                        </div>
                                                    ))}
                                                </div>
                                            </ul>
                                        </div>}
                                        <div className="checktwo">
                                            <i className="fa fa-camera upload-button" onClick={() => document.getElementById("imageadd2").click()}></i>
                                            <input className="file-upload" name='floorIsClear' type="file" id='imageadd2' accept="image/png, image/gif, image/jpeg" onChange={(e) => handleImageAdd(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12" style={{ margin: 3 }}>
                    <div className="ins-report">
                        <div className="add-report">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="cmt-box">
                                        <h5>Did any visible light from the ceiling?</h5>
                                        <div className="form-check float-start">
                                            <input className="form-check-input" type="radio" name="anyVisibleLightFromCeiling" id="flexRadioDefault1" value="Yes" onChange={addInput} checked={insepectionInput.anyVisibleLightFromCeiling.status} />
                                            <label className="form-check-label " > Yes </label>
                                        </div>
                                        <div className="form-check float-start mx-5">
                                            <input className="form-check-input" type="radio" name="anyVisibleLightFromCeiling" id="flexRadioDefault2" value="No" onChange={addInput} checked={!insepectionInput.anyVisibleLightFromCeiling.status} />
                                            <label className="form-check-label " > No </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <hr />
                                    <div className={insepectionInput.anyVisibleLightFromCeiling.images.length > 0 ? "pic-details" : "pic-details2"}>
                                        {insepectionInput.anyVisibleLightFromCeiling.images.length > 0 && <div className="no-of-pic py-0 my-0 float-start image-container">
                                            <ul>
                                                <div style={{ display: "flex", flexDirection: "row" }}>
                                                    {insepectionInput.anyVisibleLightFromCeiling.images.map((data, index) => (
                                                        <div style={{ position: "relative", height: "100%", width: "100%", display: "flex", flexDirection: "row" }}>
                                                            <li>
                                                                <Icon name='cancel' size='small' className='cancel-mark' onClick={() => handleCancel("anyVisibleLightFromCeiling", index)} />
                                                                <a href={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/inspection-report-images/download/${data}`} target="_blank" rel='noreferrer'>
                                                                    <img src={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/inspection-report-images/download/${data}`} alt="" />
                                                                </a>
                                                            </li>
                                                        </div>
                                                    ))}
                                                </div>
                                            </ul>
                                        </div>}
                                        <div className="checktwo">
                                            <i className="fa fa-camera upload-button" onClick={() => document.getElementById("imageadd3").click()}></i>
                                            <input className="file-upload" name='anyVisibleLightFromCeiling' type="file" id='imageadd3' accept="image/png, image/gif, image/jpeg" onChange={(e) => handleImageAdd(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12" style={{ margin: 3 }}>
                    <div className="ins-report">
                        <div className="add-report">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="cmt-box">
                                        <h5>Did any light from the sidewalls?</h5>
                                        <div className="form-check float-start">
                                            <input className="form-check-input" type="radio" name="anyVisibleLightFromSidewalls" id="flexRadioDefault1" value="Yes" onChange={addInput} checked={insepectionInput.anyVisibleLightFromSidewalls.status} />
                                            <label className="form-check-label " > Yes </label>
                                        </div>
                                        <div className="form-check float-start mx-5">
                                            <input className="form-check-input" type="radio" name="anyVisibleLightFromSidewalls" id="flexRadioDefault2" value="No" onChange={addInput} checked={!insepectionInput.anyVisibleLightFromSidewalls.status} />
                                            <label className="form-check-label " > No </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <hr />
                                    <div className={insepectionInput.anyVisibleLightFromSidewalls.images.length > 0 ? "pic-details" : "pic-details2"}>
                                        {insepectionInput.anyVisibleLightFromSidewalls.images.length > 0 && <div className="no-of-pic py-0 my-0 float-start image-container">
                                            <ul>
                                                <div style={{ display: "flex", flexDirection: "row" }}>
                                                    {insepectionInput.anyVisibleLightFromSidewalls.images.map((data, index) => (
                                                        <div style={{ position: "relative", height: "100%", width: "100%", display: "flex", flexDirection: "row" }}>
                                                            <li>
                                                                <Icon name='cancel' size='small' className='cancel-mark' onClick={() => handleCancel("anyVisibleLightFromSidewalls", index)} />
                                                                <a href={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/inspection-report-images/download/${data}`} target="_blank" rel='noreferrer'>
                                                                    <img src={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/inspection-report-images/download/${data}`} alt="" />
                                                                </a>
                                                            </li>
                                                        </div>
                                                    ))}
                                                </div>
                                            </ul>
                                        </div>}
                                        <div className="checktwo">
                                            <i className="fa fa-camera upload-button" onClick={() => document.getElementById("imageadd4").click()}></i>
                                            <input className="file-upload" name='anyVisibleLightFromSidewalls' type="file" id='imageadd4' accept="image/png, image/gif, image/jpeg" onChange={(e) => handleImageAdd(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12" style={{ margin: 3 }}>
                    <div className="ins-report">
                        <div className="add-report">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="cmt-box">
                                        <h5>Did any holes on the floor?</h5>
                                        <div className="form-check float-start">
                                            <input className="form-check-input" type="radio" name="holesOnFlooring" id="flexRadioDefault1" value="Yes" onChange={addInput} checked={insepectionInput.holesOnFlooring.status} />
                                            <label className="form-check-label " > Yes </label>
                                        </div>
                                        <div className="form-check float-start mx-5">
                                            <input className="form-check-input" type="radio" name="holesOnFlooring" id="flexRadioDefault2" value="No" onChange={addInput} checked={!insepectionInput.holesOnFlooring.status} />
                                            <label className="form-check-label " > No </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <hr />
                                    <div className={insepectionInput.holesOnFlooring.images.length > 0 ? "pic-details" : "pic-details2"}>
                                        {insepectionInput.holesOnFlooring.images.length > 0 && <div className="no-of-pic py-0 my-0 float-start image-container">
                                            <ul>
                                                <div style={{ display: "flex", flexDirection: "row" }}>
                                                    {insepectionInput.holesOnFlooring.images.map((data, index) => (
                                                        <div style={{ position: "relative", height: "100%", width: "100%", display: "flex", flexDirection: "row" }}>
                                                            <li>
                                                                <Icon name='cancel' size='small' className='cancel-mark' onClick={() => handleCancel("holesOnFlooring", index)} />
                                                                <a href={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/inspection-report-images/download/${data}`} target="_blank" rel='noreferrer'>
                                                                    <img src={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/inspection-report-images/download/${data}`} alt="" />
                                                                </a>
                                                            </li>
                                                        </div>
                                                    ))}
                                                </div>
                                            </ul>
                                        </div>}
                                        <div className="checktwo">
                                            <i className="fa fa-camera upload-button" onClick={() => document.getElementById("imageadd5").click()}></i>
                                            <input className="file-upload" name='holesOnFlooring' type="file" id='imageadd5' accept="image/png, image/gif, image/jpeg" onChange={(e) => handleImageAdd(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-12">
                    <div className="form-group custom-input mt-4 text-center">
                        <button className="btn-style-one text-center" onClick={handleUpdateReport} >
                            Save Report
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EditInspectionReport