import { useState, useEffect } from 'react'
import checkMarkIcon from '../../../Assets/images/checkmark.svg'
import { useDispatch, useSelector } from 'react-redux';
import { addPackagingDetails, removePackagingDetails } from '../../../Store/shipmentReducer';

function PackagingDeatilData({ packagingDetail }) {
    const [clicked, setClicked] = useState(false);
    const dispatch = useDispatch();
    let onceClicked=false;
    let { packagingDetails,products } = useSelector((state: any) => state.shipment.data.shipmentData);
    let editShipmentDetails = JSON.parse(sessionStorage.getItem("editShipmentDetails"));    
    let handleClick = () => {
        if(!editShipmentDetails?._id){
            setClicked(!clicked);
        }
    };
    useEffect(() => {
        if (Object.keys(packagingDetails).length > 0) {
                if (packagingDetails[packagingDetail.productId]?.id === packagingDetail.id) {
                    setClicked(true);
                }
                else{
                    setClicked(false)
                }    
        }
        // Code for edit data 
        if(editShipmentDetails?.packagingDetails?.length>0){
            editShipmentDetails.packagingDetails.map((data:any)=>{
                if(data._id===packagingDetail.id){
                    setClicked(true);  
                }
            }) 
        }
    }, [packagingDetails]);
    useEffect(() => {
        if (clicked) {
            dispatch(addPackagingDetails(packagingDetail));
            onceClicked=true;
        }
        if(onceClicked && !clicked){
            dispatch(removePackagingDetails(packagingDetail));
        }
    }, [clicked]);

    useEffect(()=>{
     Object.keys(packagingDetails).map((data)=>{
        let newData = products.filter((newData1:any)=>{
            if(data===newData1.id){
                return true;
            }
        });
        if(newData.length===0){
            dispatch(removePackagingDetails(packagingDetails[data]));
        }
     })
    },[]);

    return (
        <>
            <div className="col-md-2 col-sm-6 col-6 d-flex justify-content-center">
                <div className="item d-flex flex-column justify-content-center align-items-center">
                    <div className="item-details d-flex justify-content-center align-items-center" onClick={handleClick}>
                        {clicked && <div style={{ position: "absolute", top: 0, right: 7 }} ><img src={checkMarkIcon} alt='' /></div>}
                        <img src={packagingDetail.icon.iconUrl} alt="" id={packagingDetail.id} />
                    </div>
                    <div className="text-center mt-2">{packagingDetail.name}</div>
                </div>
            </div>
        </>
    )
}


export default PackagingDeatilData