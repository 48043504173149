import axios from 'axios';
import { useEffect, useState } from 'react';
import URLs from '../../Helpers/Constants';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import backIcon from '../../Assets/images/back-icon.svg'

function ViewLogs() {
  const navigate = useNavigate();
  const { shipmentId } = useParams();
  const [ logs, setLogs ] = useState([]);
  let arr = JSON.parse(localStorage.getItem("storageData"));
  let GetToken = ""
  if (arr) GetToken = `?access_token=${arr[0].id}`;
  useEffect(() => {
    axios.get(`${URLs.BACKEND_URL}/api/view_logs/${GetToken}&filter[where][shipmentId]=${shipmentId}&filter[include]=user`).then((res) => {
      if (res.data && res.data.length > 0) {
        setLogs(res.data);
      }
      }).catch((e)=>{
          if ( e.response.data.error.message === 'could not find accessToken' ) {
            localStorage.clear();
            navigate('/login');
          }
      });
  }, []);

  return (
    <>
   <div className="main-content">
    <div className="container">
    <div className="row mt-2">
            <div className="col-md-12">
              <div className="goto-back ">
                <Link to="/dashboard">
                  <img className="" src={backIcon} alt="" />
                  <span> View Logs </span>
                </Link>
              </div>
            </div>
          </div>
      <div className="row">
        <div className="col-md-12">
          {
            logs.length > 0 ? logs.map(log => (
              <div className="notif-area" key={log.id}>
                <p>
                  {log?.user?.firstName} {log?.user?.lastName} has updated <strong>{log?.subject}</strong>.
                </p>
                <p>
                  <span className="float-start">{moment(log.createdAt).fromNow()}</span>
                  <span className="float-end">{moment(log.createdAt).format('ll')}</span></p>
              </div>
            )) : (
              <div className="notif-area py-4 mt-4">
                <p>No data found..!!</p>
              </div>
            )
          }
        </div>
      </div>
    </div>
  </div>
    </>
  )
}

export default ViewLogs