import axios from "axios";
import URLS from "../Helpers/Constants";


// -------------- Parameter interfaces for different methods ----------------------------
export interface LoginInterface {
    email: string,
    password: string,
    role: string
};

export interface forgotInterface {
    email: string,
    roleType: string
}

export interface validateOtpInterface {
    email: string,
    otp: string
}

export interface resetPasswordInterface {
    newPassword: string,
    email: string,
    accessToken: string
}

export interface changePasswordInterface {
    oldPassword: string,
    newPassword: string,
}

// --------------------------- Custom Api calling function  for the login of user ------------------------------
export function login(data: LoginInterface) {
    let data1 = axios.post(`${URLS.BACKEND_URL}/api/users/login?include=user&&include=user['role']`, data).then(
        (response: any) => {
            if (response.data) {
                // ----------- Code for localstorage -------------------------------
                let storageData = JSON.parse(localStorage.getItem("storageData") || "[]");
                let data1 = { ...response.data};
                if (storageData.length === 0) {
                    storageData.push(data1);
                }
                else if (storageData.length > 0) {
                    let data = storageData.filter((c: any) => {
                        if (c.user.email === data1.user.email) {
                            return true
                        } else {
                            return false;
                        }
                    });
                    if (data.length === 0) {
                        storageData.push(data1);
                    }
                }
                localStorage.setItem("storageData", JSON.stringify(storageData));
                return response.data;
            } else {
                return response.error;
            }
        }).catch(err => {
            console.log(err);
            return err;
        });
    return data1.then((data) => data)
}

// --------------------------- Custom Api calling function  for forgot password of user ------------------------------

export function forgotPassword(data: forgotInterface) {
    let data1 = axios.post(`${URLS.BACKEND_URL}/api/users/forgot-password`, data).then(
        (response: any) => {
            if (response.data) {
                let forgotPasswordData = JSON.parse(localStorage.getItem("forgotPasswordData") || "[]");
                if (forgotPasswordData.length === 0) {
                    forgotPasswordData.push(response.data.message.verifiedEmail);
                }
                localStorage.setItem("forgotPasswordData", JSON.stringify(forgotPasswordData));
                return response.data;
            } else {
                return response.error;
            }
        }).catch(err => {
            console.log(err);
            return err;
        });
    return data1.then((data) => data)
}

// --------------------------- Custom Api calling function  for the otp validation of user ------------------------------

export function validateOtp(data: validateOtpInterface) {
    let data1 = axios.post(`${URLS.BACKEND_URL}/api/users/email-otp-validation`, data).then(
        (response: any) => {
            if (response.data) {
                return response.data;
            } else {
                return response.error;
            }
        }).catch(err => {
            console.log(err);
            return err;
        });
    return data1.then((data) => data)
}

// --------------------------- Custom Api calling function  for the reset password of user ------------------------------

export function resetPassword(data: resetPasswordInterface) {
    let data1 = axios.post(`${URLS.BACKEND_URL}/api/users/alter-password`, data).then(
        (response: any) => {
            if (response.data) {
                return response.data;
            } else {
                return response.error;
            }
        }).catch(err => {
            console.log(err);
            return err;
        });
    return data1.then((data) => data)
}

export function changePassword(data: changePasswordInterface) {
    let arr = JSON.parse(localStorage.getItem("storageData"));
    if(arr){
        let data1 = axios.post(`${URLS.BACKEND_URL}/api/users/change-password?access_token=${arr[0].id}`, data).then(
            (response: any) => {
                if (response) {
                    return response;
                } else {
                    return response.error;
                }
            }).catch(err => {
                console.log(err);
                return err;
            });
        return data1.then((data) => data)
    }
}