import { useEffect, useState } from 'react'
import './Shipment.css'
import deliveryImage from '../../Assets/img/delivery-icon.svg'
import palletImage from '../../Assets/img/Pallet.svg'
import boxImage from '../../Assets/img/Box.svg'
import dunnageImage from '../../Assets/img/Dunnage.svg'
import axios from 'axios'
import '../../Assets/css/style.css'
import URLS from '../../Helpers/Constants';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { addEditShipmentDetails } from '../../Store/dunnageDimensionReducer'
import { addShipmentId } from '../../Store/shipmentReducer'
import { enqueueSnackbar } from 'notistack'
import { Loader, Pagination } from 'semantic-ui-react'

function Shipments() {

  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [activeShipments, setActiveShipments] = useState("all");
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalShipment, setTotalShipment] = useState(0);
  let arr = JSON.parse(localStorage.getItem("storageData"));
  let GetToken = "";
  let userRole = "";
  if (arr) {
    GetToken = `?access_token=${arr[0]?.id}`;
    userRole = arr[0]?.user?.roleName;
  }
  const [shipmentData, setShipmentData] = useState([]);
  const dispatch = useDispatch();

  let handleChange = (e: any) => {
    setShowLoader(true);
    setActiveShipments(e.target.value);
    setPage(1)
    let data = {
      page: 1,
      limit: 5,
      searchQuery: searchQuery,
      "activeShipments": undefined,
      shippingManagerId: arr[0].userId
    }
    if (e.target.value === 'all') {
      data = { ...data, "activeShipments": undefined, }
      getShipmentData(data)
    }
    else if (e.target.value === 'active') {
      data = { ...data, "activeShipments": true, }
      getShipmentData(data)
    }
    else {
      data = { ...data, "activeShipments": false, }
      getShipmentData(data)
    }

  }

  const getShipmentData = (data: any) => {
    setShowLoader(true);
    axios.post(`${URLS.BACKEND_URL}/api/shipments/get-all-shipments-for-shipping-manager${GetToken}`, data).then((res) => {
      setShipmentData([...res.data.message[0].data]);
      handlePages(parseInt(res.data.message[0].metadata[0].total))
      setShowLoader(false);
    }).catch((e) => {
      setShowLoader(false);
      if (e.response.data.error.message === 'could not find accessToken') {
        localStorage.clear();
        navigate('/login');
      }
    });
  }

  useEffect(() => {
    let data = {
      page: page,
      limit: 5,
      searchQuery: searchQuery,
      shippingManagerId: arr[0].userId
    }
    getShipmentData(data);
  }, []);


  const handleEditShipment = (shipment: any) => {
    if (shipment._id) {
      dispatch(addEditShipmentDetails(shipment));
      window.sessionStorage.setItem("editShipmentDetails", JSON.stringify(shipment));
      navigate(`/edit-shipment/${shipment._id}`);
    }
  }



  const handleViewShipment = (shipmentId: any) => {
    axios.post(`${URLS.BACKEND_URL}/api/shipments/load-calculator/`, { shipmentId, userId: arr[0]?.userId }).then((data) => {
      dispatch(addShipmentId(shipmentId));
      console.log(data)
      localStorage.setItem("refresKey", JSON.stringify("refres"));
      data?.data?.unityData?.shipment?.grossWeight ? localStorage.setItem("grossWeight", data?.data?.unityData?.shipment?.grossWeight) : localStorage.setItem("grossWeight", data?.data?.unityData?.grossWeight);
      navigate(`/visualization-of-loaded-truck/${shipmentId}/${arr[0]?.userId}`);
    }).catch(err => {
      enqueueSnackbar(err.response.data.error.message ? err.response.data.error.message : "Please check your shipment details..!!", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
    });
  }

  const checkUserAccess = (shipment: any) => {
    if (shipment.status[0] == true) {
      if (arr[0].user.roleName === 'shipping_manager') {
        return 'edit'
      }
      else if (arr[0].user.roleName === 'origin_loading_manager' || arr[0].user.roleName === 'destination_loading_manager') {
        return arr[0].user.accessType[shipment._id]
      }
    } else {
      return "view";
    }
  }

  const onPageChangeCall = (pageNo: any) => {
    let data = {
      page: pageNo,
      limit: 5,
      searchQuery: searchQuery,
      shippingManagerId: arr[0].userId
    }
      getShipmentData(data);
  }

  const handleClick = (e: any) => {
    if (e.target.textContent === '⟩' && page < pageLimit) {
      setPage(page + 1);
      onPageChangeCall(page + 1)
    }
    else if (e.target.textContent === '⟨' && page > 1) {
      setPage(page - 1);
      onPageChangeCall(page - 1)
    }
    else if (parseInt(e.target.textContent)) {
      setPage(parseInt(e.target.textContent));
      onPageChangeCall(parseInt(e.target.textContent))
    }
  }

  const handlePages = (limit: any) => {
    setTotalShipment(limit)
    limit = limit / 5;
    let temp = parseInt(limit);
    (limit - temp) === 0 ? setPageLimit(parseInt(limit)) : setPageLimit(parseInt(limit) + 1)
  }

  const handleSearch = (e: any) => {
    setSearchQuery(e.target.value);
    const timeOutId = setTimeout(() => {
      setPage(1)
      let data = {
        page: 1,
        limit: 5,
        searchQuery: e.target.value,
        shippingManagerId: arr[0].userId
      }
      getShipmentData(data)
    }, 1500);
    return () => clearTimeout(timeOutId);
  }

  return (
    <>
      <section className="home-section">
        <div className="main-content">
          <div className="container">
            <div className="row mt-2">
              <div className="col-lg-6">
                <div className="search">
                  <div className="form-group has-search">
                    <span className="fa fa-search form-control-feedback"></span>
                    <input type="text" value={searchQuery} className="form-control" placeholder="Search" onChange={handleSearch} />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="select-filter custom-filter-new">
                  <select className="form-select custom_select_box" value={activeShipments} aria-label="Default select example" onChange={handleChange}>
                    <option value="all">All Shipments </option>
                    <option value="active">Active Shipments</option>
                    <option value="past">Past Shipments</option>
                  </select>
                </div>
              </div>
            </div>
            {showLoader && <div style={{ marginTop: 100 }}>
              <Loader active inline='centered' size='big' />
            </div>}
            {!showLoader && <div>
              {
                shipmentData.length == 0 && <div className="row mt-4">
                  <div className="col-lg-12 mb-2">
                    <div className="shipment-details d-flex justify-content-center align-items-center py-4">
                      <h5>No Shipments found..!!</h5>
                    </div>
                  </div>
                </div>
              }
              {shipmentData.length > 0 && shipmentData.map((shipment) => {
                return <div className="row mt-4" key={shipment.orderId[0]}>
                  <div className="col-lg-12 mb-2">
                    <div className="shipment-details">
                      <div className="row px-3 pt-3 pb-3">
                        <div className="col-md-1 d-flex justify-content-center align-items-center">
                          <img src={deliveryImage} alt="" height="35" width="35" />
                        </div>
                        <div className="col-md-11">
                          <div className="order-id">
                            <p>Order ID : <span>{shipment.orderId[0]}</span></p>
                          </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-3 mt-2">
                          <div className="text-for3">
                            <p>Transportation Mode : <span>{shipment.shipmentMode[0].name}</span></p>
                          </div>
                        </div>
                        <div className="col-md-2 mt-2" style={{ cursor: "pointer" }} title={shipment.products.map((data: any) => data.name)}>
                          <div className="text-for">
                            <p>Products :
                              <span>{shipment.products[0]?.name} {shipment.products[1]?.name}</span>
                              {shipment.products.length > 2 && <span>, ...</span>}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3 mt-2">
                          <div className="text-for">
                            <p>Quantity : <span>{shipment.totalPallets ? shipment.totalPallets : "N/A"}</span></p>
                          </div>
                        </div>
                        <div className="col-md-3 mt-2" style={{ cursor: "pointer" }} title={shipment.packagingDetails.map((data: any) => data.name)}>
                          <div className="text-for">
                            <p><img src={boxImage} alt="" /> &nbsp;Packaging :
                              <span>{shipment?.packagingDetails[0]?.name} {shipment?.packagingDetails[1]?.name}</span>
                              {shipment.packagingDetails.length > 2 && <span>, ...</span>}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-3 mt-2">
                          <div className="text-for3" style={{ cursor: "pointer" }} title={shipment.dunnage.map((data: any) => data.name)}>
                            <p><img src={dunnageImage} alt="" /> &nbsp;Dunnage :
                              {shipment.dunnage.map((data: any, index: any) =>
                              (
                                <>
                                  {index < 2 && <span>{data.name}</span>}
                                  {index < 2 && <span>{index < 1 && index !== shipment.dunnage.length - 1 && <span>, </span>}</span>}
                                  {index >= 2 && index < 3 && <span style={{ fontSize: "larger" }}>...</span>}
                                </>
                              ))}</p>
                          </div>
                        </div>
                        {shipment.palletDimensions && shipment.palletDimensions.length > 0 && shipment.palletDetails.length > 0 && shipment.palletDimensions[0].palletDimension && <div className="col-md-3 mt-2">
                          <div className="text-for3">
                            <p><img src={palletImage} alt="" /> &nbsp;Pallet Dimensions : <span>{shipment.palletDimensions[0].palletDimension.length + "*" + shipment.palletDimensions[0].palletDimension.width + "*" + shipment.palletDimensions[0].palletDimension.height}</span></p>
                          </div>
                        </div>}
                      </div>
                      <div className="bdr-top">
                        <div className="col-md-12">
                          <div className="row pb-3">
                            <div className="col-md-1"></div>
                            {
                              <div className="col-md-2">
                                <div className="report event-btns mx-3" onClick={() => navigate(`/inspection-report/${shipment._id}`)}>Inspection Report</div>
                              </div>
                            }
                            {
                              <div className="col-md-2">
                                <div className="report event-btns mx-3" onClick={() => navigate(`/destination-report/${shipment._id}`)}>Destination Report</div>
                              </div>
                            }
                            <div className="col-md-2">
                              <div className="report event-btns mx-3" onClick={() => handleEditShipment(shipment)}>{checkUserAccess(shipment) === 'edit' ? `Edit Shipment` : `View Shipment`}</div>
                            </div>
                            <div className="col-md-2">
                              <div className="report event-btns mx-3" onClick={() => navigate(`/view-logs/${shipment._id}`)}>View Logs</div>
                            </div>
                            <div className="col-md-2">
                              <div className="report event-btns mx-3" onClick={() => handleViewShipment(shipment._id)}>Visualize Shipment</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              })}
            </div>}
            {!showLoader && shipmentData.length > 0 && (
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "25px", marginTop: "20px" }}>
                <Pagination
                  boundaryRange={1}
                  defaultActivePage={page}
                  ellipsisItem={null}
                  firstItem={null}
                  lastItem={null}
                  siblingRange={1}
                  totalPages={pageLimit}
                  style={{ height: "10px", marginRight: 5, border: "1px solid orange", paddingTop: "5px" }} onClick={(e: any) => handleClick(e)}
                />
                <div style={{ padding: 5, height: "40px", marginLeft: 5, border: "1px solid orange", display: "flex", borderRadius: 4 }}>
                  <span style={{ margin: "auto" }}>Total Shipments: {totalShipment}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default Shipments