import '../../../Assets/css/style.css'
function ActiveSteps() {
    return (
        // <>
        //   <div className="col-md-12">
        //   <div className="process-wrap active-step1">
        //     <div className="process-main">
        //       <div className="col-3 ">
        //         <div className="process-step-cont">
        //           <div className="process-step step-1"></div>
        //           <span className="process-label">Step 1</span> </div>
        //       </div>
        //       <div className="col-3 ">
        //         <div className="process-step-cont">
        //           <div className="process-step step-2"></div>
        //           <span className="process-label">Step 2</span> </div>
        //       </div>
        //       <div className="col-3">
        //         <div className="process-step-cont">
        //           <div className="process-step step-3"></div>
        //           <span className="process-label">Step 3</span> </div>
        //       </div>
        //       <div className="col-3">
        //         <div className="process-step-cont">
        //           <div className="process-step step-4"></div>
        //           <span className="process-label">Step 4</span> </div>
        //       </div>
        //       <div className="col-3">
        //         <div className="process-step-cont">
        //           <div className="process-step step-4"></div>
        //           <span className="process-label">Step 4</span> </div>
        //       </div>
        //       <div className="col-3">
        //         <div className="process-step-cont">
        //           <div className="process-step step-4"></div>
        //           <span className="process-label">Step 5</span> </div>
        //       </div>
        //       <div className="col-3">
        //         <div className="process-step-cont">
        //           <div className="process-step step-4"></div>
        //           <span className="process-label">Step 6</span> </div>
        //       </div>
        //       <div className="col-3">
        //         <div className="process-step-cont">
        //           <div className="process-step step-4"></div>
        //           <span className="process-label">Step 7</span> </div>
        //       </div>
        //       <div className="col-3">
        //         <div className="process-step-cont">
        //           <div className="process-step step-4"></div>
        //           <span className="process-label">Step 8</span> </div>
        //       </div>
        //       <div className="col-3">
        //         <div className="process-step-cont">
        //           <div className="process-step step-4"></div>
        //           <span className="process-label">Step 9</span> </div>
        //       </div>
        //       <div className="col-3">
        //         <div className="process-step-cont">
        //           <div className="process-step step-4"></div>
        //           <span className="process-label">Step 10</span> </div>
        //       </div>
        //     </div>
        //   </div>
        // </div>
        // </>

        <>
        </>
    )
}

export default ActiveSteps