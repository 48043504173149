import { useEffect, useState } from 'react'
import './MyProfile.css'
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import 'react-phone-number-input/style.css';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import userTempImage from '../../Assets/img/avatar.png';
import editImage from '../../Assets/images/edit-profile.svg';
import { useNavigate } from 'react-router-dom';
import URLS from '../../Helpers/Constants';
import { truncate } from 'fs/promises';

const MyProfile = () => {
    const navigate = useNavigate();
    let arr = JSON.parse(localStorage.getItem("storageData"));
    if (!arr) navigate('/login')
    let userData = arr[0];
    const [phoneRequired, setPhoneRequired] = useState(true);
    const [validPhoneNumber, setValidPhoneNumber] = useState(false);
    const [view, setView] = useState(false);
    const [showError, setShowError] = useState(false)
    const [input, setInput] = useState({
        firstName: "",
        lastName: "",
        address: "",
        country: "",
        email: '',
        phone: { "e164Number": "" },
        profileImage: ""
    });
    let tempUrl = `${URLS.BACKEND_URL}/api/profileImages/profile-images/download/`;

    useEffect(() => {
        if (userData.user) {
            axios.get(`${URLS.BACKEND_URL}/api/users/${userData.userId}?filter={"include":"role"}&access_token=${userData.id}`)
                .then((res) => {
                    res.data = { ...res.data, roleName: res.data.role.name }
                    userData.user = res.data;
                    let data1 = { ...userData };
                    localStorage.clear();
                    let storageData = JSON.parse(localStorage.getItem("storageData") || "[]");
                    if (storageData.length === 0) {
                        storageData.push(data1);
                    }
                    localStorage.setItem("storageData", JSON.stringify(storageData));
                    let data = {
                        firstName: userData.user.firstName,
                        lastName: userData.user.lastName,
                        address: userData.user.address,
                        country: userData.user.country,
                        email: userData.user.email,
                        phone: { ...userData.user.phone },
                        profileImage: userData.user?.profileImage
                    };
                    setInput(data);
                    if (data.phone.e164Number) {
                        setValidPhoneNumber(true);
                        setPhoneRequired(false)
                    }
                });

        }
    }, []);
    function addData(e: any) {
        setInput({ ...input, [e.target.name]: e.target.value });
    }

    let handleSubmit = (e) => {
        e.preventDefault();
        if (arr[0].user.firstName !== input.firstName || arr[0].user.lastName !== input.lastName || arr[0].user.address !== input.address || arr[0]?.user?.phone?.e164Number !== input.phone.e164Number || arr[0]?.user?.profileImage !== input?.profileImage) {
            if (input.firstName && input.email) {
                if (validPhoneNumber) {
                    axios.patch(`${URLS.BACKEND_URL}/api/users/${userData.userId}?access_token=${userData.id}`, input).then((response: any) => {
                        if (response.data) {
                            userData.user = { ...userData?.user, ...response.data };
                            // ----------- Code for localstorage -------------------------------
                            let data1 = { ...userData };
                            localStorage.clear();
                            let storageData = JSON.parse(localStorage.getItem("storageData") || "[]");
                            if (storageData.length === 0) {
                                storageData.push(data1);
                            }
                            localStorage.setItem("storageData", JSON.stringify(storageData));
                            // ---------- setting local state data --------------------------------
                            if (data1.id) {
                                let data = {
                                    firstName: userData.user.firstName,
                                    lastName: userData.user.lastName,
                                    address: userData.user.address,
                                    country: userData.user.country,
                                    email: userData.user.email,
                                    phone: { ...userData.user.phone },
                                    profileImage: userData.user.profileImage
                                };
                                setInput(data);
                            }
                            setView(false);
                            enqueueSnackbar("User updated successfully.",
                                { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });

                        }
                        else {
                            enqueueSnackbar(response.data.response.data.error.message,
                                { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                    })
                        .catch((e) => console.log(e))
                }
                else {
                    enqueueSnackbar("Please enter valid phone number",
                        { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
            }
            else {
                enqueueSnackbar("Please fill the values in all the required fields",
                    { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }
        }
        else {
            enqueueSnackbar("Please proceed after changing any value",
                { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
        }
    }

    let handleImageinput = (e) => {
        if (e.target.files[0].type) {
            if (e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/tiff") {
                if (e.target.files[0].size <= 15728640) {
                    axios.post(`${URLS.BACKEND_URL}/api/profileImages/profile-images/upload`, { profile: e.target.files[0] }, { headers: { "Content-Type": "multipart/form-data" } }).then((res) => {
                        setInput({ ...input, profileImage: tempUrl + res.data.result.files.profile[0].name });
                        setView(true);
                    }).catch((e) => console.log(e))
                }
                else {
                    enqueueSnackbar("Please enter an image having size less than 15 mb!",
                        { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
            }
            else {
                enqueueSnackbar("Only images are accepted as profile image. And type must be jpg/jpeg/png/tiff!",
                    { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }
        }
    }
    return (
        <div>
            <section className="home-section">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row mt-2 mb-2">
                            <div className="col-lg-12">
                                <div className="page-heading">
                                    <h3>My Profile</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row profile-bg">
                            <div className="col-lg-4">
                                {view ? <div className="profile-img"><img src={input.profileImage} alt="" className="pro-img" /><img src={editImage} onClick={() => document.getElementById("imageUpdate").click()} alt="" className="pro-edit-icon" /> </div>
                                    : <div className="profile-img"> {input.profileImage ? <img src={input.profileImage} alt="" className="pro-img" /> : <img src={userTempImage} alt="" className="pro-img" />} <img src={editImage} onClick={() => document.getElementById("imageUpdate").click()} alt="" className="pro-edit-icon" /> </div>}
                                <input type='file' id="imageUpdate" onChange={handleImageinput} />
                            </div>

                            <div className="col-lg-8">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group custom-input">
                                                <label >First Name <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" name="firstName" id="firstName"
                                                    value={input.firstName}
                                                    onChange={addData}
                                                    placeholder="First name" />
                                                {input.firstName.length === 0 && <div style={{ color: "red" }}>
                                                    <div>First name is required</div>
                                                </div>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group custom-input">
                                                <label >Last Name <span className="text-danger"></span></label>
                                                <input type="text" className="form-control" name="lastName" id="lastName"
                                                    value={input.lastName}
                                                    onChange={addData}
                                                    placeholder="Last name" />
                                                <div className="invalid-feedback">
                                                    <div >Last Name is required</div>
                                                    <div >Blank space is not allowed</div>
                                                    <div >Please enter no more than 25 characters.</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <label className="mt-1 mb-2">Phone Number <span className="text-danger">*</span></label>
                                            <div className="form-group custom-input-phone">
                                                <PhoneInput
                                                    placeholder="Enter phone number"
                                                    name="phone"
                                                    value={input.phone.e164Number}
                                                    international={true}
                                                    onChange={(e) => {
                                                        if (e) {
                                                            setPhoneRequired(false)
                                                            setShowError(true)
                                                        }
                                                        else setPhoneRequired(true)
                                                        if (e && isValidPhoneNumber(e)) {
                                                            setValidPhoneNumber(true);
                                                            let internationalNumber = formatPhoneNumberIntl(e);
                                                            let nationalNumber = formatPhoneNumber(e)
                                                            let phoneObject = parsePhoneNumber(formatPhoneNumberIntl(e));
                                                            let outPutObject = { ...userData.user.phone };
                                                            outPutObject.internationalNumber = internationalNumber;
                                                            outPutObject.countryCode = phoneObject.country
                                                            outPutObject.e164Number = phoneObject.number
                                                            outPutObject.dialCode = `+${phoneObject.countryCallingCode}`
                                                            outPutObject.nationalNumber = nationalNumber
                                                            outPutObject.number = nationalNumber
                                                            // --------------- changinging the state of form --------------------------
                                                            setInput({ ...input, phone: { ...outPutObject } })
                                                        }
                                                        else {
                                                            setValidPhoneNumber(false)
                                                        }
                                                        return e
                                                    }}
                                                />
                                                {showError && <div style={{ marginLeft: 40, color: "red" }}>
                                                    {phoneRequired && <div >Mobile number is required!</div>}
                                                    {!validPhoneNumber && !phoneRequired && <div >Mobile number is invalid!</div>}
                                                </div>}
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group custom-input">
                                                <label >Email</label>
                                                <input type="text" disabled={true} className="form-control" value={input.email} name="email" onChange={addData} id="email"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group custom-input">
                                                <label >Address <span className="text-danger"></span></label>
                                                <input type="text" className="form-control" value={input.address} name="address" id="address"
                                                    onChange={addData} />
                                                <div className="invalid-feedback">
                                                    <div>Address is required</div>
                                                    <div>Blank space is not allowed</div>
                                                    <div >Please enter no more than 25 characters.</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group custom-input">
                                                <button type='submit' className="btn-style-one text-center">
                                                    <div role="status">
                                                        <span>Update User</span>
                                                    </div>

                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default MyProfile;