import { useEffect, useState } from 'react'
import axios from 'axios'
import URLS from "../../Helpers/Constants";
import { Link, useNavigate, useParams } from 'react-router-dom';
import backIcon from '../../Assets/images/back-icon.svg';
import editIcon from '../../Assets/images/edit-ship-icon.svg';

function DestinationReport() {
    const [destinationReport, setDestinationreport] = useState([]);
    // const [editAccess, setEditAccess] = useState(true);
    const { id } = useParams();
    let arr = JSON.parse(localStorage.getItem("storageData"));
    let GetToken = ""
    if (arr) GetToken = `?access_token=${arr[0].id}`;
    const navigate = useNavigate();
    const getDestinationReport = (orderId: any) => {
        axios.post(`${URLS.BACKEND_URL}/api/destination-reports/get-destination-reports${GetToken}`, { orderId }).then((res) => {
            setDestinationreport(res.data.message);
            // Code for set inspection report id 
            if (res.data.message.length > 0) {
                let data1 = { ...arr[0], "destinationReportId": res.data.message[0].id };
                localStorage.clear();
                let storageData = JSON.parse(localStorage.getItem("storageData") || "[]");
                if (storageData.length === 0) {
                    storageData.push(data1);
                }
                localStorage.setItem("storageData", JSON.stringify(storageData));
            }
        }).catch((e) => {
            if (e.response.data.error.message === 'could not find accessToken') {
                localStorage.clear();
                navigate('/login');
            }
        });
    }

    // const getShipmentDetails = (id: any) => {
    //     axios.get(`${URLS.BACKEND_URL}/api/shipments/${id}${GetToken}`).then((res) => {
    //         setEditAccess(res.data.status)
    //     }).catch((err) => console.log(err))
    // }

    useEffect(() => {
        getDestinationReport(id);
    }, []);

    // let status = (val) => {
    //     if (val) getDestinationReport(id);
    // }
    return (
        <div className="main-content">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="goto-back">
                            <Link to='/dashboard'>
                                <img className="" src={backIcon} alt="" />
                                <span> Destination Report </span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    {/* <div className="col-md-3">
                        <div className="form-group custom-input">
                            <select className="form-select" aria-label="Default select example">
                                <option selected>Pallet 1</option>
                            </select>
                        </div>
                    </div> */}
                    {destinationReport.length === 0 && (arr[0]?.user.roleName === 'destination_loading_manager' || arr[0]?.user.roleName === 'shipping_manager') && <div className="col-md-9">
                        <div className="form-group custom-input float-start">
                            <button className="btn-style-one text-center" onClick={() => navigate(`/destination-report/${id}/add`)}>
                                Add Report
                            </button>
                        </div>
                    </div>}
                </div>
                {destinationReport.length > 0 && <div className="des-report">
                    <div className="row mt-2">
                        <div className="col-md-12 row pb-4">
                            <div className="col-md-10">
                                <p className="mng-id">
                                    Order ID: <span>{destinationReport[0].orderId}</span>
                                </p>
                            </div>
                            <div className="col-md-2">
                                <div className="float-end">
                                    <Link to={`/destination-report/${id}/edit`}>
                                        <img src={editIcon} alt="" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* Map from here */}

                        <div className="col-md-12">
                            <div className="des-details">
                                <h4>Load Shift</h4>
                                <p>
                                    {destinationReport[0].loadShift.description}
                                </p>
                                <div className="no-of-pic">
                                    <ul>
                                        {
                                            destinationReport[0].loadShift.images.length > 0 && destinationReport[0].loadShift.images.map((data) => (
                                                <li>
                                                    <a href={data} target="_blank" rel='noreferrer'>
                                                        <img src={data} alt="" />
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="des-details">
                                <h4>Primary Packaging Failure</h4>
                                <p>
                                    {destinationReport[0].packagingFailure.description}
                                </p>
                                <div className="no-of-pic">
                                    <ul>
                                        {
                                            destinationReport[0].packagingFailure.images.length > 0 && destinationReport[0].packagingFailure.images.map((data) => (
                                                <li>
                                                    <a href={data} target="_blank" rel='noreferrer'>
                                                        <img src={data} alt="" />
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="des-details">
                                <h4>Pallet Failure</h4>
                                <p>
                                    {destinationReport[0].palletFailure.description}
                                </p>
                                <div className="no-of-pic">
                                    <ul>
                                        {
                                            destinationReport[0].palletFailure.images.length > 0 && destinationReport[0].palletFailure.images.map((data) => (
                                                <li>
                                                    <a href={data} target="_blank" rel='noreferrer'>
                                                        <img src={data} alt="" />
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="des-details">
                                <h4>Dunnage Failure</h4>
                                <p>
                                    {destinationReport[0].dunnageFailure.description}
                                </p>
                                <div className="no-of-pic">
                                    <ul>
                                        {
                                            destinationReport[0].dunnageFailure.images.length > 0 && destinationReport[0].dunnageFailure.images.map((data) => (
                                                <li>
                                                    <a href={data} target="_blank" rel='noreferrer'>
                                                        <img src={data} alt="" />
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="des-details">
                                <h4>Water Damage</h4>
                                <p>
                                    {destinationReport[0].waterDamage.description}
                                </p>
                                <div className="no-of-pic">
                                    <ul>
                                        {
                                            destinationReport[0].waterDamage.images.length > 0 && destinationReport[0].waterDamage.images.map((data) => (
                                                <li>
                                                    <a href={data} target="_blank" rel='noreferrer'>
                                                        <img src={data} alt="" />
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="des-details">
                                <h4>Exessive Impact</h4>
                                <p>
                                    {destinationReport[0].excessiveImpact.description}
                                </p>
                                <div className="no-of-pic">
                                    <ul>
                                        {
                                            destinationReport[0].excessiveImpact.images.length > 0 && destinationReport[0].excessiveImpact.images.map((data) => (
                                                <li>
                                                    <a href={data} target="_blank" rel='noreferrer'>
                                                        <img src={data} alt="" />
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="des-details">
                                <h4>Other</h4>
                                <p>
                                    {destinationReport[0].other.description}
                                </p>
                                <div className="no-of-pic">
                                    <ul>
                                        
                                        {
                                            destinationReport[0].other.images.length > 0 && destinationReport[0].other.images.map((data) => (
                                                <li>
                                                    <a href={data} target="_blank" rel='noreferrer'>
                                                        <img src={data} alt="" />
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>}
                {!destinationReport.length && <div className="des-report">
                    <div className="row mt-2">
                        {/* Map from here */}
                        <div className="col-md-12">
                            <div className="des-details">
                                <h3>No Reports Has Been Added. Please Add New Report</h3>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default DestinationReport