import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: { dunnageDimensions: [], editShipmentDetails: {}, dunnageSubcategoryDataContainer: [] },
};

const dunnageDimensionSlice = createSlice({
  name: "dunnageDimensions",
  initialState,
  reducers: {
    addDunnageDimensions: (state, action) => {

      if (state.data.dunnageDimensions && state.data.dunnageDimensions.length === 0) {
        state.data.dunnageDimensions.push(action.payload);
      }
      else {
        let count = 0;
        let newData = state.data.dunnageDimensions && state.data.dunnageDimensions.filter((data, index) => {
          if (data.dunnages[0].dunnageId === action.payload.dunnages[0].dunnageId) {
            count = index;
            return true;
          }
        });
        if (newData && newData.length > 0) {
          state.data.dunnageDimensions.splice(count, 1, action.payload);
        }
        else {
          state.data.dunnageDimensions && state.data.dunnageDimensions.push(action.payload)
        }
      }
    },
    removeDunnageDimensions: (state, action) => {
      if (action.payload) {
        state.data.dunnageDimensions = [];
      }
    },
    addEditShipmentDetails: (state, action) => {
      if (action.payload._id) {
        state.data.editShipmentDetails = action.payload
      }
    },
    addDunnageSubcategoryDataContainer: (state, action) => {
      if (action.payload.id) {
        state.data.dunnageSubcategoryDataContainer.push(action.payload)
      }
    },
    removeDunnageSubcategoryDataContainer: (state, action) => {
      if (action.payload.id && state.data.dunnageSubcategoryDataContainer.length > 0) {
        state.data.dunnageSubcategoryDataContainer.map((data: any, index: any) => {
          if (data.id === action.payload.id) {
            state.data.dunnageSubcategoryDataContainer.splice(index, 1);
          }
        });
      }
    },
    emptyDunnageDimensionsReducer: (state, action) => {
      if (action.payload) {
        state.data = { dunnageDimensions: [], editShipmentDetails: {}, dunnageSubcategoryDataContainer: [] }
      }
    }
  },
});

export const { addDunnageDimensions, removeDunnageDimensions, addEditShipmentDetails, addDunnageSubcategoryDataContainer, removeDunnageSubcategoryDataContainer,emptyDunnageDimensionsReducer } = dunnageDimensionSlice.actions;

export default dunnageDimensionSlice;
