import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import backIcon from '../../../Assets/images/back-icon.svg'
import ActiveSteps from '../ActiveSteps/ActiveSteps';
import { addPalletDimensions, emptyShipmentReducer } from '../../../Store/shipmentReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import URLs from '../../../Helpers/Constants';
import { emptyDunnageDimensionsReducer } from '../../../Store/dunnageDimensionReducer';

function PalletDimensionDetail() {

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    let { products, palletDetail, palletDimension } = useSelector((state: any) => state.shipment.data.shipmentData);
    const [palletDimensions, setPalletDimensions] = useState({ height: "", width: "", length: "", palletWeight: "" });
    let editShipmentDetails = JSON.parse(sessionStorage.getItem("editShipmentDetails"));
    let arr = JSON.parse(localStorage.getItem("storageData"));
    let GetToken = ""
    if (arr) GetToken = `?access_token=${arr[0].id}`

    window.onload = (event: any) => {
        if (editShipmentDetails) {
            navigate(`/edit-shipment/${editShipmentDetails._id}`);
        }
        else {
            if (arr[0]?.user?.roleName === "shipping_manager") navigate('/shipment-mode')
            else navigate('/dashboard');
        }
        // if (event) {
        //     navigate('/shipment-mode')
        // }
    };

    function addData(e: any) {
        if (!isNaN(e.target.value)) {
            if (Number(e.target.value) <= 0) {
                enqueueSnackbar(`Please input value greater than 0.`,
                    { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            }
            else if (Number(e.target.value) > 99999) {
                enqueueSnackbar(`Please input value greater than 99999.`,
                    { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            }
            setPalletDimensions({ ...palletDimensions, [e.target.name]: e.target.value });
        }
    }

    let handleSubmit = () => {
        if (editShipmentDetails) {
            navigate(`/edit-shipment/${editShipmentDetails._id}`);
        }
        else {
            if (palletDimensions.height && palletDimensions.width && palletDimensions.length && palletDimensions.palletWeight) {
                if (Number(palletDimensions.length) >= 10 && Number(palletDimensions.width) >= 10) {
                    let data = {
                        "palletId": palletDetail[0].id,
                        "productId": products[0].id,
                        "palletDimension": {
                            "length": palletDimensions.length,
                            "width": palletDimensions.width,
                            "height": palletDimensions.height
                        },
                        "palletWeight": palletDimensions.palletWeight
                    }
                    dispatch(addPalletDimensions(data));
                    navigate('/pallet-wrap-details');
                } else {
                    enqueueSnackbar("Pallet's length and width should be greater than equal to 10.", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
            }
            else {
                enqueueSnackbar("Please enter dimensions for each selected type of pallet.", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                navigate('/pallet-dimension-details');
            }
        }
    }

    useEffect(() => {
        if (palletDimension && Object.keys(palletDimension).length > 0) {
            setPalletDimensions(
                {
                    height: palletDimension.palletDimension.height,
                    width: palletDimension.palletDimension.width,
                    length: palletDimension.palletDimension.length,
                    palletWeight: palletDimension.palletWeight
                }
            )
        }
        else if (editShipmentDetails && editShipmentDetails.palletDimensions.length > 0) {
            setPalletDimensions(
                {
                    height: editShipmentDetails.palletDimensions[0]?.palletDimension?.height,
                    width: editShipmentDetails.palletDimensions[0]?.palletDimension.width,
                    length: editShipmentDetails.palletDimensions[0]?.palletDimension.length,
                    palletWeight: editShipmentDetails.palletDimensions[0]?.palletWeight
                }
            )
        }
        // logic for the clearing further data from the reducer
        if (!editShipmentDetails) {
            dispatch(emptyShipmentReducer(
                {
                    palletWrapDetails: [],
                    dunnage: [],
                    dunnageSubcategory: [],
                    palletWrapSpecifications: {},
                    otherType: "palletDimensions"
                }
            ));
            dispatch(emptyDunnageDimensionsReducer(true));
        }
    }, []);

    // const handleUpdate = () => {
    //     let palletDimensionsStatic = {
    //         "palletId": editShipmentDetails.palletDetails[0].id?editShipmentDetails.palletDetails[0].id:editShipmentDetails.palletDetails[0]._id,
    //         "productId": products[0].id,
    //         "palletDimension": {
    //             "length": palletDimensions.length,
    //             "width": palletDimensions.width,
    //             "height": palletDimensions.height
    //         },
    //         "palletWeight": palletDimensions.palletWeight
    //     }
    //     // Update data
    //     axios.post(`${URLs.BACKEND_URL}/api/pallet-dimension-details/${editShipmentDetails.palletDimensions[0]._id}/replace${GetToken}`, palletDimensionsStatic).then((res) => {
    //         if (res.data.id) {
    //             console.log('Shipment updated completely..!!');
    //             let data =[];
    //             data.push(res.data);
    //             sessionStorage.setItem("editShipmentDetails", JSON.stringify({...editShipmentDetails,"palletDimensions":data}));
    //         }
    //     });
    //     // Add view logs
    //     const view_data = {
    //         "userId": arr[0].userId,
    //         "subject": "Pallet Dimension",
    //         "shipmentId": editShipmentDetails._id
    //     }
    //     axios.post(`${URLs.BACKEND_URL}/api/view_logs/${GetToken}`, view_data).then((res) => {
    //         if (res.data.id) {
    //             console.log('View logs added completely..!!');
    //             navigate(`/edit-shipment/${editShipmentDetails._id}`);
    //         }
    //     }).catch((e)=>{
    //         if ( e.response.data.error.message === 'could not find accessToken' ) {
    //           localStorage.clear();
    //           navigate('/login');
    //         }
    //     });  
    // }

    return (
        <div className="main-content">
            <div className="container">
                <div className="row mt-2">
                    <ActiveSteps />
                    <div className="col-md-12">
                        <div className={"goto-back " + (editShipmentDetails?._id && 'disableDiv')}>
                            <Link to="/pallet-details">
                                {!editShipmentDetails && <img className="" src={backIcon} alt="" />}
                                <span> Pallet Dimensions</span>
                            </Link>
                        </div>
                    </div>

                    {/* <!--------------------------------- Start Tabs Data ---------------------------------------> */}
                    <div className="mng_shipment_data">
                        <div className="tab-content tab-content-cus">
                            <div className="tab-pane active" id="tabs-1" role="tabpanel">
                                <div className="package-dia">
                                    <div className="row mt-3">
                                        <div className="col-md-12">
                                            <h6 className="mb-4"> Enter Dimensions of Pallet (Inches) </h6>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <div className="form-group custom-input">
                                                        <label>Length</label>
                                                        <input className='form-control' disabled={editShipmentDetails && true} type='text' value={palletDimensions.length} pattern="[0-9]*" placeholder='Enter Length' name='length' style={{ height: 40 }} onChange={addData} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-1 mid-con">
                                                    <div className="text-center"> X </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group custom-input">
                                                        <label>Width</label>
                                                        <input className='form-control' placeholder='Enter Width' value={palletDimensions.width} pattern="[0-9]*" type='text' name='width' disabled={editShipmentDetails && true} style={{ height: 40 }} onChange={addData} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-1 mid-con">
                                                    <div className="text-center"> X </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group custom-input">
                                                        <div className="form-group custom-input">
                                                            <label>Height</label>
                                                            <input className='form-control' disabled={editShipmentDetails && true} value={palletDimensions.height} pattern="[0-9]*" placeholder='Enter Height' type='text' name='height' style={{ height: 40 }} onChange={addData} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group custom-input">
                                                        <label>Enter Weight of a Pallet (lbs)</label>
                                                        <input className='form-control' disabled={editShipmentDetails && true} placeholder='Enter Pallet Weight' type='text' pattern="[0-9]*" value={palletDimensions.palletWeight} name='palletWeight' style={{ height: 40 }} onChange={addData} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!---------------------------------------------  End Tab Data  ----------------------------------------------->  */}

                </div>
                <div className="mt-2">
                    <div className="col-md-12">
                        <div className="form-group custom-input mt-4 text-center">
                            <button className="btn-style-one text-center" onClick={handleSubmit} >
                                {!editShipmentDetails ? `NEXT` : `BACK`}
                            </button>
                        </div>
                    </div>
                </div>
                {/* {editShipmentDetails?.packagingDetails?.length > 0 && <div className="mt-2">
                    <div className="col-md-12">
                        <div className="form-group custom-input mt-4 text-center">
                            <button className="btn-style-one text-center" onClick={handleUpdate} >
                                UPDATE
                            </button>
                        </div>
                    </div>
                </div>} */}
            </div>
        </div>
    )
}

export default PalletDimensionDetail