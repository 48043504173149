import axios from 'axios';
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import URLS from '../../Helpers/Constants';
import { Icon } from 'semantic-ui-react'
import backIcon from '../../Assets/images/back-icon.svg'
import { addOtherSpecifications, addPalletDetail, addSelectedDunnage, addSelectedProducts } from '../../Store/shipmentReducer';
import { addDunnageDimensions, addEditShipmentDetails } from '../../Store/dunnageDimensionReducer';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';

function EditShipment() {
  const { shipmentId } = useParams();
  const [shipmentDetails, setShipmentDetails] = useState([]);
  const [allAccessedUsers, setAllAccessedUsers] = useState([]);
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [accessType, setAccessType] = useState("view");
  const [role, setRole] = useState("origin_loading_manager");
  const navigate = useNavigate();
  let editShipmentDetails = JSON.parse(sessionStorage.getItem("editShipmentDetails"));
  const dispatch = useDispatch();
  let arr = JSON.parse(localStorage.getItem("storageData"));
  let GetToken = ""
  if (arr) {
    if (localStorage.getItem("refresKey")) {
      localStorage.removeItem("refresKey");
    }
    GetToken = `?access_token=${arr[0].id}`
  }
  useEffect(() => {
    axios.get(`${URLS.BACKEND_URL}/api/shipments/${shipmentId}${GetToken}`).then((res) => {
      if (res.data.id) {
        let data = []
        data.push(res.data);
        setShipmentDetails(data);
      }
    }).catch((err) => console.log(err));
    // code for prefilling data in reducer
    if (editShipmentDetails._id) {
      // Code for getting user acces to shipment.
      getAllAccessedUsers(editShipmentDetails._id)
      // Add products and dunnage to reducer
      axios.post(`${URLS.BACKEND_URL}/api/products/get-products-icon${GetToken}`, { modelName: "products" })
        .then((res) => {
          let productList = res.data.message;
          editShipmentDetails.products.map((data: any) => {
            productList.filter((item: any) => {
              if (data._id === item.id) {
                dispatch(addSelectedProducts(item));
                return true;
              }
            });
          });
        }).catch((e) => {
          if (e.response.data.error.message === 'could not find accessToken') {
            localStorage.clear();
            navigate('/login');
          }
        });

      // dunnage
      editShipmentDetails.products.map((productData: any) => {
        if (productData._id === editShipmentDetails.dunnage[0].productId) {
          axios.post(`${URLS.BACKEND_URL}/api/products/get-products-icon${GetToken}`, { modelName: "dunnage", productId: productData?._id })
            .then((res) => {
              let productList = res.data.message;
              editShipmentDetails.dunnage.map((data: any) => {
                productList.filter((item: any) => {
                  if (data._id === item.id) {
                    dispatch(addSelectedDunnage(item));
                    return true;
                  }
                });
              });
            })
            .catch((e) => {
              if (e.response.data.error.message === 'could not find accessToken') {
                localStorage.clear();
                navigate('/login');
              }
            });
        }
      });
      // pallet
      editShipmentDetails.products.map((productData: any) => {
        if (productData._id === editShipmentDetails?.palletDetails[0]?.productId) {
          axios.post(`${URLS.BACKEND_URL}/api/products/get-products-icon${GetToken}`, { modelName: "pallet-details", productId: productData._id })
            .then((res) => {
              let productList = res.data.message;
              let newData = [];
              editShipmentDetails.palletDetails.map((data: any) => {
                productList.filter((item: any) => {
                  if (data._id === item.id) {
                    newData.push(item)
                    return true;
                  }
                });
              });
              dispatch(addPalletDetail(newData));
            })
            .catch((e) => {
              if (e.response.data.error.message === 'could not find accessToken') {
                localStorage.clear();
                navigate('/login');
              }
            });
        }
      });

      if (editShipmentDetails?.OtherSpecifications.length > 0) {
        editShipmentDetails?.OtherSpecifications.map((data: any) => {
          dispatch(addOtherSpecifications(data))
        })
      }
      // case for dunnage dimensions
      // console.log(shipment?.dunnageDimensions[0])
      if (editShipmentDetails?.dunnageDimensions[0].length > 0) {
        editShipmentDetails?.dunnageDimensions[0].map((data: any) => {
          dispatch(addDunnageDimensions(data))
        })
      }
    }
  }, []);

  const checkUserAccess = (shipment: any) => {
    if (shipment.status[0] == true) {
      if (arr[0].user.roleName === 'shipping_manager') {
        return 'edit'
      }
      else if (arr[0].user.roleName === 'origin_loading_manager' || arr[0].user.roleName === 'destination_loading_manager') {
        return arr[0].user.accessType[shipment._id]
      }
    } else {
      return "view";
    }
  }

  // Cases for share shipment 

  const handleAccessType = (e: any) => {
    setAccessType(e.currentTarget.value);
  }

  const resetShareData = () => {
    setAccessType("view");
    setEmail("");
    setRole("origin_loading_manager");
  }

  const setEmailValue = (data) => {
    setIsEmailValid(false);
    setEmail(data);
  }

  const handleShare = () => {
    if (email && editShipmentDetails) {
      let emailregex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
      if (emailregex.test(email)) {
        let oldShipment = editShipmentDetails?._id;
        let data = {
          accessType: accessType.toLowerCase() || 'view',
          email: email || '',
          role: role || '',
          creatorId: arr[0].userId || '',
          shipmentId: oldShipment || ''
        };
        axios.post(`${URLS.BACKEND_URL}/api/users/share-shipment`, data).then(result => {
          if (result) {
            enqueueSnackbar("Shipment shared successfully.", { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            getAllAccessedUsers(editShipmentDetails._id);
            document.getElementById("closebutton").click();
          }
        }).catch(err => {
          console.log(err);
          enqueueSnackbar("Error while creating loading manager. Please try again!!", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
        })
      } else {
        setIsEmailValid(true);
      }
    } else {
      enqueueSnackbar("Email is a required field..!!", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
    }
  }

  const getAllAccessedUsers = (shipmentId: any) => {
    axios.post(`${URLS.BACKEND_URL}/api/users/user-access-shipment${GetToken}`, { "shipmentId": shipmentId })
      .then((res) => {
        setAllAccessedUsers(res.data.users);
      }).catch((e) => {
        if (e.response.data.error.message === 'could not find accessToken') {
          localStorage.clear();
          navigate('/login');
        }
      });
  }

  return (
    <>
      <div className="main-content">
        <div className="container">
          <div className="row mt-2">
            <div className="col-md-12">
              <div className="goto-back ">
                <Link to="/dashboard">
                  <img className="" src={backIcon} alt="" />
                  <span> {editShipmentDetails?.status[0] ? arr[0]?.user?.accessType[shipmentId] === 'edit' ? "Edit Shipment" : "View Shipment" : "View Shipment"} </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12" id="edit_shipment">
              {shipmentDetails[0]?.productId?.length > 0 && <div className="notif-area">
                <p>Products </p>  <Icon name='eye' size='large' onClick={() => navigate('/add-new-product')} />
                {/* <p><span className="float-start"> 50 minutes ago </span> <span className="float-end">Feb 25, 2023</span></p> */}
              </div>}
              {shipmentDetails[0]?.productSubcategoryId?.length > 0 && <div className="notif-area">
                <p>Product Subcategories </p>  <Icon name='eye' size='large' onClick={() => navigate('/subcategories-of-product')} />
                {/* <p><span className="float-start"> 50 minutes ago </span> <span className="float-end">Feb 25, 2023</span></p> */}
              </div>}
              {shipmentDetails[0]?.packageDetailId?.length > 0 && <div className="notif-area">
                <p>Packaging Details </p>
                <Icon name='eye' size='large' onClick={() => navigate('/packaging-details')} />
                {/* <p><span className="float-start"> 50 minutes ago </span> <span className="float-end">Feb 25, 2023</span></p> */}
              </div>}
              {shipmentDetails[0]?.packageDimensionId?.length > 0 &&
                <div className="notif-area">
                  <p>Package Dimensions </p>
                  {checkUserAccess(editShipmentDetails) === 'view' && <Icon name='eye' size='large' onClick={() => navigate('/packaging-dimension-details')} />}
                  {checkUserAccess(editShipmentDetails) === 'edit' && <Icon name='write' size='large' onClick={() => navigate('/packaging-dimension-details')} />}
                </div>
              }
              {shipmentDetails[0]?.palletDetailId?.length > 0 && <div className="notif-area">
                <p>Pallet Details</p>  <Icon name='eye' size='large' onClick={() => navigate('/pallet-details')} />
                {/* <p><span className="float-start"> 50 minutes ago </span> <span className="float-end">Feb 25, 2023</span></p> */}
              </div>}
              {shipmentDetails[0]?.palletDimensionId?.length > 0 && <div className="notif-area">
                <p>Pallet Dimension </p>  <Icon name='eye' size='large' onClick={() => navigate('/pallet-dimension-details')} />
                {/* <p><span className="float-start"> 50 minutes ago </span> <span className="float-end">Feb 25, 2023</span></p> */}
              </div>}
              {shipmentDetails[0]?.palletWrapdetailId?.length > 0 && <div className="notif-area">
                <p>PalletWrap Details </p>  <Icon name='eye' size='large' onClick={() => navigate('/pallet-wrap-details')} />
                {/* <p><span className="float-start"> 50 minutes ago </span> <span className="float-end">Feb 25, 2023</span></p> */}
              </div>}
              {shipmentDetails[0]?.dunnageId?.length > 0 && <div className="notif-area">
                <p>Dunnage Details </p>  <Icon name='eye' size='large' onClick={() => navigate('/dunnage')} />
                {/* <p><span className="float-start"> 50 minutes ago </span> <span className="float-end">Feb 25, 2023</span></p> */}
              </div>}
              {shipmentDetails[0]?.dunnageId?.length > 0 && <div className="notif-area">
                <p>DunnageSubcategory Details </p>  <Icon name='eye' size='large' onClick={() => navigate('/dunnage-subcategories')} />
                {/* <p><span className="float-start"> 50 minutes ago </span> <span className="float-end">Feb 25, 2023</span></p> */}
              </div>}
              {editShipmentDetails && <div className="notif-area" style={{ cursor: "pointer" }} data-toggle="modal" data-target={"#share"}>
                <p> Share Shipment </p>  <Icon name='external share' size='large' />
                {/* <p><span className="float-start"> 50 minutes ago </span> <span className="float-end">Feb 25, 2023</span></p> */}
              </div>}
              <div className="modal fade" id="share" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1}
                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header custom-model-header">
                      <h5 className="modal-title" id="staticBackdropLabel">Share </h5>
                      <button type="button" className="btn btn-default btn-close" id="closebutton" data-dismiss="modal" onClick={resetShareData}>
                      </button>
                      <img src="images/close.svg" alt="" className="" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body pt-4 pb-5">
                      <div className="row mt-3">
                        <div className="col-lg-12">
                          <div className="form-group custom-input">
                            <label htmlFor="text"> User Type </label>
                            <select className="form-select" aria-label="Default select example" value={role} onChange={(e) => setRole(e.target.value)}>
                              <option selected value="origin_loading_manager">Origin Loading Manager</option>
                              <option value="destination_loading_manager">Destination Loading Manager</option>
                            </select>
                          </div>
                          <div className="form-group custom-input">
                            <label htmlFor="usr">Email Address</label>
                            <input type="text" onChange={(e) => setEmailValue(e.target.value)} value={email} className="form-control" id="usr" />
                            <span className='errorText'>{isEmailValid && "Please enter a valid email"}</span>
                          </div>
                          <h6 className="mb-3">Access Type</h6>
                          <div className="form-check float-start me-4">
                            <input className="form-check-input" type="radio" id="check1" name="view" value="view" checked={accessType == "view"} onChange={handleAccessType} />
                            <label className="form-check-label">View</label>
                          </div>
                          <div className="form-check float-start">
                            <input className="form-check-input" type="radio" id="check1" name="edit" value="edit" checked={accessType == "edit"} onChange={handleAccessType} />
                            <label className="form-check-label">Edit</label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-lg-12">
                          <button type="button" className="btn btn-primary Generate-btn" onClick={handleShare}>Share</button>
                          {/* <button type="button" className="btn btn-default" data-dismiss="modal">Close</button> */}
                        </div>
                      </div>
                      <br />
                      {allAccessedUsers.length > 0 && <h6 className="mb-3">Shared With :</h6>}
                      {allAccessedUsers.length > 0 && allAccessedUsers.map((user) => (
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                          <div style={{ fontSize: "15px" }}>{user.email}</div>
                          <div style={{ fontSize: "15px" }}>{user.accessType === 'edit' ? "Edit" : "View"}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditShipment