import { useState, useEffect } from 'react'
import checkMarkIcon from '../../../Assets/images/checkmark.svg'
import { useDispatch, useSelector } from 'react-redux';
import { addSelectedDunnage, removeDunnage } from '../../../Store/shipmentReducer';

function DunnageData({ dunnageData }) {
    const [clicked, setClicked] = useState(false);
    let { dunnage } = useSelector((state: any) => state.shipment.data.shipmentData);
    let editShipmentDetails = JSON.parse(sessionStorage.getItem("editShipmentDetails"));
    const dispatch = useDispatch();
    let handleClick = () => {
        if (!editShipmentDetails?._id) {
            setClicked(!clicked);
        }
    };
    useEffect(() => {
        if (clicked) {
            dispatch(addSelectedDunnage(dunnageData));
        }
        else {
            dispatch(removeDunnage(dunnageData));
        }
    }, [clicked]);

    useEffect(() => {
        if (dunnage.length > 0) {
            dunnage.forEach((prod: any) => {
                if (prod.id == dunnageData.id || prod._id == dunnageData.id) {
                    setClicked(true);
                }
            })
        }
        // code for edit shipment content
        if (editShipmentDetails?.dunnage?.length > 0 && dunnage.length === 0) {
            editShipmentDetails.dunnage.forEach((prod: any) => {
                if (prod._id === dunnageData.id) {
                    setClicked(true);
                    // selectedProduct(dunnage);
                }
            })
        }
    }, []);
    return (
        <>
            <div className="col-md-2 col-sm-6 col-6 d-flex justify-content-center"   >
                <div className="item d-flex flex-column justify-content-center align-items-center">
                    <div className="item-details d-flex justify-content-center align-items-center" onClick={handleClick}>
                        {clicked && <div style={{ position: "absolute", top: 0, right: 7 }} ><img src={checkMarkIcon} alt='' /></div>}
                        <img src={dunnageData?.icon?.iconUrl} alt="" id={dunnageData.id} />
                    </div>
                    <div className="text-center mt-2">{dunnageData.name}</div>
                </div>
            </div>
        </>
    )
}

export default DunnageData