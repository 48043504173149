export default {
    // BACKEND URLs BELOW
    // BACKEND_URL: process.env.REACT_APP_API_URL || 'http://localhost:3000',   // LOCAL
    // BACKEND_URL: process.env.REACT_APP_API_URL || 'http://159.89.234.66:9014',   // DEV
    // BACKEND_URL: process.env.REACT_APP_API_URL || 'http://159.89.234.66:9028',   // QA
    // BACKEND_URL: process.env.REACT_APP_API_URL || 'http://159.89.234.66:9031',  // PROD
    BACKEND_URL: process.env.REACT_APP_API_URL || 'https://backend.shipitpro.com',   // AWS

    // COMMON URLS BELOW
    BASE_URL: process.env.REACT_APP_BASE_URL || 'http://localhost:4412',
    // UNITY_URL: process.env.UNITY_LINK || 'http://159.89.234.66:9026', // DEV
    // UNITY_URL: process.env.UNITY_LINK || 'http://159.89.234.66:9008', // QA
    // UNITY_URL: process.env.UNITY_LINK || 'http://159.89.234.66:9039', // PROD
    UNITY_URL: process.env.UNITY_LINK || 'https://unity.shipitpro.com', // AWS
}