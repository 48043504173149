import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ActiveSteps from '../ActiveSteps/ActiveSteps'
import backIcon from '../../../Assets/images/back-icon.svg'
import cubeImage from '../../../Assets/images/cube.svg'
import togglerImage from '../../../Assets/images/toggle-menu.svg'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { addShipmentId, emptyShipmentReducer } from '../../../Store/shipmentReducer'
import URLs from '../../../Helpers/Constants';
import { removeDunnageDimensions } from '../../../Store/dunnageDimensionReducer'
import { Unity, useUnityContext } from 'react-unity-webgl'
import { Loader } from 'rsuite';
import { enqueueSnackbar } from 'notistack';
// import GetToken from '../../../Helpers/StorageFunction'

function VisualizationOfLoadedTruck() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { shipmentId } = useParams();
    let arr = JSON.parse(localStorage.getItem("storageData"));
    let GetToken = ""
    let { dunnage, palletWrapDetails, products, palletDetail } = useSelector((state: any) => state.shipment.data.shipmentData);
    let { shipmentData } = useSelector((state: any) => state.shipment.data);
    let { dunnageDimensions } = useSelector((state: any) => state.dunnageDimensions.data);
    let editShipmentDetails = JSON.parse(sessionStorage.getItem("editShipmentDetails"));
    if (arr && arr[0]?.id) GetToken = `?access_token=${arr[0].id}`;

    // Share data
    const [accessType, setAccessType] = useState("view");
    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [role, setRole] = useState("origin_loading_manager");
    const [grossWeight, setGrossWeight] = useState(0);

    // Logic for the refress page
    window.onload = (event: any) => {
        handleViewShipment(shipmentId);
        if (editShipmentDetails) {
            navigate(`/edit-shipment/${editShipmentDetails._id}`);
        }
        else {
            if (arr[0]?.user?.roleName === "shipping_manager") {
                if (!localStorage.getItem("refresKey")) {
                    navigate('/shipment-mode')
                }
            }
            else navigate('/dashboard');
        }
        // if (event) {
        //     navigate('/shipment-mode')
        // }
    };

    let handleAddShipment = () => {
        if (dunnageDimensions.length > 0) {
            let dunnageIds = shipmentData.dunnage.map((data) => {
                return data.id;
            });
            let dunnageSubcategoryIds = (shipmentData.dunnageSubcategory.length > 0) ? shipmentData.dunnageSubcategory : [];
            let data = {
                "dunnageId": dunnageIds,
                "dunnageSubcategoryId": dunnageSubcategoryIds,
                "OtherSpecifications": shipmentData.otherSpecifications,
                "dunnageDimensions": dunnageDimensions,
                "isCompleted": true
            };
            axios.patch(`${URLs.BACKEND_URL}/api/shipments/${shipmentData.shipmentId}${GetToken}`, data).then((res) => {
                if (res.data.isCompleted) {
                    console.log('Shipment Finalised completely..!!');
                }
            }).catch((e) => {
                if (e.response.data.error.message === 'could not find accessToken') {
                    localStorage.clear();
                    navigate('/login');
                }
            });
        } else {
            navigate("/dunnage");
        }
    }

    function handleCacheControl(url: any) {
        if (url && (url.match(/\.data/) || url.match(/\.bundle/))) {
            return "must-revalidate";
        }
        if (url && (url.match(/\.mp4/) || url.match(/\.wav/))) {
            return "immutable";
        }
        return "no-store";
    }

    const { unityProvider, loadingProgression, isLoaded, sendMessage } = useUnityContext({
        loaderUrl: "build/CargoContainer.loader.js",
        dataUrl: "build/CargoContainer.data",
        frameworkUrl: "build/CargoContainer.framework.js",
        codeUrl: "build/CargoContainer.wasm",
        cacheControl: handleCacheControl
    });

    useEffect(() => {
        sendMessage("Shipment", "Shipment", shipmentData.shipmentId);
    }, [isLoaded]);

    // Logic for the functioning og back button
    const handleGoBack = () => {
        if (products.length > 0) {
            if (dunnageDimensions.length > 0) {
                navigate("/dunnage-subcategories");
            }
            else {
                if (palletDetail.length === 0) {
                    navigate("/pallet-details");
                }
                else {
                    navigate("/pallet-wrap-details");
                }
            }
        } else {
            navigate("/dashboard");
        }
    }

    const handleAccessType = (e: any) => {
        setAccessType(e.currentTarget.value);
    }

    const resetShareData = () => {
        setAccessType("view");
        setEmail("");
        setRole("origin_loading_manager");
    }

    const setEmailValue = (data) => {
        setIsEmailValid(false);
        setEmail(data);
    }

    const handleShare = () => {
        if (email) {
            let emailregex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
            if (emailregex.test(email)) {
                let oldShipment = localStorage.getItem("shipmentId");
                let data = {
                    accessType: accessType.toLowerCase() || 'view',
                    email: email || '',
                    role: role || '',
                    creatorId: arr[0].userId || '',
                    shipmentId: oldShipment || ''
                };
                axios.post(`${URLs.BACKEND_URL}/api/users/share-shipment`, data).then(result => {
                    if (result) {
                        dispatch(emptyShipmentReducer({
                            shipmentMode: {},
                            products: [],
                            subcategories: [],
                            packagingDetails: {},
                            packageDimensions: [],
                            palletDetail: [],
                            palletDimensions: {},
                            palletWrapDetails: [],
                            dunnage: [],
                            dunnageSubcategory: [],
                            otherSpecifications: [],
                            palletWrapSpecifications: {}
                        }));
                        dispatch(removeDunnageDimensions(true));
                        let oldShipment = localStorage.getItem("shipmentId");
                        if (oldShipment) localStorage.removeItem("shipmentId");
                        document.getElementById("closebutton").click();
                        navigate("/dashboard");
                    }
                }).catch(err => {
                    console.log(err);
                    enqueueSnackbar("Error while creating loading manager. Please try again!!", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                })
            } else {
                setIsEmailValid(true);
            }
        } else {
            enqueueSnackbar("Email is a required field..!!", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
        }
    }
    const handleViewShipment = (shipmentId: any) => {
        axios.post(`${URLs.BACKEND_URL}/api/shipments/load-calculator/`, { shipmentId, userId: arr[0]?.userId }).then((data) => {
            dispatch(addShipmentId(shipmentId));
            localStorage.setItem("refresKey", JSON.stringify("refres"));
            localStorage.setItem("grossWeight", data?.data?.unityData?.grossWeight);
        }).catch(err => {
            enqueueSnackbar(err.response.data.error.message ? err.response.data.error.message : "Please check your shipment details..!!", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
        });
    }

    return (
        <>
            <div className="main-content">
                <div className="container">
                    <div className="row mt-2">
                        <ActiveSteps />
                        <div className="col-md-6">
                            <div className="goto-back" onClick={handleGoBack}>
                                <img className="" src={backIcon} alt="" />
                                <span style={{ fontSize: "large", cursor: "pointer" }}> Visualization of Loaded Truck </span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group custom-input mt-4 text-center float-end">
                                {/* <Link to={dunnage.length === 0 ? '/dunnage' : '/dashboard'}> */}
                                {
                                    products.length > 0 && (
                                        <button className="btn-style-one text-center" data-toggle="modal" data-target={dunnage.length > 0 ? "#share" : ""} onClick={handleAddShipment} >
                                            {dunnageDimensions.length > 0 ? 'Finalize Shipment' : 'Secure Your Product'}
                                        </button>
                                    )
                                }
                                {/* </Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="package-dia pd-1530">
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <div className="container-frame-area">
                                    <iframe style={{ height: "520px", width: "100%", borderRadius: "15px" }} src={`${URLs.UNITY_URL}/${shipmentData.shipmentId}/${arr[0].userId}/web`} title='container' />
                                </div>
                            </div>
                            <div className="col-md-3 text-center col-sm-6 col-6">
                                <p className="mng-sec">Container Size: <span>40'</span></p>
                            </div>
                            <div className="col-md-3 text-center col-sm-6 col-6">
                                <p className="mng-sec">Dimensions: <span> 39.5ft <span className="xs"> X </span> 7.8ft <span className="xs"> X </span> 8.9ft </span></p>
                            </div>
                            <div className="col-md-3 text-center col-sm-6 col-6">
                                <p className="mng-sec">Max. Weight: <span>58500 lbs</span></p>
                            </div>
                            <div className="col-md-3 text-center col-sm-6 col-6">
                                <p className="mng-sec">Gross. Weight: <span>{Number(localStorage.getItem("grossWeight")) > 0 ? localStorage.getItem("grossWeight") + ' lbs' : "N/A"}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="share" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1}
                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header custom-model-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Share </h5>
                            <button type="button" className="btn btn-default btn-close" id="closebutton" data-dismiss="modal" onClick={resetShareData}>
                            </button>
                            <img src="images/close.svg" alt="" className="" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body pt-4 pb-5">
                            <div className="row mt-3">
                                <div className="col-lg-12">
                                    <div className="form-group custom-input">
                                        <label htmlFor="text"> User Type </label>
                                        <select className="form-select" aria-label="Default select example" value={role} onChange={(e) => setRole(e.target.value)}>
                                            <option selected value="origin_loading_manager">Origin Loading Manager</option>
                                            <option value="destination_loading_manager">Destination Loading Manager</option>
                                        </select>
                                    </div>
                                    <div className="form-group custom-input">
                                        <label htmlFor="usr">Email Address</label>
                                        <input type="text" onChange={(e) => setEmailValue(e.target.value)} value={email} className="form-control" id="usr" />
                                        <span className='errorText'>{isEmailValid && "Please enter a valid email"}</span>
                                    </div>
                                    <h6 className="mb-3">Access Type</h6>
                                    <div className="form-check float-start me-4">
                                        <input className="form-check-input" type="radio" id="check1" name="view" value="view" checked={accessType == "view"} onChange={handleAccessType} />
                                        <label className="form-check-label">View</label>
                                    </div>
                                    <div className="form-check float-start">
                                        <input className="form-check-input" type="radio" id="check1" name="edit" value="edit" checked={accessType == "edit"} onChange={handleAccessType} />
                                        <label className="form-check-label">Edit</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-12">
                                    <button type="button" className="btn btn-primary Generate-btn" onClick={handleShare}>Share</button>
                                    {/* <button type="button" className="btn btn-default" data-dismiss="modal">Close</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VisualizationOfLoadedTruck