import { Link, useNavigate } from 'react-router-dom';
import './OtpValidation.css';
import Logo from '../../Assets/img/logo.png';
import { FieldGroup, FormBuilder, Validators, AbstractControl, FieldControl } from 'react-reactive-form';
import { validateOtp } from '../../Services/commonService';
import { useSnackbar } from 'notistack'
import axios from 'axios';
import '../../Assets/css/style.css';
import URLS from '../../Helpers/Constants';
import { isDisabled } from '@testing-library/user-event/dist/utils';

function OtpValidation() {
    // Assigning Global Variables
    let arr = JSON.parse(localStorage.getItem("forgotPasswordData"));
    let arr1 = JSON.parse(localStorage.getItem("resetPasswordTokenData"));
    console.log(arr1);
    
    let emailOfUser = "";
    if (arr && arr[0]) emailOfUser = arr[0];
    else if (arr1) emailOfUser = arr1[0].verfiedMail
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar()
    const loginForm = FormBuilder.group({
        number1: ["",[Validators.required]],
        number2: ["", [Validators.required]],
        number3: ["", [Validators.required]],
        number4: ["", [Validators.required]]
    });


    // ------------ Function for resend the verication code -----------------------------------------------------
    let handleClick = () => {
        let bodyData = { email: emailOfUser, roleType: "shipping_manager" };
        axios.post(`${URLS.BACKEND_URL}/api/users/forgot-password`, bodyData).then((response: any) => {
            if (response.data.message.verifiedEmail) {
                enqueueSnackbar(response.data.message.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            } else {
                enqueueSnackbar(response.data.response.data.error.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            }
        });
    }

    let handleFocus = (ref: any, event: any) => {
        // if (event.key == "Backspace")
        if(event.target.value && event.target.value > 9) {
            let inputValue = event.target.value.split("").splice(0, event.target.value.length - 1).join("")
            event.target.value = inputValue;
        } else if (/[0-9]/i.test(event.key)) {
            document.getElementById(ref)?.focus();
        } else {
            const inputElement = event.target;
            const inputValue = inputElement.value;
            inputElement.value = inputValue.replace(/[^\d]/g, '');
        }
    }

    let handleValues = (event: any) => {
        if(event.target.value && event.target.value > 9) {
            let inputValue = event.target.value.split("").splice(0, event.target.value.length - 1).join("")
            event.target.value = inputValue;
        } else if (/[0-9]/i.test(event.key)) {} 
        else {
            const inputElement = event.target;
            const inputValue = inputElement.value;
            inputElement.value = inputValue.replace(/[^\d]/g, '');
        }
    }

    // ------------ Function for submitting form data -----------------------------------------------------
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        let { number1, number2, number3, number4 } = loginForm.value;
        if (!number1 || !number2 || !number3 || !number4) {
            enqueueSnackbar("Number is required", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
        }

        if (loginForm.valid) {
            let verificationCode = number1 + number2 + number3 + number4
            let data = await validateOtp({ "otp": verificationCode, "email": emailOfUser });
            if (data.message.message) {
                data.message = { ...data.message, verfiedMail: emailOfUser }
                enqueueSnackbar(data.message.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                localStorage.clear();
                let resetPasswordTokenData = JSON.parse(localStorage.getItem("resetPasswordTokenData") || "[]");
                if (resetPasswordTokenData.length === 0) {
                    resetPasswordTokenData.push(data.message);
                }
                localStorage.setItem("resetPasswordTokenData", JSON.stringify(resetPasswordTokenData));
                navigate('/reset-password');
            }
            else {
                enqueueSnackbar(data.response.data.error.message,
                    { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            }
        }
        else {
            loginForm.markAsTouched();
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 d-flex align-items-center justify-content-center logo-bg">
                        <div className="logo text-center">
                            <Link to="#"><img src={Logo} alt="Logo" /></Link>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="login_right_side">
                            <div className="login_form">
                                <h2 className="text-white text-center pb-1">Password reset?</h2>
                                <p className="text-white text-center mb-5">We sent a code to <strong>{emailOfUser}</strong></p>

                                <FieldGroup
                                    control={loginForm}
                                    render={({ pristine, invalid, pending, value}: AbstractControl) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <ul className="otp-msg mt-3">
                                                    <li>
                                                        <FieldControl
                                                        //  formState={ value, disabled: true }
                                                            name='number1'
                                                            render={({ handler, touched, hasError }: AbstractControl) => (
                                                                <div>
                                                                    <input type="number" min={0} max={9} onKeyUp={(event: any) => handleFocus('two', event)} className="form-control form-control2 mb-0" {...handler()} id="one" />
                                                                </div>
                                                            )}
                                                        />
                                                    </li>
                                                    <li>
                                                        <FieldControl
                                                            name='number2'
                                                            render={({ handler, touched, hasError }: AbstractControl) => (
                                                                <div>
                                                                    <input type="number"  min={0} max={9} onKeyUp={(event: any) => handleFocus('three', event)} className="form-control form-control2 mb-0" {...handler()} id="two" />
                                                                </div>
                                                            )}
                                                        />
                                                    </li>
                                                    <li>
                                                        <FieldControl
                                                            name='number3'
                                                            render={({ handler, touched, hasError }: AbstractControl) => (
                                                                <div>
                                                                    <input disabled={arr1 && true} type="number" min={0} max={9} onKeyUp={(event: any) => handleFocus('four', event)} className="form-control form-control2 mb-0" {...handler()} id="three" />
                                                                </div>
                                                            )}
                                                        />
                                                    </li>
                                                    <li>
                                                        <FieldControl
                                                            name='number4'
                                                            render={({ handler, touched, hasError }: AbstractControl) => (
                                                                <div>
                                                                    <input disabled={arr1 && true} type="number" min={0} max={9} onKeyUp={(event: any) => handleValues(event)} className="form-control form-control2 mb-0" {...handler()} id="four" />
                                                                    {/* <div className='mb-2'>
                                                                        <span className='errorText'>
                                                                            {(touched &&
                                                                                ((hasError("required") && "Number is required")))}
                                                                        </span>
                                                                    </div> */}
                                                                </div>
                                                            )}
                                                        />
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="form-group custom-input">
                                                <button type="submit" className="btn-style-one btn btn_default text-center afterhover">
                                                    <div className=" text-light align-top" role="status" >
                                                        <span>Continue</span>
                                                    </div>
                                                </button>
                                            </div>
                                            <p className="text-center text-light mt-4">Don't receive the email <a style={{ cursor: "pointer" }} className="secondry-color text-decoration-none resend-link" onClick={handleClick} >Click to resend</a></p>
                                            <div className="text-center mt-4">
                                                <Link to='/login' className="secondry-color">Back</Link>
                                            </div>
                                        </form>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default OtpValidation;