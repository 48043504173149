import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    shipmentData: {
      shipmentMode: {},
      products: [],
      subcategories: [],
      packagingDetails: {},
      packageDimensions: [],
      palletDetail: [],
      palletDimension: {},
      palletWrapDetails: [],
      dunnage: [],
      dunnageSubcategory: [],
      otherSpecifications: [],
      palletWrapSpecifications: {},
      shipmentId: ""
    }
  },
};

const shipmentSlice = createSlice({
  name: "shipment",
  initialState,
  reducers: {
    addShipmentMode: (state, action) => {
      if (Object.keys(action.payload).length > 0) {
        state.data.shipmentData = { ...state.data.shipmentData, shipmentMode: { ...action.payload } };
      }
    },
    addSelectedProducts: (state, action) => {
      if (state.data.shipmentData.products.length === 0) {
        if (action.payload) {
          state.data.shipmentData.products.push(action.payload)
        }
      }
      else {
        let newData = state.data.shipmentData.products.filter((data) => {
          if (data.id === action.payload.id) {
            return true;
          }
        });

        if (newData.length === 0) {
          state.data.shipmentData.products.push(action.payload);
        }
      }
    },
    removeProducts: (state, action) => {
      if (state.data.shipmentData.products.length > 0) {
        let newData = state.data.shipmentData.products.filter((data) => {
          if (data.id !== action.payload.id) {
            return true;
          }
        });
        state.data.shipmentData.products = newData;
      }
    },
    addSubcategories: (state, action) => {
      if (state.data.shipmentData.subcategories.length === 0) {
        if (action.payload) {
          state.data.shipmentData.subcategories.push(action.payload)
        }
      }
      else {
        let newData = state.data.shipmentData.subcategories.filter((subcategory) => {
          if (subcategory.id === action.payload.id) {
            return true;
          }
        });

        if (newData.length === 0) {
          state.data.shipmentData.subcategories.push(action.payload);
        }
      }
    },
    removeSubcategories: (state, action) => {
      if (state.data.shipmentData.subcategories.length > 0) {
        let newData = state.data.shipmentData.subcategories.filter((data) => {
          if (data.id !== action.payload.id) {
            return true;
          }
        });
        state.data.shipmentData.subcategories = newData;
      }
    },
    addPackagingDetails: (state, action) => {
      if (Object.keys(state.data.shipmentData.packagingDetails).length === 0) {
        state.data.shipmentData.packagingDetails = { [action.payload.productId]: action.payload }
      }
      else {
        state.data.shipmentData.packagingDetails = { ...state.data.shipmentData.packagingDetails, [action.payload.productId]: action.payload }
      }
    },
    removePackagingDetails: (state, action) => {
      if (Object.keys(state.data.shipmentData.packagingDetails).length > 0) {
        if (state.data.shipmentData.packagingDetails[action.payload.productId]?.id === action.payload.id) {
          delete state.data.shipmentData.packagingDetails[action.payload.productId];
          // state.data.shipmentData.packagingDetails = { ...state.data.shipmentData.packagingDetails,...item }
        }
      }
    },
    addSelectedDunnage: (state, action) => {
      if (state.data.shipmentData.dunnage.length === 0) {
        if (action.payload) {
          state.data.shipmentData.dunnage.push(action.payload)
        }
      }
      else {
        let newData = state.data.shipmentData.dunnage.filter((data) => {
          if (data.id === action.payload.id) {
            return true;
          }
        });

        if (newData.length === 0) {
          state.data.shipmentData.dunnage.push(action.payload);
        }
      }
    },
    removeDunnage: (state, action) => {
      if (state.data.shipmentData.dunnage.length > 0) {
        let newData = state.data.shipmentData.dunnage.filter((data) => {
          if (data.id !== action.payload.id) {
            return true;
          }
        });
        state.data.shipmentData.dunnage = newData;
      }
    },
    addDunnageSubcategories: (state, action) => {
      if (state.data.shipmentData.dunnageSubcategory.length === 0) {
        state.data.shipmentData.dunnageSubcategory.push(action.payload);
      }
      else {
        if (!state.data.shipmentData.dunnageSubcategory.includes(action.payload)) {
          state.data.shipmentData.dunnageSubcategory.push(action.payload);
        }
      }

    },
    removeDunnageSubcategories: (state, action) => {
      if (state.data.shipmentData.dunnageSubcategory.length > 0) {
        state.data.shipmentData.dunnageSubcategory = state.data.shipmentData.dunnageSubcategory.filter((data) => {
          if (data === action.payload) {
            return true;
          }
        })
      }
    },
    addPalletDetail: (state, action) => {
      if (action.payload.length > 0) {
        state.data.shipmentData = { ...state.data.shipmentData, palletDetail: action.payload };
      }
    },
    removePalletDetail: (state, action) => {
      if (state.data.shipmentData.palletDetail.length > 0 && action.payload) {
        state.data.shipmentData.palletDetail.pop();
      }
    },
    addPalletWrapDetail: (state, action) => {
      if (state.data.shipmentData.palletWrapDetails.length === 0) {
        if (action.payload) {
          state.data.shipmentData.palletWrapDetails.push(action.payload)
        }
      }
      else {
        let newData = state.data.shipmentData.palletWrapDetails.filter((data) => {
          if (data.id === action.payload.id) {
            return true;
          }
        });

        if (newData.length === 0) {
          state.data.shipmentData.palletWrapDetails.push(action.payload);
        }
      }
    },
    removePalletWrapDetail: (state, action) => {
      if (state.data.shipmentData.palletWrapDetails.length > 0) {
        let newData = state.data.shipmentData.palletWrapDetails.filter((data) => {
          if (data.id !== action.payload.id) {
            return true;
          }
        });
        state.data.shipmentData.palletWrapDetails = newData;
      }
    },
    addOtherSpecifications: (state, action) => {
      if (state.data.shipmentData.otherSpecifications.length === 0) {
        state.data.shipmentData.otherSpecifications.push(action.payload)
      }
      else {
        let count = 0;
        let newData = state.data.shipmentData.otherSpecifications.filter((data, index) => {
          if (data.type === action.payload.type) {
            count = index;
            return true;
          }
        });

        if (newData.length > 0) {
          if (newData[0].type === 'product') {
            state.data.shipmentData.otherSpecifications.splice(count, 1, action.payload);
          }
          else if (newData[0].type === 'subcategory') {
            let checkForProductData = state.data.shipmentData.otherSpecifications.filter((data, index) => {
              if (data.specification.productId === action.payload.specification.productId) {
                count = index;
                return true;
              }
            });
            if (checkForProductData.length > 0) {
              state.data.shipmentData.otherSpecifications.splice(count, 1, action.payload);
            }
            else {
              state.data.shipmentData.otherSpecifications.push(action.payload)
            }
          }
          else if (newData[0].type === 'packagingDetails') {
            let checkForProductData = state.data.shipmentData.otherSpecifications.filter((data, index) => {
              if (data.specification.productId === action.payload.specification.productId) {
                count = index;
                return true;
              }
            });
            if (checkForProductData.length > 0) {
              state.data.shipmentData.otherSpecifications.splice(count, 1, action.payload);
            }
            else {
              state.data.shipmentData.otherSpecifications.push(action.payload)
            }
          }
          else if (newData[0].type === 'palletWrap') {
            state.data.shipmentData.otherSpecifications.splice(count, 1, action.payload);
          }
          else if (newData[0].type === 'dunnage') {
            state.data.shipmentData.otherSpecifications.splice(count, 1, action.payload);
          }
          else if (newData[0].type === 'dunnageSubcategory') {
            let checkForProductData = state.data.shipmentData.otherSpecifications.filter((data, index) => {
              if (data.specification.dunnageId === action.payload.specification.dunnageId) {
                count = index;
                return true;
              }
            });
            if (checkForProductData.length > 0) {
              state.data.shipmentData.otherSpecifications.splice(count, 1, action.payload);
            }
            else {
              state.data.shipmentData.otherSpecifications.push(action.payload)
            }
          }
        }
        else {
          state.data.shipmentData.otherSpecifications.push(action.payload)
        }
      }
    },
    addPackageDimensions: (state, action) => {
      let count = 0;
      if (state.data.shipmentData.packageDimensions.length === 0) {
        state.data.shipmentData.packageDimensions.push(action.payload);
      }
      else {
        let newData = state.data.shipmentData.packageDimensions.filter((data, index) => {
          if (action.payload.productId === data.productId) {
            count = index;
            return true;
          }
        });
        if (newData.length > 0) {
          state.data.shipmentData.packageDimensions.splice(count, 1, action.payload);
        }
        else {
          state.data.shipmentData.packageDimensions.push(action.payload)
        }
      }

    },
    addPalletDimensions: (state, action) => {
      state.data.shipmentData.palletDimension = action.payload
    },
    addPalletWrapSpecifications: (state, action) => {
      state.data.shipmentData.palletWrapSpecifications = action.payload
    },
    emptyShipmentReducer: (state, action) => {
      if (action.payload.hasOwnProperty("otherType")) {
        let data = [...state.data.shipmentData.otherSpecifications]
        if (action.payload.otherType === 'product') {
          data = data.filter((item) => {
            if (item.type === action.payload.otherType) return true
          })
        }
        else if (action.payload.otherType === 'subcategory') {
          data = data.filter((item) => {
            if (item.type === action.payload.otherType || item.type === 'product') return true
          })
        }
        else if (action.payload.otherType === 'packagingDetails') {
          data = data.filter((item) => {
            if (item.type === action.payload.otherType || item.type === 'product' || item.type === 'subcategory') return true
          })
        }
        else if (action.payload.otherType === 'palletWrap') {
          data = data.filter((item) => {
            if (item.type === action.payload.otherType || item.type === 'product' || item.type === 'subcategory' || item.type === 'packagingDetails') return true
          })
        }
        else {
          data = data.filter((item) => {
            if (item.type === action.payload.otherType || item.type === 'product' || item.type === 'subcategory' || item.type === 'packagingDetails' || item.type === 'palletWrap') return true
          })
        }
        state.data.shipmentData = { ...state.data.shipmentData, otherSpecifications: data, ...action.payload }
      }
      else{
        state.data.shipmentData = action.payload;
      }
    },
    addShipmentId: (state, action) => {
      state.data.shipmentData.shipmentId = action.payload
    }
  },
});

export const { addShipmentId, emptyShipmentReducer, addPalletWrapSpecifications, addPalletDimensions, addPackageDimensions, addPalletWrapDetail, addShipmentMode, addSelectedProducts, addSubcategories, addPackagingDetails, addSelectedDunnage, addDunnageSubcategories, addPalletDetail, removeSubcategories, removeDunnage, removeDunnageSubcategories, removePackagingDetails, removePalletDetail, removePalletWrapDetail, addOtherSpecifications, removeProducts } = shipmentSlice.actions;

export default shipmentSlice;