import { useEffect, useState } from 'react';
// import ActiveSteps from '../ActiveSteps/ActiveSteps';
import '../../../Assets/css/style.css'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import SubcategoryData from './SubcategoryData';
import backIcon from '../../../Assets/images/back-icon.svg';
import { useSnackbar } from 'notistack';
import URLs from '../../../Helpers/Constants';
import { addOtherSpecifications, emptyShipmentReducer } from '../../../Store/shipmentReducer';
import { addEditShipmentDetails, emptyDunnageDimensionsReducer } from '../../../Store/dunnageDimensionReducer';
// import GetToken from '../../../Helpers/StorageFunction';

const SubcategoryOfProduct = () => {
    const navigate = useNavigate();
    const [input, setInput] = useState({
        name: "",
        productSpecifications: "",
        imageName: ""
    });
    const [subcategory, setSubcategory] = useState([]);
    const [isChemicals, setChemicals] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [buttonClicked, setButtonClicked] = useState("");
    const [showInputDiv, setShowInputDiv] = useState(false);
    let { products, subcategories, otherSpecifications } = useSelector((state: any) => state.shipment.data.shipmentData);
    let editShipmentDetails = JSON.parse(sessionStorage.getItem("editShipmentDetails"));
    let arr = JSON.parse(localStorage.getItem("storageData"));
    let GetToken = "";
    if (arr) GetToken = `?access_token=${arr[0].id}`;

    window.onload = (event: any) => {
        if (editShipmentDetails) {
            navigate(`/edit-shipment/${editShipmentDetails._id}`);
        }
        else {
            if (arr[0]?.user?.roleName === "shipping_manager") navigate('/shipment-mode')
            else navigate('/dashboard');
        }
        // if (event) {
        //     navigate('/shipment-mode')
        // }
    };

    useEffect(() => {
        axios.post(`${URLs.BACKEND_URL}/api/products/get-products-icon${GetToken}`, { modelName: "subcategories", productId: products[0]?.id }).then((res) => {
            let productList = res.data.message;
            let data = [];
            let newProducts = productList.filter((product: any) => {
                if (product.name !== 'Other') {
                    return true;
                }
            });
            data = newProducts.sort((a: any, b: any) => a.name.localeCompare(b.name));
            let otherData = productList.filter((product: any) => {
                if (product.name === 'Other') {
                    return true;
                }
            });
            data.push(otherData[0]);
            setSubcategory(data);

            document.getElementById(products[0].id).classList.add("active-button");
            if (products[0].name == "Chemicals") setChemicals(true);
            else setChemicals(false)
            setButtonClicked(products[0].id);
            // logic for the clearing further data from the reducer
            if (!editShipmentDetails) {
                dispatch(emptyShipmentReducer(
                    {
                        packagingDetails: {},
                        packageDimensions: [],
                        palletDetail: [],
                        palletDimensions: {},
                        palletWrapDetails: [],
                        dunnage: [],
                        dunnageSubcategory: [],
                        palletWrapSpecifications: {},
                        otherType: "subcategory"
                    }
                ));
                dispatch(emptyDunnageDimensionsReducer(true));
            }
        }).catch((e) => {
            if (e.response.data.error.message === 'could not find accessToken') {
                localStorage.clear();
                navigate('/login');
            }
        });
    }, []);

    useEffect(() => {
        if (subcategory.length > 0) {
            let newData = subcategory.filter((data) => {
                if (data.name === "Other") {
                    return true;
                }
            });
            let newSelectedData = subcategories.filter((data) => {
                if (data.name === "Other" && data.productId === newData[0].productId) {
                    return true;
                }
            });
            if (newSelectedData.length > 0) {
                setShowInputDiv(true);
            } else {
                setShowInputDiv(false);
            }
        }
    }, [subcategories]);

    const addData = (e: any) => {
        setInput({ ...input, [e.target.name]: e.target.value });
    }

    let handleCheck = () => {
        if (editShipmentDetails) {
            navigate(`/edit-shipment/${editShipmentDetails._id}`);
        }
        else {
            let goNext = false;
            if (subcategories.length === 0) {
                enqueueSnackbar("Please select a subcategory", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                navigate("/subcategories-of-product")
            }
            else {
                products.map((item, index) => {
                    if (index === products.length - 1) {
                        goNext = true;
                    }
                    let newData = subcategories.filter((data) => {
                        if (data.productId === item.id) {
                            return true;
                        }
                    });
                    if (newData.length === 0) {
                        enqueueSnackbar("Please select a subcategory from each selected product.",
                            { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    }
                    else {
                        let newOtherData = subcategory.filter((data) => {
                            if (data.name === "Other") {
                                return true;
                            }
                        });

                        let newSelectedData = subcategories.filter((data) => {
                            if (data.name === "Other" && data.productId === newOtherData[0].productId) {
                                return true;
                            }
                        });

                        if (newSelectedData.length > 0) {
                            if (input.name && input.productSpecifications) {
                                if (goNext) {
                                    navigate("/packaging-details")
                                }
                            }
                            else {
                                enqueueSnackbar("Please fill the all other fields.",
                                    { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            }
                        }
                        else {
                            if (goNext) {
                                navigate("/packaging-details")
                            }
                        }

                    }
                })
            }
        }
    }

    useEffect(() => {
        if (otherSpecifications && subcategory.length > 0) {
            let newData = otherSpecifications.filter((data) => {
                if (data.type === "subcategory" && data.specification.productId === subcategory[0].productId) {
                    return true;
                }
            });
            if (newData.length > 0) {
                setInput({
                    name: newData[0].specification.name,
                    productSpecifications: newData[0].specification.data,
                    imageName: ""
                })
            }
            else {
                setInput(
                    {
                        name: "",
                        productSpecifications: "",
                        imageName: ""
                    }
                )
            }
        }
    }, [subcategory]);

    useEffect(() => {
        if (input.name && input.productSpecifications) {
            let productData = products.filter((data) => {
                if (data.id === subcategory[0].productId) {
                    return true;
                }
            })
            let data = {
                "type": "subcategory",
                "specification": {
                    "name": input.name,
                    "data": input.productSpecifications,
                    "productId": productData[0].id,
                    "productName": productData[0].name
                }
            }
            dispatch(addOtherSpecifications(data));
        }
    }, [input]);

    const handleTabSwitch = (tab: any) => {
        axios.post(`${URLs.BACKEND_URL}/api/products/get-products-icon${GetToken}`, { modelName: "subcategories", productId: tab.id }).then((res) => {
            let productList = res.data.message;
            let data = [];
            let newProducts = productList.filter((product: any) => {
                if (product.name !== 'Other') {
                    return true;
                }
            });
            data = newProducts.sort((a: any, b: any) => a.name.localeCompare(b.name));
            let otherData = productList.filter((product: any) => {
                if (product.name === 'Other') {
                    return true;
                }
            });
            data.push(otherData[0]);
            setSubcategory(data);
            document.getElementById(buttonClicked).classList.remove("active-button");
            setButtonClicked(tab.id);
            document.getElementById(tab.id).classList.add("active-button");
            if (tab.name == "Chemicals") setChemicals(true);
            else setChemicals(false)
            setInput(
                {
                    name: "",
                    productSpecifications: "",
                    imageName: ""
                }
            )
        }).catch((e) => {
            if (e.response.data.error.message === 'could not find accessToken') {
                localStorage.clear();
                navigate('/login');
            }
        });
    }

    // const handleUpdate = ()=>{
    //     if(subcategories.length>0){
    //         let productSubcategoryIds = subcategories.map((data:any)=>data.id);
    //         let editData = {...editShipmentDetails,"productSubcategories":subcategories};
    //         let data = {
    //             "productSubcategoryId": productSubcategoryIds,
    //         };
    //         // Update data
    //         axios.patch(`${URLs.BACKEND_URL}/api/shipments/${editShipmentDetails._id}${GetToken}`,data).then((res) => {
    //             if (res.data.productId) {
    //                 console.log('Shipment updated completely..!!');
    //                 sessionStorage.setItem("editShipmentDetails", JSON.stringify(editData));
    //             }
    //         }).catch((e)=>{
    //             if ( e.response.data.error.message === 'could not find accessToken' ) {
    //               localStorage.clear();
    //               navigate('/login');
    //             }
    //         });
    //         // Add view logs
    //         const view_data = {
    //             "userId": arr[0].userId,
    //             "subject": "Subcategory",
    //             "shipmentId": editShipmentDetails._id
    //         }
    //         axios.post(`${URLs.BACKEND_URL}/api/view_logs/${GetToken}`, view_data).then((res) => {
    //             if (res.data.id) {
    //                 console.log('View logs added completely..!!');
    //                 navigate(`/edit-shipment/${editShipmentDetails._id}`);
    //             }
    //         }).catch((e)=>{
    //             if ( e.response.data.error.message === 'could not find accessToken' ) {
    //               localStorage.clear();
    //               navigate('/login');
    //             }
    //         });
    //     }
    // }

    return (
        <div className="main-content">
            <div className="container">
                <div className="row mt-2">
                    {/* <ActiveSteps /> */}
                    <div className="col-md-12">
                        <div className={"goto-back " + (editShipmentDetails?._id && 'disableDiv')}>
                            <Link to="/add-new-product">
                                {!editShipmentDetails && <img className="" src={backIcon} alt="" />}
                                <span> Product Subcategory </span>
                            </Link>
                        </div>
                    </div>

                    {/* <!--------------------------------- Start Tabs Data ---------------------------------------> */}

                    <div className="mng_shipment_data">

                        {/* <!-- Tabs menu --> */}
                        <ul className="nav nav-tabs" role="tablist">
                            {products.length > 0 && products.map((tab) => {
                                return <li key={tab.id} className="nav-item nav-item-cus" id={tab.id}>
                                    <button className="nav-link nav-link-cus active " onClick={() => handleTabSwitch(tab)} >
                                        {tab.name}
                                    </button>
                                </li>
                            })}
                        </ul>
                        {/* <!-- End Tabs menu --> */}

                        <div className="tab-content tab-content-cus">
                            <div className="tab-pane active" id="tabs-1" role="tabpanel">
                                <div className="row mt-5">
                                    {subcategory.length > 0 && subcategory.map((productSubcategory) => {
                                        return <SubcategoryData checkChemicals={isChemicals} subcategory={productSubcategory} key={productSubcategory?.id} />
                                    })
                                    }
                                </div>
                                {showInputDiv && <div className="col-lg-8">
                                    <form >
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group custom-input">
                                                    <label >Name <span className="text-danger">*</span></label>
                                                    <input type="string" className="form-control" name="name" id="firstName"
                                                        value={input.name}
                                                        onChange={addData}
                                                        placeholder="Enter Product Subcategory Name " pattern="[a-zA-Z]|[ ][a-zA-Z]+" />
                                                    <div className="invalid-feedback">
                                                        <div>Product Subcategory Name is required</div>
                                                        <div >Blank space is not allowed</div>
                                                        <div >Please enter no more than 25 characters.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group custom-input">
                                                    <label >Specifications <span className="text-danger">*</span></label>
                                                    <input type="string" className="form-control" name="productSpecifications" id="firstName"
                                                        value={input.productSpecifications}
                                                        onChange={addData}
                                                        placeholder="Enter Subactegory Specifications " pattern="[a-zA-Z]|[ ][a-zA-Z]+" />
                                                    <div className="invalid-feedback">
                                                        <div>Product Specification is required</div>
                                                        <div >Blank space is not allowed</div>
                                                        <div >Please enter no more than 25 characters.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>}
                            </div>
                        </div>
                    </div>

                    {/* <!---------------------------------------------  End Tab Data  ----------------------------------------------->  */}

                </div>

                <div className="mt-2">
                    <div className="col-md-12">
                        <div className="form-group custom-input mt-4 text-center">
                            <button className="btn-style-one text-center" onClick={handleCheck} >
                                {!editShipmentDetails ? `NEXT` : `BACK`}
                            </button>
                        </div>
                    </div>
                </div>
                {/* { editShipmentDetails?.productSubcategories?.length >0 && <div className="mt-2">
                <div className="col-md-12">
                    <div className="form-group custom-input mt-4 text-center">
                            <button className="btn-style-one text-center" onClick={handleUpdate} >
                                UPDATE
                            </button>
                    </div>
                </div>
            </div>} */}
            </div>
        </div>
    )
}

export default SubcategoryOfProduct