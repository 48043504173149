import { useEffect, useState } from 'react';
import ActiveSteps from '../ActiveSteps/ActiveSteps';
import '../../../Assets/css/style.css'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { addOtherSpecifications, emptyShipmentReducer } from '../../../Store/shipmentReducer';
import backIcon from '../../../Assets/images/back-icon.svg'
import { useSnackbar } from 'notistack';
import PackagingDeatilData from './PackagingDetailsData';
import URLs from '../../../Helpers/Constants';
import { emptyDunnageDimensionsReducer } from '../../../Store/dunnageDimensionReducer';
// import GetToken from '../../../Helpers/StorageFunction'

const PackagingDeatails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [packagingDetail, setPackagingDetail] = useState([]);
    const [buttonClicked, setButtonClicked] = useState("");
    const [showInputDiv, setShowInputDiv] = useState(false);
    let editShipmentDetails = JSON.parse(sessionStorage.getItem("editShipmentDetails"));
    const [input, setInput] = useState({
        name: "",
        productSpecifications: "",
        imageName: ""
    });
    let arr = JSON.parse(localStorage.getItem("storageData"));
    let { packagingDetails, otherSpecifications, products } = useSelector((state: any) => state.shipment.data.shipmentData);
    let GetToken = ""
    if (arr) GetToken = `?access_token=${arr[0].id}`;

    window.onload = (event: any) => {
        if (editShipmentDetails) {
            navigate(`/edit-shipment/${editShipmentDetails._id}`);
        }
        else {
            if (arr[0]?.user?.roleName === "shipping_manager") navigate('/shipment-mode')
            else navigate('/dashboard');
        }
        // if (event) {
        //     navigate('/shipment-mode')
        // }
    };

    useEffect(() => {
        axios.post(`${URLs.BACKEND_URL}/api/products/get-products-icon${GetToken}`, { modelName: "packaging-details", productId: products[0]?.id }).then((res) => {
            let list = res.data.message;
            let data = [];
            let newProducts = list.filter((product: any) => {
                if (product.name !== 'Other') {
                    return true;
                }
            });
            data = newProducts.sort((a: any, b: any) => a.name.localeCompare(b.name));
            let otherData = list.filter((product: any) => {
                if (product.name === 'Other') {
                    return true;
                }
            });
            data.push(otherData[0]);
            setPackagingDetail(data);
            document.getElementById(products[0].id).classList.add("active-button");
            setButtonClicked(products[0].id);
            // logic for the clearing further data from the reducer
            if (!editShipmentDetails) {
                dispatch(emptyShipmentReducer(
                    {
                        packageDimensions: [],
                        palletDetail: [],
                        palletDimensions: {},
                        palletWrapDetails: [],
                        dunnage: [],
                        dunnageSubcategory: [],
                        palletWrapSpecifications: {},
                        otherType: "packagingDetails"
                    }
                ));
                dispatch(emptyDunnageDimensionsReducer(true));
            }
        }).catch((e) => {
            if (e.response.data.error.message === 'could not find accessToken') {
                localStorage.clear();
                navigate('/login');
            }
        });
    }, []);

    let handleCheck = () => {
        if (editShipmentDetails) {
            navigate(`/edit-shipment/${editShipmentDetails._id}`);
        }
        else {
            if (Object.keys(packagingDetails).length === 0) {
                enqueueSnackbar("Please select a packaging detail", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            } else {
                if (Object.keys(packagingDetails).length === products.length) {
                    let newOtherData = packagingDetail.filter((data) => {
                        if (data.name === "Other") {
                            return true;
                        }
                    });
                    let newSelectedData = Object.keys(packagingDetails).filter((data) => {
                        if (packagingDetails[data].name === "Other" && packagingDetails[data].productId === newOtherData[0].productId) {
                            return true;
                        }
                    });
                    if (newSelectedData.length > 0) {
                        if (input.name && input.productSpecifications) {
                            navigate('/packaging-dimension-details')
                        }
                        else {
                            enqueueSnackbar("Please fill the all other fields.", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        }
                    }
                    else {
                        navigate('/packaging-dimension-details')
                    }
                }
                else {
                    enqueueSnackbar("Please select a packaging detail from each selected product.", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
            }
        }
    }

    function addData(e: any) {
        setInput({ ...input, [e.target.name]: e.target.value });
    }

    // ------------------ useeffect for the showing input div for the other ------------------------------------
    useEffect(() => {
        if (packagingDetail.length > 0) {
            let newData = packagingDetail.filter((data) => {
                if (data.name === "Other") {
                    return true;
                }
            });

            let newSelectedData = Object.keys(packagingDetails).filter((data) => {
                if (packagingDetails[data].name === "Other" && packagingDetails[data].productId === newData[0].productId) {
                    return true;
                }
            });
            if (newSelectedData.length > 0) {
                setShowInputDiv(true);
            } else {
                setShowInputDiv(false);
            }
        }
    }, [packagingDetails]);

    // ------------------------- UseEffect for setting the other values ----------------------------------------
    useEffect(() => {
        if (otherSpecifications && packagingDetail.length > 0) {
            let newData = otherSpecifications.filter((data) => {
                if (data.type === "packagingDetails" && data.specification.productId === packagingDetail[0].productId) {
                    return true;
                }
            });
            if (newData.length > 0) {
                setInput({
                    name: newData[0].specification.name,
                    productSpecifications: newData[0].specification.data,
                    imageName: ""
                })
            }
            else {
                setInput({
                    name: "",
                    productSpecifications: "",
                    imageName: ""
                })
            }
        }
    }, [packagingDetail]);

    // -------------------- useEffect for setting the other values to the reducer --------------------------------
    useEffect(() => {
        if (input.name && input.productSpecifications) {
            let productData = products.filter((data: any) => {
                if (data.id === packagingDetail[0].productId) {
                    return true;
                }
            });
            let data = {
                "type": "packagingDetails",
                "specification": {
                    "name": input.name,
                    "data": input.productSpecifications,
                    "productId": productData[0].id,
                    "productName": productData[0].name
                }
            };
            dispatch(addOtherSpecifications(data));
        }
    }, [input]);

    const handleTabSwitch = (tab: any) => {
        axios.post(`${URLs.BACKEND_URL}/api/products/get-products-icon${GetToken}`, { modelName: "packaging-details", productId: tab.id }).then((res) => {
            let list = res.data.message;
            let data = [];
            let newProducts = list.filter((product: any) => {
                if (product.name !== 'Other') {
                    return true;
                }
            });
            data = newProducts.sort((a: any, b: any) => a.name.localeCompare(b.name));
            let otherData = list.filter((product: any) => {
                if (product.name === 'Other') {
                    return true;
                }
            });
            data.push(otherData[0]);
            setPackagingDetail(data);
            document.getElementById(buttonClicked).classList.remove("active-button");
            setButtonClicked(tab.id);
            document.getElementById(tab.id).classList.add("active-button");
        }).catch((e) => {
            if (e.response.data.error.message === 'could not find accessToken') {
                localStorage.clear();
                navigate('/login');
            }
        });
    }

    // const handleUpdate = ()=>{
    //     if(Object.keys(packagingDetails).length>0){
    //         let packageDetailData= Object.keys(packagingDetails).map((data)=>packagingDetails[data])
    //         let editData = {...editShipmentDetails,"packagingDetails":packageDetailData};
    //         let data = {
    //             "packageDetailId": packageDetailData.map((data)=>data.id),
    //         };
    //         // Update data
    //         axios.patch(`${URLs.BACKEND_URL}/api/shipments/${editShipmentDetails._id}${GetToken}`,data).then((res) => {
    //             if (res.data.productId) {
    //                 console.log('Shipment updated completely..!!');
    //                 sessionStorage.setItem("editShipmentDetails",JSON.stringify(editData));
    //             }
    //         }).catch((e)=>{
    //             if ( e.response.data.error.message === 'could not find accessToken' ) {
    //               localStorage.clear();
    //               navigate('/login');
    //             }
    //         });
    //         // Add view logs
    //         const view_data = {
    //             "userId": arr[0].userId,
    //             "subject": "Packaging Details",
    //             "shipmentId": editShipmentDetails._id
    //         }
    //         axios.post(`${URLs.BACKEND_URL}/api/view_logs/${GetToken}`, view_data).then((res) => {
    //             if (res.data.id) {
    //                 console.log('View logs added completely..!!');
    //                 navigate(`/edit-shipment/${editShipmentDetails._id}`);
    //             }
    //         }).catch((e)=>{
    //             if ( e.response.data.error.message === 'could not find accessToken' ) {
    //               localStorage.clear();
    //               navigate('/login');
    //             }
    //         });
    //     }
    // }

    return (
        <div className="main-content">
            <div className="container">
                <div className="row mt-2">
                    <ActiveSteps />
                    <div className="col-md-12">
                        <div className={"goto-back " + (editShipmentDetails?._id && 'disableDiv')}>
                            <Link to="/subcategories-of-product">
                                {!editShipmentDetails && <img className="" src={backIcon} alt="" />}
                                <span> Packaging Details </span>
                            </Link>
                        </div>
                    </div>

                    {/* <!--------------------------------- Start Tabs Data ---------------------------------------> */}

                    <div className="mng_shipment_data">
                        {/* <!-- Tabs menu --> */}
                        <ul className="nav nav-tabs" role="tablist">
                            {products.length > 0 && products.map((tab) => {
                                return <li key={tab.id} className="nav-item nav-item-cus" id={tab.id}>
                                    <button className="nav-link nav-link-cus active" onClick={() => handleTabSwitch(tab)} >
                                        {tab.name}
                                    </button>
                                </li>
                            })}
                        </ul>
                        {/* <!-- End Tabs menu --> */}

                        <div className="tab-content tab-content-cus">
                            <div className="tab-pane active" id="tabs-1" role="tabpanel">
                                <div className="row mt-5">
                                    {packagingDetail.length > 0 && packagingDetail.map((packagingDetails) => {
                                        return <PackagingDeatilData packagingDetail={packagingDetails} key={packagingDetails?.id} />
                                    })
                                    }
                                </div>
                                {showInputDiv && <div className="col-lg-8">
                                    <form >
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group custom-input">
                                                    <label >Name <span className="text-danger">*</span></label>
                                                    <input type="string" className="form-control" name="name" id="firstName"
                                                        value={input.name}
                                                        onChange={addData}
                                                        placeholder="Enter Packaging Detail Name " pattern="[a-zA-Z]|[ ][a-zA-Z]+" />
                                                    <div className="invalid-feedback">
                                                        <div>Packaging Detail Name is required</div>
                                                        <div >Blank space is not allowed</div>
                                                        <div >Please enter no more than 25 characters.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group custom-input">
                                                    <label >Specifications <span className="text-danger">*</span></label>
                                                    <input type="string" className="form-control" name="productSpecifications" id="firstName"
                                                        value={input.productSpecifications}
                                                        onChange={addData}
                                                        placeholder="Enter Subactegory Specifications " pattern="[a-zA-Z]|[ ][a-zA-Z]+" />
                                                    <div className="invalid-feedback">
                                                        <div>Packaging Detail Specification is required</div>
                                                        <div >Blank space is not allowed</div>
                                                        <div >Please enter no more than 25 characters.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>}
                            </div>
                        </div>
                    </div>

                    {/* <!---------------------------------------------  End Tab Data  ----------------------------------------------->  */}

                </div>
                <div className="mt-2">
                    <div className="col-md-12">
                        <div className="form-group custom-input mt-4 text-center">
                            <button className="btn-style-one text-center" onClick={handleCheck} >
                                {!editShipmentDetails ? `NEXT` : `BACK`}
                            </button>
                        </div>
                    </div>
                </div>
                {/* { editShipmentDetails?.packagingDetails?.length >0 && <div className="mt-2">
                <div className="col-md-12">
                    <div className="form-group custom-input mt-4 text-center">
                            <button className="btn-style-one text-center" onClick={handleUpdate} >
                                UPDATE
                            </button>
                    </div>
                </div>
            </div>} */}
            </div>
        </div>
    )
}

export default PackagingDeatails