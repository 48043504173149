import { useEffect, useState } from 'react'
import checkMarkIcon from '../../../Assets/images/checkmark.svg'
import { useDispatch, useSelector } from 'react-redux';
import { addSelectedProducts, removeProducts} from '../../../Store/shipmentReducer';

function ProductData({ product}) {
    const [clicked, setClicked] = useState(false);
    let { products } = useSelector((state: any) => state.shipment.data.shipmentData);
    let editShipmentDetails = JSON.parse(sessionStorage.getItem("editShipmentDetails"));
    const dispatch = useDispatch();
    let handleClick = () => {
        if (!editShipmentDetails?._id) {
            setClicked(!clicked);
        }
    };

    useEffect(() => {
        if (clicked) {
            dispatch(addSelectedProducts(product));
        }
        else {
            dispatch(removeProducts(product));
        }
    }, [clicked]);

    useEffect(() => {
        if (products.length > 0) {
            products.forEach((prod: any) => {
                if (prod.id === product.id || prod._id===product.id) {
                    setClicked(true);
                }
            })
        }
        // code for edit shipment content
        if (editShipmentDetails?.products?.length > 0 && products.length === 0) {
            editShipmentDetails.products.forEach((prod: any) => {
                if (prod._id === product.id || prod.id === product.id) {
                    setClicked(true);
                }
            })
        }
    }, []);

    return (
        <>
            <div className="col-md-2 col-sm-6 col-6 d-flex justify-content-center"   >
                <div className="item d-flex flex-column justify-content-center align-items-center" >
                    <div className="item-details d-flex justify-content-center align-items-center" onClick={handleClick} >
                        {clicked && <div style={{ position: "absolute", top: 0, right: 2 }} ><img src={checkMarkIcon} alt='' /></div>}
                        <img src={product.icon.iconUrl} alt="" id={product.id} />
                    </div>
                    <div className="text-center mt-2 " style={{ width: "80%", justifyContent: "center" }}> {product.name}</div>
                </div>
            </div>
        </>
    )
}

export default ProductData