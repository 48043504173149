import { configureStore } from '@reduxjs/toolkit'
import userSlice from './loginReducer';
import shipmentSlice from './shipmentReducer';
import dunnageDimensionSlice from './dunnageDimensionReducer';
export default configureStore({
  reducer: {
    user: userSlice.reducer,
    shipment:shipmentSlice.reducer,
    dunnageDimensions:dunnageDimensionSlice.reducer
  },
})