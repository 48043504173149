import { useState, useEffect } from 'react'
import ActiveSteps from '../ActiveSteps/ActiveSteps'
import { Link, useNavigate } from 'react-router-dom'
import backIcon from '../../../Assets/images/back-icon.svg'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import DunnageData from './DunnageData'
import { useDispatch, useSelector } from 'react-redux'
import URLs from '../../../Helpers/Constants';
import { addOtherSpecifications, emptyShipmentReducer } from '../../../Store/shipmentReducer';
import { addEditShipmentDetails, emptyDunnageDimensionsReducer } from '../../../Store/dunnageDimensionReducer';
// import GetToken from '../../../Helpers/StorageFunction'
function Dunnage() {
    const navigate = useNavigate();
    //  OnLoad
    let arr = JSON.parse(localStorage.getItem("storageData"));
    let shipmentId = localStorage.getItem("shipmentId")
    let GetToken = ""
    if (arr) {
        GetToken = `?access_token=${arr[0].id}`
    }
    const dispatch = useDispatch();
    let { dunnage, products, otherSpecifications } = useSelector((state: any) => state.shipment.data.shipmentData);
    const [dunnageData, setDunnageData] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [showInputDiv, setShowInputDiv] = useState(false);
    let editShipmentDetails = JSON.parse(sessionStorage.getItem("editShipmentDetails"));
    window.onload = (event: any) => {
        if (editShipmentDetails) {
            navigate(`/edit-shipment/${editShipmentDetails._id}`);
        }
        else {
            if (arr[0]?.user?.roleName === "shipping_manager") navigate('/shipment-mode')
            else navigate('/dashboard');
        }
        // if (event) {
        //     navigate('/shipment-mode')
        // }
    };

    const [input, setInput] = useState({
        name: "",
        productSpecifications: "",
        imageName: ""
    });
    function addData(e: any) {
        setInput({ ...input, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        axios.post(`${URLs.BACKEND_URL}/api/products/get-products-icon${GetToken}`, { modelName: "dunnage", productId: dunnage[0]?.id ? dunnage[0].productId : products[0]?.id })
            .then((res) => {
                let productList = res.data.message;
                let data = [];
                let newProducts = productList.filter((product: any) => {
                    if (product.name !== 'Other') {
                        return true;
                    }
                });
                data = newProducts.sort((a: any, b: any) => a.name.localeCompare(b.name));
                let otherData = productList.filter((product: any) => {
                    if (product.name === 'Other') {
                        return true;
                    }
                });
                data.push(otherData[0])
                setDunnageData(data);
                // logic for the clearing further data from the reducer
                if (!editShipmentDetails) {
                    dispatch(emptyShipmentReducer(
                        {
                            dunnageSubcategory: [],
                            otherType: "dunnage"
                        }
                    ));
                    dispatch(emptyDunnageDimensionsReducer(true));
                }
            })
            .catch((e) => {
                if (e.response.data.error.message === 'could not find accessToken') {
                    localStorage.clear();
                    navigate('/login');
                }
            });
    }, []);

    useEffect(() => {
        if (dunnage.length > 0) {
            let newData = dunnage.filter((data) => {
                if (data.name === "Other") {
                    return true;
                }
            });
            if (newData.length > 0) {
                setShowInputDiv(true);
            } else {
                setShowInputDiv(false);
            }
        }
        else {
            setShowInputDiv(false);
        }
    }, [dunnage]);

    let handleCheck = () => {
        if (editShipmentDetails) {
            navigate(`/edit-shipment/${editShipmentDetails._id}`);
        }
        else {
            if (dunnage.length === 0) {
                enqueueSnackbar("Please select a dunnage",
                    { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            }
            else {
                let newSelectedData = dunnage.filter((data) => {
                    if (data.name === "Other") {
                        return true;
                    }
                });
                if (newSelectedData.length > 0) {
                    if (input.name && input.productSpecifications) {
                        navigate('/dunnage-subcategories')
                    }
                    else {
                        enqueueSnackbar("Please fill the all other fields.",
                            { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    }
                }
                else {
                    navigate('/dunnage-subcategories')
                }
            }
        }
    }

    // const handleUpdate = ()=>{
    //     if(dunnage.length>0){
    //         let dunnageIds = dunnage.map((data:any)=>data.id);
    //         let editData = {...editShipmentDetails,"dunnage":dunnage};
    //         let data = {
    //             "dunnageId": dunnageIds,
    //         };
    //         // Update data
    //         axios.patch(`${URLs.BACKEND_URL}/api/shipments/${editShipmentDetails._id}${GetToken}`,data).then((res) => {
    //             if (res.data.productId) {
    //                 console.log('Shipment updated completely..!!');
    //                 sessionStorage.setItem("editShipmentDetails", JSON.stringify(editData));
    //             }
    //         }).catch((e)=>{
    //             if ( e.response.data.error.message === 'could not find accessToken' ) {
    //               localStorage.clear();
    //               navigate('/login');
    //             }
    //         });
    //         // Add view logs
    //         const view_data = {
    //             "userId": arr[0].userId,
    //             "subject": "Dunnage",
    //             "shipmentId": editShipmentDetails._id
    //         }
    //         axios.post(`${URLs.BACKEND_URL}/api/view_logs/${GetToken}`, view_data).then((res) => {
    //             if (res.data.id) {
    //                 console.log('View logs added completely..!!');
    //                 navigate(`/edit-shipment/${editShipmentDetails._id}`);
    //             }
    //         }).catch((e)=>{
    //             if ( e.response.data.error.message === 'could not find accessToken' ) {
    //               localStorage.clear();
    //               navigate('/login');
    //             }
    //         });
    //     }
    // }

    useEffect(() => {
        if (otherSpecifications) {
            let newData = otherSpecifications.filter((data) => {
                if (data.type === "dunnage") {
                    return true;
                }
            });
            if (newData.length > 0) {
                setInput({
                    name: newData[0].specification.name,
                    productSpecifications: newData[0].specification.data,
                    imageName: ""
                })
            }
            else {
                setInput(
                    {
                        name: "",
                        productSpecifications: "",
                        imageName: ""
                    }
                )
            }
        }
    }, [dunnage]);

    useEffect(() => {
        if (input.name && input.productSpecifications) {
            let data = {
                "type": "dunnage",
                "specification": {
                    "name": input.name,
                    "data": input.productSpecifications
                }
            }
            dispatch(addOtherSpecifications(data));
        }
    }, [input]);

    // Logic for go back button 
    const handleGoBack = () => {
        dispatch(emptyShipmentReducer(
            {
                dunnage: [],
                otherType: "palletWrap"
            }
        ));
        navigate(`/visualization-of-loaded-truck/${shipmentId}/${arr[0].userId}`)
    }
    return (
        <>
            <div className="main-content">
                <div className="container">
                    <div className="row mt-2">
                        <ActiveSteps />
                        <div className="col-md-12">
                            <div className={"goto-back " + (editShipmentDetails?._id && 'disableDiv')} onClick={handleGoBack}>
                                <Link to={`/visualization-of-loaded-truck/${shipmentId}/${arr[0].userId}`}>
                                    {!editShipmentDetails && <img className="" src={backIcon} alt="" />}
                                    <span>Select Dunnage</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        {dunnageData.length > 0 && dunnageData.map((dunnageData) => {
                            return <DunnageData dunnageData={dunnageData} key={dunnageData.id} />
                        })
                        }
                    </div>
                    {showInputDiv && <div className="col-lg-8">
                        <form >
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group custom-input">
                                        <label >Name <span className="text-danger">*</span></label>
                                        <input type="string" className="form-control" name="name" id="firstName"
                                            value={input.name}
                                            onChange={addData}
                                            placeholder="Enter Dunnage Name " pattern="[a-zA-Z]|[ ][a-zA-Z]+" />
                                        <div className="invalid-feedback">
                                            <div>Dunnage Name is required</div>
                                            <div >Blank space is not allowed</div>
                                            <div >Please enter no more than 25 characters.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group custom-input">
                                        <label >Specifications <span className="text-danger">*</span></label>
                                        <input type="string" className="form-control" name="productSpecifications" id="firstName"
                                            value={input.productSpecifications}
                                            onChange={addData}
                                            placeholder="Enter Dunnage Specifications " pattern="[a-zA-Z]|[ ][a-zA-Z]+" />
                                        <div className="invalid-feedback">
                                            <div>Dunnage Specification is required</div>
                                            <div >Blank space is not allowed</div>
                                            <div >Please enter no more than 25 characters.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>}
                    <div className="row mt-2">
                        <div className="mt-2">
                            <div className="col-md-12">
                                <div className="form-group custom-input mt-4 text-center">
                                    <button className="btn-style-one text-center" onClick={handleCheck} >
                                        {!editShipmentDetails ? `NEXT` : `BACK`}
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* { editShipmentDetails?.products?.length >0 && <div className="mt-2">
                            <div className="col-md-12">
                                <div className="form-group custom-input mt-4 text-center">
                                        <button className="btn-style-one text-center" onClick={handleUpdate} >
                                            UPDATE
                                        </button>
                                </div>
                            </div>
                        </div>} */}

                    </div>

                </div>
            </div >
        </>
    )
}

export default Dunnage