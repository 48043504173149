import { useEffect, useState } from 'react'
import ActiveSteps from '../ActiveSteps/ActiveSteps'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import backIcon from '../../../Assets/images/back-icon.svg'
import axios from 'axios';
import { addPackageDimensions, addShipmentId, emptyShipmentReducer } from '../../../Store/shipmentReducer';
import { useSnackbar } from 'notistack';
import URLS from '../../../Helpers/Constants';
import { emptyDunnageDimensionsReducer } from '../../../Store/dunnageDimensionReducer';
import URLs from '../../../Helpers/Constants';

function PackageDimensionDetails() {
  const navigate = useNavigate();
  let arr = JSON.parse(localStorage.getItem("storageData"));
  let GetToken = ""
  if (arr) GetToken = `?access_token=${arr[0].id}`

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [packageDimensionDetails, setPackageDimensionDetails] = useState([]);
  const [buttonClicked, setButtonClicked] = useState("");
  const [dimensions, setDimensions] = useState({ productId: "", weight: "", shape: "cylinder", numberOfUnits: "", unitsInPackage: "", height: "", width: "", length: "", diameter: "" })
  const [selectedProduct, setSelectedProduct] = useState({ id: "" });
  let { products, packagingDetails, packageDimensions } = useSelector((state: any) => state.shipment.data.shipmentData);
  let editShipmentDetails = JSON.parse(sessionStorage.getItem("editShipmentDetails"));
  window.onload = (event: any) => {
    if (editShipmentDetails) {
      navigate(`/edit-shipment/${editShipmentDetails._id}`);
    }
    else {
      if (arr[0]?.user?.roleName === "shipping_manager") navigate('/shipment-mode')
      else navigate('/dashboard');
    }
    // if (event) {
    //     navigate('/shipment-mode')
    // }
  };


  useEffect(() => {
    if (products[0]) {
      setSelectedProduct(products[0]);
      document.getElementById(products[0]?.id).classList.add("active-button");
      setButtonClicked(products[0]?.id);
      // logic for the clearing further data from the reducer
      if (!editShipmentDetails) {
        dispatch(emptyShipmentReducer(
          {
            palletDetail: [],
            palletDimensions: {},
            palletWrapDetails: [],
            dunnage: [],
            dunnageSubcategory: [],
            palletWrapSpecifications: {},
            otherType: "packageDimensions"
          }
        ));
        dispatch(emptyDunnageDimensionsReducer(true));
      }
    }
  }, []);
  function addData(e: any) {
    if (!isNaN(e.target.value)) {
      if (Number(e.target.value) <= 0) {
        enqueueSnackbar(`Please input value greater than 0.`,
          { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
      }
      else if (Number(e.target.value) > 99999) {
        enqueueSnackbar(`Please input value greater than 99999.`,
          { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
      }
      setDimensions({ ...dimensions, [e.target.name]: e.target.value });
    }

  }
  let handleSubmit = () => {
    if (packageDimensions.length > 0) {
      if (packageDimensions.length === products.length) {
        let filteredData = packageDimensions.filter((data) => {
          if (data.packageShape === 'cylinder') {
            if (
              !data.packageShape || Number(data.dimensions.height) <= 0 || Number(data.dimensions.height) > 99999 || Number(data.unitWeight) <= 0 || Number(data.unitWeight) > 99999
              || Number(data.totalPackages) <= 0 || Number(data.totalPackages) > 99999 || Number(data.dimensions.diameter) <= 0 || Number(data.dimensions.diameter) > 99999) {
              return true;
            }
          }
          else {
            if (
              !data.packageShape || Number(data.dimensions.height) <= 0 || Number(data.dimensions.height) > 99999 || Number(data.unitWeight) <= 0 || Number(data.unitWeight) > 99999 || Number(data.totalPackages) <= 0
              || Number(data.totalPackages) > 99999 || Number(data.dimensions.length) <= 0 || Number(data.dimensions.length) > 99999 || Number(data.dimensions.width) <= 0 || Number(data.dimensions.width) > 99999) {
              return true;
            }
          }
        });

        if (filteredData.length === 0) {
          navigate('/pallet-details');
        }
        else {
          enqueueSnackbar("Please fill all the fields with the correct input.",
            { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
        }
      }
      else {
        enqueueSnackbar("Please enter dimensions for each selected type of product.",
          { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
      }
    }
    else {
      enqueueSnackbar("Please enter dimensions for each selected type of product.",
        { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
    }
  }

  useEffect(() => {
    if (selectedProduct.id) {
      let data = {
        "packageId": packagingDetails[selectedProduct.id]?.id,
        "productId": selectedProduct.id,
        "packageShape": dimensions.shape,
        "dimensions": {
          "length": dimensions.length,
          "width": dimensions.width,
          "height": dimensions.height,
          "diameter": dimensions.diameter
        },
        "unitWeight": dimensions.weight,
        "totalPackages": dimensions.numberOfUnits,
        "unitsInPackage": dimensions.unitsInPackage,
      }
      setPackageDimensionDetails([data])
      dispatch(addPackageDimensions(data));
    }
  }, [dimensions]);

  useEffect(() => {
    if (packageDimensions.length > 0 || editShipmentDetails?.packageDimensions?.length > 0) {
      let newData = packageDimensions.filter((data) => {
        if (data.productId === selectedProduct.id) {
          return true;
        }
      });
      // edit code 
      let newData1 = editShipmentDetails?.packageDimensions?.filter((data: any) => {
        if (data.productId === selectedProduct.id) {
          return true;
        }
      });
      if (newData1?.length > 0) {
        setDimensions(
          {
            productId: newData1[0].productId,
            weight: newData1[0].unitWeight,
            shape: newData1[0].packageShape,
            numberOfUnits: newData1[0].totalPackages,
            unitsInPackage: newData1[0].unitsInPackage,
            height: newData1[0].dimensions.height,
            width: newData1[0].dimensions.width,
            length: newData1[0].dimensions.length,
            diameter: newData1[0].dimensions.diameter
          }
        );
        setPackageDimensionDetails(newData1[0]);
      }
      else if (newData.length > 0) {
        setDimensions(
          {
            productId: newData[0].productId,
            weight: newData[0].unitWeight,
            shape: newData[0].packageShape,
            numberOfUnits: newData[0].totalPackages,
            unitsInPackage: newData[0].unitsInPackage,
            height: newData[0].dimensions.height,
            width: newData[0].dimensions.width,
            length: newData[0].dimensions.length,
            diameter: newData[0].dimensions.diameter
          }
        );
        setPackageDimensionDetails(newData[0]);
      }
      else {
        setDimensions(
          { productId: "", weight: "", shape: "cylinder", numberOfUnits: "", unitsInPackage: "", height: "", width: "", length: "", diameter: "" }
        )
      }
    }
  }, [selectedProduct]);

  const handleUpdate = () => {
    // Find the updated data 
    // Replace the existing data with new one 
    if (packageDimensions.length > 0) {
      let temp = [...editShipmentDetails.packageDimensions];
      editShipmentDetails.packageDimensions.map((data: any, index: any) => {
        packageDimensions.map((item: any) => {
          if (data.productId === item.productId) {
            item = { ...item, "packageId": data.packageId }
            // Code for the dimensions replacement
            axios.post(`${URLS.BACKEND_URL}/api/package-dimensions/${data._id ? data._id : data.id}/replace${GetToken}`, item).then((res) => {
              temp[index] = res.data
              // Update session storage data
              let sessionData = { ...editShipmentDetails, packageDimensions: temp }
              window.sessionStorage.setItem("editShipmentDetails", JSON.stringify(sessionData));
              if (res.data.id) {
                axios.post(`${URLS.BACKEND_URL}/api/unity_shipments/delete-unity-data`, { shipmentId: editShipmentDetails._id }).then((res) => {
                  console.log('Shipment replaced completely..!!');
                  handleViewShipment(editShipmentDetails._id);
                  enqueueSnackbar("Package Dimensions updated successfully.",
                    { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }).catch(err => {
                  console.log(err);
                })
              }
            }).catch(err => {
              console.log(err);
            });
          }
        });
      });

      // Add view logs
      const view_data = {
        "userId": arr[0].userId,
        "subject": "Packaging Dimension",
        "shipmentId": editShipmentDetails._id
      }
      axios.post(`${URLS.BACKEND_URL}/api/view_logs/${GetToken}`, view_data).then((res) => {
        if (res.data.id) {
          console.log('View logs added completely..!!');
          navigate(`/edit-shipment/${editShipmentDetails._id}`);
        }
      }).catch((e) => {
        if (e.response.data.error.message === 'could not find accessToken') {
          localStorage.clear();
          navigate('/login');
        }
      });
      navigate(`/edit-shipment/${editShipmentDetails._id}`)
    }
    else {
      enqueueSnackbar("Please enter dimensions for each products.",
        { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
    }
  }

  const checkUserAccess = (shipment: any) => {
    if (shipment) {
      if (shipment?.status[0] == true) {
        if (arr[0].user.roleName === 'shipping_manager') {
          return 'edit'
        }
        else if (arr[0].user.roleName === 'origin_loading_manager' || arr[0].user.roleName === 'destination_loading_manager') {
          return arr[0].user.accessType[shipment._id]
        }
      } else {
        return "view";
      }
    } else {
      if (arr[0].user.roleName === 'shipping_manager') {
        return 'edit'
      }
      else if (arr[0].user.roleName === 'origin_loading_manager' || arr[0].user.roleName === 'destination_loading_manager') {
        return arr[0].user.accessType[shipment._id]
      }
    }
  }

  const handleViewShipment = (shipmentId: any) => {
    axios.post(`${URLS.BACKEND_URL}/api/shipments/load-calculator/`, { shipmentId, userId: arr[0]?.userId }).then((data) => {
      if (data) {
        dispatch(addShipmentId(shipmentId));
        handleDunnage();
      }
    }).catch(err => {
      enqueueSnackbar(err.response.data.error.message ? err.response.data.error.message : "Please check your shipment details..!!", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
    });
  }

  let handleDunnage = () => {
    let newData = editShipmentDetails.dunnageDimensions[0].map((data: any) => {
      return data.dunnages[0];
    });
    let data = {
      "shipmentId": editShipmentDetails._id,
      "dunnages": newData
    }
    axios.post(`${URLs.BACKEND_URL}/api/unity_shipments/update-unity-data${GetToken}`, data).then((res) => console.log(res)
    ).catch((e) => console.log(e));
  }

  return (
    <>
      <div className="main-content">
        <div className="container">
          <div className="row mt-2">
            <ActiveSteps />
            <div className="col-md-12">
              <div className={"goto-back "}>
                <Link to={editShipmentDetails ? `/edit-shipment/${editShipmentDetails._id}` : "/packaging-details"}><img className="" src={backIcon} alt="" />
                  <span> Package Dimensions </span>
                </Link>
              </div>
            </div>

            {/* <!--------------------------------- Start Tabs Data ---------------------------------------> */}

            <div className="mng_shipment_data">

              {/* <!-- Tabs menu --> */}
              <ul className="nav nav-tabs" role="tablist">
                {products?.length > 0 && products.map((tab) => {
                  return <li key={tab.id} className="nav-item nav-item-cus" id={tab?.id} >
                    <button className="nav-link nav-link-cus active" onClick={() => {
                      setSelectedProduct(tab)
                      document.getElementById(buttonClicked).classList.remove("active-button");
                      setButtonClicked(tab?.id);
                      document.getElementById(tab?.id).classList.add("active-button");
                    }}>
                      {tab.name}
                    </button>
                  </li>
                })}
              </ul>
              {/* <!-- End Tabs menu --> */}

              <div className="tab-content tab-content-cus">
                <div className="tab-pane active" id="tabs-1" role="tabpanel">
                  <div className="package-dia">
                    <div className="row mt-3">
                      <div className="col-md-3">
                        <div className="form-group custom-input">
                          <label>Package shape </label>
                          <select value={dimensions.shape} className="form-select" disabled={editShipmentDetails && true} aria-label="Default select example" defaultValue='cylinder' onChange={(e) => setDimensions({ ...dimensions, shape: e.target.value })}>
                            <option value="cylinder">Cylinder</option>
                            <option value="cube">Cube</option>
                            <option value="cuboid">Cuboid</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group custom-input">
                          <label> Total no. of packages </label>
                          <input className='form-control' type='text' disabled={checkUserAccess(editShipmentDetails) === 'view' ? true : false} placeholder='Enter Total Number of Units' value={dimensions.numberOfUnits} name='numberOfUnits' style={{ height: 40 }} onChange={addData} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group custom-input">
                          <label> Packages in a unit (pallet)</label>
                          <input className='form-control' type='text' disabled={checkUserAccess(editShipmentDetails) === 'view' ? true : false} placeholder='Enter Number of Units (pallet)' value={dimensions.unitsInPackage} name='unitsInPackage' style={{ height: 40 }} onChange={addData} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group custom-input">
                          <label > Weight of a single package (lbs) </label>
                          <input className='form-control' type='text' disabled={editShipmentDetails && true} placeholder='Enter Weight Of Single Unit' value={dimensions.weight} name='weight' style={{ height: 40 }} onChange={addData} />
                        </div>
                      </div>
                      <div className={dimensions.shape == "cylinder" ? "col-md-4" : "col-md-8"}>
                        <label className='my-2'>Enter Package Dimensions (inches)</label>
                        <div className="row">
                          {
                            dimensions.shape !== "cylinder" && (
                              <>
                                <div className="col-sm-3">
                                  <div className="form-group custom-input">
                                    <label > Length </label>
                                    <input className='form-control' disabled={editShipmentDetails && true} type='text' placeholder='Enter Length' value={dimensions.length} name='length' style={{ height: 40 }} onChange={addData} />
                                  </div>
                                </div>
                                <div className="col-sm-1">
                                  <p className="text-center" style={{ marginTop: 40 }}> X </p>
                                </div>
                              </>
                            )
                          }
                          <div className={dimensions.shape == "cylinder" ? "col-md-5" : "col-md-3"}>
                            <div className="form-group custom-input">
                              <div className="form-group custom-input">
                                {
                                  dimensions.shape == "cylinder" ? (
                                    <label>Diameter</label>
                                  ) : (
                                    <label>Width</label>
                                  )
                                }
                                {dimensions.shape == "cylinder" ?
                                  <input className='form-control' disabled={editShipmentDetails && true} type='text' placeholder='Enter Diameter' value={dimensions.diameter} name='diameter' style={{ height: 40 }} onChange={addData} />
                                  : <input className='form-control' disabled={editShipmentDetails && true} type='text' placeholder='Enter Width' value={dimensions.width} name='width' style={{ height: 40 }} onChange={addData} />
                                }
                              </div>
                            </div>
                          </div>
                          <div className={dimensions.shape == "cylinder" ? "col-md-2" : "col-md-1"}>
                            <p className="text-center" style={{ marginTop: 40 }}> X </p>
                          </div>
                          <div className={dimensions.shape == "cylinder" ? "col-md-5" : "col-md-3"}>
                            <div className="form-group custom-input">
                              <label > Height </label>
                              <input className='form-control' disabled={editShipmentDetails && true} type='text' placeholder='Enter Height' value={dimensions.height} name='height' style={{ height: 40 }} onChange={addData} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!---------------------------------------------  End Tab Data  ----------------------------------------------->  */}

          </div>
          {!editShipmentDetails && <div className="mt-2">
            <div className="col-md-12">
              <div className="form-group custom-input mt-4 text-center">
                <button className="btn-style-one text-center" onClick={handleSubmit} >
                  NEXT
                </button>
              </div>
            </div>
          </div>}
          {editShipmentDetails?.packagingDetails?.length > 0 && <div className="mt-2">
            <div className="col-md-12">
              <div className="form-group custom-input mt-4 text-center">
                {checkUserAccess(editShipmentDetails) === 'view' && <button className="btn-style-one text-center" onClick={() => navigate(`/edit-shipment/${editShipmentDetails._id}`)} >
                  BACK
                </button>}
                {checkUserAccess(editShipmentDetails) === 'edit' && <button className="btn-style-one text-center" onClick={handleUpdate} >
                  UPDATE
                </button>}
              </div>
            </div>
          </div>}
        </div>
      </div>
    </>
  )
}

export default PackageDimensionDetails