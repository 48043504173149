import { Link, useNavigate } from 'react-router-dom';
import './ResetPassword.css';
import Logo from '../../Assets/img/logo.png';
import { FieldGroup, FormBuilder, Validators, AbstractControl, FieldControl } from 'react-reactive-form';
import { resetPassword } from '../../Services/commonService';
import { useSnackbar } from 'notistack'

function ResetPassword() {
    let arr = JSON.parse(localStorage.getItem("resetPasswordTokenData"));
    let jsonData = {
        "verfiedMail": "",
        "data": "",
    };
    if (arr) {
        jsonData = arr[0];
    }
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar()
    const loginForm = FormBuilder.group({
        password1: ["", [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).*$/)]],
        password2: ["", [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).*$/)]]
    });

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        let { password1, password2 } = loginForm.value;
        // ------------- case for alphabet length less than 8 ----------------------------
        if (password1.length < 8 || password2.length < 8) {
            enqueueSnackbar("Password must contain minimum 8 characters.",
                { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
        }
        if (loginForm.valid) {
            if (password1 === password2) {
                let data = await resetPassword({ "newPassword": password1, "email": jsonData.verfiedMail, "accessToken": jsonData.data });
                if (data.message) {
                    enqueueSnackbar(data.message,
                        { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    navigate('/login');
                    localStorage.clear();
                } else {
                    enqueueSnackbar(data.response.data.error.message,
                        { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
            }
            else {
                enqueueSnackbar("Enter password and confirm password must be same.",
                    { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            }

        } else {
            loginForm.markAsTouched();
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 d-flex align-items-center justify-content-center logo-bg">
                        <div className="logo text-center">
                            <Link to="#"><img src={Logo} alt="Logo" /></Link>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="login_right_side">
                            <div className="login_form">
                                <h2 className="text-white text-center pb-2">Set new password</h2>
                                <p className="text-center text-white mb-5">Must be at least 8 characters.</p>
                                <FieldGroup
                                    control={loginForm}
                                    render={({ pristine, invalid, pending, value }: AbstractControl) => (
                                        <form onSubmit={handleSubmit}>
                                            <FieldControl
                                                name='password1'
                                                render={({ handler, touched, hasError }: AbstractControl) => (
                                                    <div className="form-group">
                                                        <label htmlFor="password">Enter new password</label>
                                                        <div className="position-relative d-flex align-items-center">
                                                            <input type="password" className="form-control form-control1 password_input mb-0" {...handler()} id="password" />
                                                        </div>
                                                        <div className="invalid-feedback d-block errorText mb-2">
                                                            <span className='errorText'>
                                                                {(touched &&
                                                                    ((hasError("required") && "Password is required")))}
                                                            </span>
                                                            <span className='errorText'>
                                                                {(touched &&
                                                                    ((hasError("pattern") && "Password should contain atleast 1 number, 1 lowercase, 1 uppercase, 1 special character and must of 8 digits.")))}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                            <FieldControl
                                                name='password2'
                                                render={({ handler, touched, hasError }: AbstractControl) => (
                                                    <div className="form-group">
                                                        <label htmlFor="password">Confirm new password</label>
                                                        <div className="position-relative d-flex align-items-center">
                                                            <input type="password" className="form-control form-control1 password_input mb-0" {...handler()} id="password" />
                                                        </div>
                                                        <div className="invalid-feedback d-block errorText mb-2">
                                                            <span className='errorText'>
                                                                {(touched &&
                                                                    ((hasError("required") && "Password is required")))}
                                                            </span>
                                                            <span className='errorText'>
                                                                {(touched &&
                                                                    ((hasError("pattern") && "Password should contain atleast 1 number, 1 lowercase, 1 uppercase, 1 special character and must of 8 digits.")))}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                            <div className="form-group custom-input">
                                                <button type="submit" className="btn-style-one btn btn_default text-center afterhover">
                                                    <div className=" text-light align-top" role="status" >
                                                        {/* <span>Loading...</span> */}
                                                        <span>RESET PASSWORD</span>
                                                    </div>
                                                    {/* <ng-container *ngIf="!loading">RESET PASSWORD</ng-container> */}
                                                </button>
                                            </div>
                                            <div className="text-center mt-4">
                                                <Link to='/login' className="secondry-color">Login</Link>
                                            </div>
                                        </form>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword;