import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: { userData: [] , loggedIn: false},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginData: (state, action) => {
      if(state.data.userData.length===0){
        state.data.userData.push(action.payload);
        state.data = { userData: state.data.userData, loggedIn: true }
      }
      else{
        if (state.data.userData.length> 0) {
          state.data.userData.pop();
          state.data.userData.push(action.payload);
          state.data = { userData: state.data.userData, loggedIn: true }
        }
      }
  
    },
    logOutData: (state, action) => {
      state.data = { userData:[], loggedIn:action.payload }
    },
  },
}); 

export const { loginData, logOutData } = userSlice.actions;

export default userSlice;
