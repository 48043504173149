import { useState, useEffect } from 'react'
import ActiveSteps from '../ActiveSteps/ActiveSteps'
import { Link, useNavigate } from 'react-router-dom'
import backIcon from '../../../Assets/images/back-icon.svg'
import PalletWrapData from './PalletWrapData'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import URLs from '../../../Helpers/Constants';
// import GetToken from '../../../Helpers/StorageFunction'
import { addOtherSpecifications, addPalletWrapSpecifications, addShipmentId, emptyShipmentReducer } from '../../../Store/shipmentReducer'
import { emptyDunnageDimensionsReducer } from '../../../Store/dunnageDimensionReducer'

const PalletWrapDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [palletWrap, setPalletWrap] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [showInputDiv, setShowInputDiv] = useState(false);
    const [palletWrapSpecifications, setPalletWrapSpecifications] = useState("");
    let editShipmentDetails = JSON.parse(sessionStorage.getItem("editShipmentDetails"));

    const [input, setInput] = useState({
        name: "",
        productSpecifications: ""
    });
    let { palletWrapDetails, palletDetail, otherSpecifications } = useSelector((state: any) => state.shipment.data.shipmentData);
    let { shipmentData } = useSelector((state: any) => state.shipment.data);
    let arr = JSON.parse(localStorage.getItem("storageData"));
    let GetToken = "";
    if (arr) GetToken = `?access_token=${arr[0].id}`;

    window.onload = (event: any) => {
        if (editShipmentDetails) {
            navigate(`/edit-shipment/${editShipmentDetails._id}`);
        }
        else {
            if (arr[0]?.user?.roleName === "shipping_manager") navigate('/shipment-mode')
            else navigate('/dashboard');
        }
        // if (event) {
        //     navigate('/shipment-mode')
        // }
    };

    function addData(e: any) {
        setInput({ ...input, [e.target.name]: e.target.value });
    }


    // Showing Input for other
    useEffect(() => {
        if (palletWrapDetails.length > 0) {
            let newData = palletWrapDetails.filter((data) => {
                if (data.name === "Other") {
                    return true;
                }
            });
            if (newData.length > 0) {
                setShowInputDiv(true);
            } else {
                setShowInputDiv(false);
            }
        }
    }, [palletWrapDetails]);

    // Initial Api call
    useEffect(() => {
        axios.post(`${URLs.BACKEND_URL}/api/products/get-products-icon${GetToken}`, { modelName: "pallet-wrap-details", productId: palletDetail[0]?.productId, palletId: palletDetail[0]?.id }).then((res) => {
            let list = res.data.message;
            let data = [];
            let newProducts = list.filter((product: any) => {
                if (product.name !== 'Other') {
                    return true;
                }
            });
            data = newProducts.sort((a: any, b: any) => a.name.localeCompare(b.name));
            let otherData = list.filter((product: any) => {
                if (product.name === 'Other') {
                    return true;
                }
            });
            data.push(otherData[0]);
            setPalletWrap(data);
            // logic for the clearing further data from the reducer
            if (!editShipmentDetails) {
                dispatch(emptyShipmentReducer(
                    {
                        dunnage: [],
                        dunnageSubcategory: [],
                        otherType: "palletWrap"
                    }
                ));
                dispatch(emptyDunnageDimensionsReducer(true));
            }
        }).catch((e) => {
            if (e.response.data.error.message === 'could not find accessToken') {
                localStorage.clear();
                navigate('/login');
            }
        });
    }, []);

    let handleCheck = () => {
        if (editShipmentDetails) {
            navigate(`/edit-shipment/${editShipmentDetails._id}`);
        }
        else {
            if (palletWrapDetails.length === 0) {
                enqueueSnackbar("Please select a pallet wrap product", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            }
            else {
                let newSelectedData = palletWrapDetails.filter((data: any) => {
                    if (data?.name === "Other") return true;
                });

                if (newSelectedData.length > 0) {
                    if (!input.name || !input.productSpecifications) {
                        enqueueSnackbar("Please fill the all other fields.", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        return;
                    }
                }
                // ------------ Adding initial shipment --------------------------------------------

                let arr = JSON.parse(localStorage.getItem("storageData"));
                if (palletWrapDetails.length > 0) {
                    let productIds = shipmentData.products.map((data: any) => data.id);
                    let productSubcategoryIds = shipmentData.subcategories.map((data: any) => data.id);
                    let packagingDetailIds = Object.keys(shipmentData.packagingDetails).map((data) => shipmentData.packagingDetails[data].id);
                    let shipmentMode = "";
                    if (shipmentData.shipmentMode?.id) {
                        shipmentMode = shipmentData.shipmentMode.id
                    }
                    else {
                        shipmentMode = "645cd0d1148c0a6210ce4ef8"
                    }
                    // shipmentMode = shipmentData.shipmentMode.id;

                    // logic for getting array of ids of pallet Wrap
                    let palletWrapDetailIds = [];
                    if (palletWrapDetails) {
                        palletWrapDetailIds = palletWrapDetails.map((data) => data?.id);
                    }
                    let data = {
                        "shippingManagerId": arr[0]?.userId,
                        "shipmentModeId": shipmentMode,
                        "productId": productIds,
                        "productSubcategoryId": productSubcategoryIds,
                        "packageDetailId": packagingDetailIds,
                        "packageDimension": shipmentData.packageDimensions,
                        "palletDetailId": shipmentData.palletDetail[0].id,
                        "palletDimension": shipmentData.palletDimension,
                        "palletWrapdetailId": palletWrapDetailIds,
                        "OtherSpecifications": shipmentData.otherSpecifications,
                        "palletWrapSpecifications": shipmentData.palletWrapSpecifications
                    }
                    let oldShipment = localStorage.getItem("shipmentId");
                    if (oldShipment) localStorage.removeItem("shipmentId");
                    axios.post(`${URLs.BACKEND_URL}/api/shipments/add-new-shipment${GetToken}`, data).then((res) => {
                        if (res.data.message.id) {
                            axios.post(`${URLs.BACKEND_URL}/api/shipments/load-calculator/`, { shipmentId: res.data.message.id, userId: arr[0]?.userId }).then((data) => {
                                dispatch(addShipmentId(res.data.message.id));
                                localStorage.setItem("shipmentId", res.data.message.id);
                                localStorage.setItem("grossWeight", data?.data?.unityData?.grossWeight);
                                navigate(`/visualization-of-loaded-truck/${res.data.message.id}/${arr[0].userId}`)
                            }).catch(err => {
                                axios.delete(`${URLs.BACKEND_URL}/api/shipments/${res.data.message.id}${GetToken}`).then(del => {
                                    enqueueSnackbar(err.response.data.error.message ? err.response.data.error.message : "Loading container error..!!", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                                }).catch(err => { });
                            });
                        }
                        else {
                            enqueueSnackbar("Please try again", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        }
                    }).catch((e) => {
                        if (e.response.data.error.message === 'could not find accessToken') {
                            localStorage.clear();
                            navigate('/login');
                        }
                    });
                }
            }
        }
    }

    // Other Specifications
    useEffect(() => {
        if (otherSpecifications) {
            let newData = otherSpecifications.filter((data) => {
                if (data.type === "palletWrap") {
                    return true;
                }
            });
            if (newData.length > 0) {
                setInput({
                    name: newData[0].specification.name,
                    productSpecifications: newData[0].specification.data,
                })
            }
            else {
                setInput(
                    {
                        name: "",
                        productSpecifications: "",
                    }
                )
            }
        }
    }, [palletWrap]);

    // For input
    useEffect(() => {
        if (input.name && input.productSpecifications) {
            let data = {
                "type": "palletWrap",
                "specification": {
                    "name": input.name,
                    "data": input.productSpecifications
                }
            }
            dispatch(addOtherSpecifications(data));
        }
    }, [input])

    // for palletWrapspecifications
    useEffect(() => {
        if (palletWrapSpecifications) {
            dispatch(addPalletWrapSpecifications(palletWrapSpecifications));
        }
    }, [palletWrapSpecifications]);

    // const handleUpdate = ()=>{
    //     if(palletWrapDetails.length>0){
    //         let palletWrapDetailIds = palletWrapDetails.map((data:any)=>data.id);
    //         let editData = {...editShipmentDetails,"palletWrapDetails":palletWrapDetails};
    //         let data = {
    //             "palletWrapdetailId": palletWrapDetailIds,
    //         };
    //         // Update data
    //         axios.patch(`${URLs.BACKEND_URL}/api/shipments/${editShipmentDetails._id}${GetToken}`,data).then((res) => {
    //             if (res.data.productId) {
    //                 console.log('Shipment updated completely..!!');
    //                 sessionStorage.setItem("editShipmentDetails",JSON.stringify(editData));
    //             }
    //         }).catch((e)=>{
    //             if ( e.response.data.error.message === 'could not find accessToken' ) {
    //               localStorage.clear();
    //               navigate('/login');
    //             }
    //         });
    //         // Add view logs
    //         const view_data = {
    //             "userId": arr[0].userId,
    //             "subject": "Plallet Wrap",
    //             "shipmentId": editShipmentDetails._id
    //         }
    //         axios.post(`${URLs.BACKEND_URL}/api/view_logs/${GetToken}`, view_data).then((res) => {
    //             if (res.data.id) {
    //                 console.log('View logs added completely..!!');
    //                 navigate(`/edit-shipment/${editShipmentDetails._id}`);
    //             }
    //         }).catch((e)=>{
    //             if ( e.response.data.error.message === 'could not find accessToken' ) {
    //               localStorage.clear();
    //               navigate('/login');
    //             }
    //         });
    //     }
    // }

    return (
        <div className="main-content">
            <div className="container">
                <div className="row mt-2">
                    <ActiveSteps />
                    <div className="col-md-12">
                        <div className={"goto-back " + (editShipmentDetails?._id && 'disableDiv')}>
                            <Link to="/pallet-dimension-details">
                                {!editShipmentDetails && <img className="" src={backIcon} alt="" />}
                                <span> Pallet Wrap Details </span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    {palletWrap.length > 0 && palletWrap.map((product) => {
                        return <PalletWrapData palletWrap={product} key={product.id} />
                    })
                    }
                </div>
                {showInputDiv && <div className="col-lg-8">
                    <form >
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group custom-input">
                                    <label >Name <span className="text-danger">*</span></label>
                                    <input type="string" className="form-control" name="name" id="firstName"
                                        value={input.name}
                                        onChange={addData}
                                        placeholder="Enter Pallet Wrap Name " pattern="[a-zA-Z]|[ ][a-zA-Z]+" />
                                    <div className="invalid-feedback">
                                        <div>Pallet Wrap Name is required</div>
                                        <div >Blank space is not allowed</div>
                                        <div >Please enter no more than 25 characters.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group custom-input">
                                    <label >Specifications <span className="text-danger">*</span></label>
                                    <input type="string" className="form-control" name="productSpecifications" id="firstName"
                                        value={input.productSpecifications}
                                        onChange={addData}
                                        placeholder="Enter Pallet Wrap Specifications " pattern="[a-zA-Z]|[ ][a-zA-Z]+" />
                                    <div className="invalid-feedback">
                                        <div>Pallet Wrap Specification is required</div>
                                        <div >Blank space is not allowed</div>
                                        <div >Please enter no more than 25 characters.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>}
                <div className="package-dia">
                    <div className="row mt-2">

                        <div className="col-md-6">
                            <div className="form-group custom-input">
                                <label> Enter Pallet Wrap Specifications </label>
                                <input className="form-control" type="text" value={palletWrapSpecifications} name='palletWrapSpecification' placeholder="Enter Specifications" onChange={(e) => setPalletWrapSpecifications(e.target.value)} />
                            </div>

                        </div>

                    </div>

                </div>
                <div className="mt-2">
                    <div className="col-md-12">
                        <div className="form-group custom-input mt-4 text-center">
                            <button className="btn-style-one text-center" onClick={handleCheck} >
                                {!editShipmentDetails ? `NEXT` : `BACK`}
                            </button>
                        </div>
                    </div>
                </div>
                {/* {editShipmentDetails?.palletWrapdetails?.length > 0 && <div className="mt-2">
                    <div className="col-md-12">
                        <div className="form-group custom-input mt-4 text-center">
                            <button className="btn-style-one text-center" onClick={handleUpdate} >
                                UPDATE
                            </button>
                        </div>
                    </div>
                </div>} */}

            </div>
        </div>
    )
}

export default PalletWrapDetails