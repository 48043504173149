import axios from 'axios'
import React, { useEffect, useState } from 'react';
import URLs from '../../Helpers/Constants';
import moment from 'moment'

function Notifications() {
  let arr = JSON.parse(localStorage.getItem("storageData"));
  let [notifications, setNotifications] = useState([]);

  useEffect(() => {
    axios.get(`${URLs.BACKEND_URL}/api/notification?filter[where][userId]=${arr[0].userId}`).then((resp: any) => {
      if(resp.data.length > 0) {
        setNotifications(resp.data.reverse());
      }
    }).catch(err => {
      console.log(err);
    });
  }, []);

  return (
    <div className="main-content">
      <div className="container">
        <div className="row mt-2 mb-2">
          <div className="col-lg-6">
            <div className="page-heading">
              <h3>Notifications</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {
              notifications.length > 0 ? notifications.map(notify => (
                <div className="notif-area" key={notify.id}>
                  <p>{notify.title} - {notify.contentId}</p>
                  <p><span className="float-start"> {moment(notify.createdAt).fromNow()} </span> <span className="float-end">{moment(notify.createdAt).format('ll')}</span></p>
                </div>
              )) : (
                <div className="notif-area py-4 mt-4">
                  <p>No data found..!!</p>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default Notifications