import { useEffect, useState } from 'react';
import ActiveSteps from '../ActiveSteps/ActiveSteps';
import '../../../Assets/css/style.css'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import backIcon from '../../../Assets/images/back-icon.svg'
import { useSnackbar } from 'notistack';
import DunnageSubcategoryData from './DunnageSubcategoryData';
import checkMarkIcon from '../../../Assets/images/checkmark.svg'
import URLs from '../../../Helpers/Constants';
import { addDunnageSubcategories, addOtherSpecifications, removeDunnageSubcategories } from '../../../Store/shipmentReducer';
import { addDunnageDimensions, addDunnageSubcategoryDataContainer, removeDunnageDimensions, removeDunnageSubcategoryDataContainer } from '../../../Store/dunnageDimensionReducer';
import { Loader } from 'semantic-ui-react'


function DunnageSubcategory() {
    let arr = JSON.parse(localStorage.getItem("storageData"));
    let GetToken = ""
    if (arr) {
        GetToken = `?access_token=${arr[0].id}`
    }
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [dunnageSubcategoryData, setDunnageSubcategoryData] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [buttonClicked, setButtonClicked] = useState("");
    const [showLoader, setShowLoader] = useState(true);
    let { dunnageSubcategory, shipmentId, otherSpecifications, dunnage } = useSelector((state: any) => state.shipment.data.shipmentData);
    let { dunnageDimensions, dunnageSubcategoryDataContainer } = useSelector((state: any) => state.dunnageDimensions.data);
    const [showInputDiv, setShowInputDiv] = useState(false);
    const [showDimensionInput, setShowDimensionInput] = useState(false);
    const [selectedDunnageSubcategory, setSelectedDunnageSubcategory] = useState("");
    const [selectedDunnageName, setSelectedDunnageName] = useState("");
    let editShipmentDetails = JSON.parse(sessionStorage.getItem("editShipmentDetails"));

    window.onload = (event: any) => {
        if (editShipmentDetails) {
            navigate(`/edit-shipment/${editShipmentDetails._id}`);
        }
        else {
            if (arr[0]?.user?.roleName === "shipping_manager") navigate('/shipment-mode')
            else navigate('/dashboard');
        }
        // if (event) {
        //     navigate('/shipment-mode')
        // }
    };

    useEffect(() => {
        setSelectedDunnageName(dunnage[0]?.name);
        axios.post(`${URLs.BACKEND_URL}/api/products/get-products-icon${GetToken}`, { modelName: "dunnage-subcategories", productId: dunnage[0]?.productId, dunnageId: dunnage[0]?.id })
            .then((res) => {
                let arr = res.data.message;
                arr = arr.filter((data) => {
                    if (data.dunnageId === dunnage[0].id) {
                        return true;
                    }
                });

                let data = [];
                let newProducts = arr.filter((product) => {
                    if (product.name !== 'Other') {
                        return true;
                    }
                });
                data = newProducts.sort((a: any, b: any) => a.name.localeCompare(b.name));
                let otherData = arr.filter((product) => {
                    if (product.name === 'Other') {
                        return true;
                    }
                });
                data.push(otherData[0]);
                // Scenario for setting edit subcategory
                if (editShipmentDetails && editShipmentDetails?.dunnageSubcategories?.length > 0) {
                    editShipmentDetails?.dunnageSubcategories.map((item: any) => {
                        arr.filter((newData: any) => {
                            if (item._id === newData.id || item.id === newData.id) {
                                if (item._id) {
                                    setSelectedDunnageSubcategory(item._id);
                                    setShowDimensionInput(true);
                                }
                                else {
                                    setSelectedDunnageSubcategory(item.id);
                                    setShowDimensionInput(true);
                                }
                            }
                        })
                    });
                }
                setDunnageSubcategoryData(data);
                document.getElementById(dunnage[0].id).classList.add("active-button");
                setButtonClicked(dunnage[0].id);
                setShowLoader(false)
            })
            .catch((e) => {
                setShowLoader(false)
                if (e.response.data.error.message === 'could not find accessToken') {
                    localStorage.clear();
                    navigate('/login');
                } else {
                    setDunnageSubcategoryData([]);
                    document.getElementById(dunnage[0].id).classList.add("active-button");
                    setButtonClicked(dunnage[0].id);
                }
            });

        // Scenario for setting selected subcategory
        dunnageSubcategory.map((data) => {
            dunnageSubcategoryData.filter((newData) => {
                if (data === newData.id) {
                    setSelectedDunnageSubcategory(data);
                }
            })
        });

        // dunnageSubcategory.map((data) => {
        //     axios.get(`${URLs.BACKEND_URL}/api/dunnage-subcategories/${data}${GetToken}`)
        //     .then((res)=>{
        //         if(!dunnage.some(el => el.id === res.data.dunnageId)){
        //             dispatch(removeDunnageSubcategories(data));
        //         }
        //     }
        // )});
    }, []);

    // ------------------ useeffect for the showing input div for the other ------------------------------------
    useEffect(() => {
        handleShowOtherInput();
    }, [dunnageSubcategoryDataContainer]);

    useEffect(() => {
        if (dunnageSubcategory.includes(buttonClicked)) {
            setShowDimensionInput(true);
        }
        else {
            setShowDimensionInput(false);
        }
    }, [buttonClicked]);

    // ------------------------- UseEffect for setting the other values ----------------------------------------
    useEffect(() => {
        handleShowOtherInput();
        // Scenario for setting selected subcategory
        dunnageSubcategory.map((data) => {
            dunnageSubcategoryData.filter((newData) => {
                if (data === newData.id) {
                    setSelectedDunnageSubcategory(data);
                    setShowDimensionInput(true);
                    return true;
                }
            })
        });

        if (otherSpecifications && dunnageSubcategoryData.length > 0) {
            let newData = otherSpecifications.filter((data) => {
                if (data.type === "dunnageSubcategory" && data.specification.dunnageId === dunnageSubcategoryData[0].dunnageId) {
                    return true;
                }
            });
            if (newData.length > 0) {
                setInput({
                    name: newData[0].specification.name,
                    productSpecifications: newData[0].specification.data,
                    imageName: ""
                })
            }
            else {
                setInput({
                    name: "",
                    productSpecifications: "",
                    imageName: ""
                })
            }
        }

        // case for setting dimensions
        if (dunnageDimensions && dunnageDimensions.length > 0) {
            let newData = dunnageDimensions.filter((data1: any) => {
                if (data1?.dunnages[0]?.dunnageId === buttonClicked) {
                    return true;
                }
            });
            if (newData.length > 0) {
                setDunnageDimension({
                    height: newData[0].dunnages[0].height,
                    length: newData[0].dunnages[0].length,
                    width: newData[0].dunnages[0].width,
                    psi: newData[0].dunnages[0].psi,
                    crush_strength: newData[0].dunnages[0].crush_strength,
                    break_strength: newData[0].dunnages[0].break_strength,
                    specifications: newData[0].dunnages[0].specifications,
                });
                setShowDimensionInput(true)
            }
            else {
                setDunnageDimension({
                    height: "",
                    length: "",
                    width: "",
                    psi: '',
                    crush_strength: '',
                    break_strength: '',
                    specifications: ''
                });
            }
        }

        if (dunnageSubcategoryData.length === 0) {
            setShowDimensionInput(true);
        }
    }, [dunnageSubcategoryData]);

    const [input, setInput] = useState({
        name: "",
        productSpecifications: "",
        imageName: ""
    });
    const [dunnageDimension, setDunnageDimension] = useState({
        height: "",
        length: "",
        width: "",
        psi: '',
        crush_strength: '',
        break_strength: '',
        specifications: ''
    });
    function addData(e: any) {
        setInput({ ...input, [e.target.name]: e.target.value });
    }
    // For dunnage dimensions
    function addDunnageDimension(e: any) {
        if (!isNaN(e.target.value)) {
            if (Number(e.target.value) <= 0) {
                enqueueSnackbar(`Please input value greater than 0.`,
                    { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            }
            else if (Number(e.target.value) > 99999) {
                enqueueSnackbar(`Please input value greater than 99999.`,
                    { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            }
            setDunnageDimension({ ...dunnageDimension, [e.target.name]: e.target.value });
        }
    }

    let handleCheck = () => {
        if (editShipmentDetails) {
            navigate(`/edit-shipment/${editShipmentDetails._id}`);
        }
        else {
            if (checkDunnageDimensionValidation()) {
                if (dunnage.length > 0 && dunnageSubcategory.length === 0 && (dunnage.some(el => el.name === 'Air Bags') || dunnage.some(el => el.name === 'Straps') || dunnage.some(el => el.name === 'Floor Brace') || dunnage.some(el => el.name === 'Load Bars'))) {
                    enqueueSnackbar("Please select a dunnage subcategory", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
                else {
                    if (dunnageSubcategory.length > 0) {
                        let newOtherData = dunnageSubcategoryData.filter((data) => {
                            if (data.name === "Other") {
                                return true;
                            }
                        });
                        if (newOtherData.length > 0) {
                            let newSelectedData = dunnageSubcategory.filter((data) => {
                                if (data === newOtherData[0]?.id) {
                                    return true;
                                }
                            });

                            if (newSelectedData.length > 0) {
                                if (input.name && input.productSpecifications) {
                                    handleDunnage();
                                    navigate(`/visualization-of-loaded-truck/${shipmentId}/${arr[0].userId}`)
                                }
                                else {
                                    enqueueSnackbar("Please fill the all other fields.", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                                }
                            }
                            else {
                                handleDunnage();
                                navigate(`/visualization-of-loaded-truck/${shipmentId}/${arr[0].userId}`)
                            }
                        }
                    }
                    handleDunnage();
                    navigate(`/visualization-of-loaded-truck/${shipmentId}/${arr[0].userId}`)
                }
            }
        }
    }

    useEffect(() => {
        if (input.name && input.productSpecifications) {
            let productData = dunnage.filter((data) => {
                if (data.id === dunnageSubcategoryData[0].dunnageId) {
                    return true;
                }
            })
            let data = {
                "type": "dunnageSubcategory",
                "specification": {
                    "name": input.name,
                    "data": input.productSpecifications,
                    "dunnageId": productData[0].id,
                    "dunnageName": productData[0].name
                }
            }
            dispatch(addOtherSpecifications(data));
        }
    }, [input]);

    useEffect(() => {
        if (dunnageSubcategoryData.length > 0) {
            if (dunnageDimension.width) {
                let data = {
                    "shipmentId": shipmentId,
                    "dunnages": [
                        {
                            dunnageSubcategoryId: selectedDunnageSubcategory,
                            length: dunnageDimension.length,
                            width: dunnageDimension.width,
                            height: dunnageDimension.height,
                            psi: dunnageDimension.psi,
                            crush_strength: dunnageDimension.crush_strength,
                            break_strength: dunnageDimension.break_strength,
                            specifications: dunnageDimension.specifications,
                            dunnageId: buttonClicked
                        },
                    ]
                }
                dispatch(addDunnageDimensions(data))
            }
        }
        else {
            if (dunnageDimension.width) {
                let data = {
                    "shipmentId": shipmentId,
                    "dunnages": [
                        {
                            dunnageSubcategoryId: buttonClicked,
                            length: dunnageDimension.length,
                            width: dunnageDimension.width,
                            height: dunnageDimension.height,
                            psi: dunnageDimension.psi,
                            crush_strength: dunnageDimension.crush_strength,
                            break_strength: dunnageDimension.break_strength,
                            specifications: dunnageDimension.specifications,
                            dunnageId: buttonClicked
                        },
                    ]
                }
                dispatch(addDunnageDimensions(data))
            }
        }

    }, [dunnageDimension]);

    let handleClick = (data: any) => {
        if (!editShipmentDetails?._id) {
            if (!selectedDunnageSubcategory) {
                setSelectedDunnageSubcategory(data.id);
                dispatch(addDunnageSubcategories(data.id));
                dispatch(addDunnageSubcategoryDataContainer(data))
                setShowDimensionInput(true);
            }
            else {
                if (data.id === selectedDunnageSubcategory) {
                    setSelectedDunnageSubcategory("");
                    dispatch(removeDunnageSubcategories(data.id));
                    dispatch(removeDunnageSubcategoryDataContainer(data));
                    setShowDimensionInput(false);
                }
                else {
                    setSelectedDunnageSubcategory(data.id);
                    dispatch(addDunnageSubcategories(data.id));
                    dispatch(addDunnageSubcategoryDataContainer(data))
                    setShowDimensionInput(true);
                }
            }
        }
    };

    let handleDunnage = () => {
        let newData = dunnageDimensions.map((data: any) => {
            return data.dunnages[0];
        });
        let data = {
            "shipmentId": shipmentId,
            "dunnages": newData
        }
        axios.post(`${URLs.BACKEND_URL}/api/unity_shipments/update-unity-data${GetToken}`, data).then((res) => { }).catch((e) => console.log(e));
    }

    // const handleUpdate = ()=>{

    //     if(dunnageSubcategory.length>0){
    //         let editData = {...editShipmentDetails,"dunnageSubcategories":dunnageSubcategoryDataContainer};
    //         let data = {
    //             "dunnageSubcategoryId": dunnageSubcategory,
    //         };
    //         axios.patch(`${URLs.BACKEND_URL}/api/shipments/${editShipmentDetails._id}${GetToken}`,data).then((res) => {
    //             if (res.data.productId) {
    //                 console.log('Shipment updated completely..!!');
    //                 sessionStorage.setItem("editShipmentDetails", JSON.stringify(editData));
    //                 navigate(`/edit-shipment/${editShipmentDetails._id}`)
    //             }
    //         }).catch((e)=>{
    //             if ( e.response.data.error.message === 'could not find accessToken' ) {
    //               localStorage.clear();
    //               navigate('/login');
    //             }
    //         });
    //     }
    // }

    const checkDunnageDimensionValidation = () => {
        if (dunnageDimensions.length === dunnage.length) {
            for (let i of dunnage) {
                let { name } = i;
                for (let j of dunnageDimensions) {
                    if (i.id === j.dunnages[0].dunnageId) {
                        if (name === "Air Bags") {
                            if ((j.dunnages[0].length > 0 && j.dunnages[0].length < 9999) && (j.dunnages[0].width > 0 && j.dunnages[0].width < 9999) && (j.dunnages[0].height > 0 && j.dunnages[0].height < 9999) && (j.dunnages[0].psi > 0 && j.dunnages[0].psi < 9999)) {
                                continue;
                            }
                            else {
                                enqueueSnackbar("Please fill correct dunnage dimensions for Air Bags!", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                                return false;
                            }
                        }
                        else if (name === "Straps") {
                            if ((j.dunnages[0].width > 0 && j.dunnages[0].width < 9999) && (j.dunnages[0].break_strength > 0 && j.dunnages[0].break_strength < 9999)) {
                                continue;
                            }
                            else {
                                enqueueSnackbar("Please fill correct dunnage dimensions for Straps!", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                                return false;
                            }
                        }
                        else if (name === "Corrugated Honeycomb" || name === "Kraft Paper Honeycomb") {
                            if ((j.dunnages[0].length > 0 && j.dunnages[0].length < 9999) && (j.dunnages[0].width > 0 && j.dunnages[0].width < 9999) && (j.dunnages[0].height > 0 && j.dunnages[0].height < 9999) && (j.dunnages[0].crush_strength > 0 && j.dunnages[0].crush_strength < 9999)) {
                                continue;
                            }
                            else {
                                enqueueSnackbar(`Please fill correct dunnage dimensions for ${name}!`, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                                return false;
                            }
                        }
                        else {
                            if ((j.dunnages[0].length > 0 && j.dunnages[0].length < 9999) && (j.dunnages[0].width > 0 && j.dunnages[0].width < 9999) && (j.dunnages[0].height > 0 && j.dunnages[0].height < 9999)) {
                                continue;
                            }
                            else {
                                enqueueSnackbar(`Please fill correct dunnage dimensions for ${name}!`, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                                return false;
                            }
                        }
                    }
                }
            }
            return true;
        }
        else {
            enqueueSnackbar("Please fill dunnage dimensions of all the data", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            return false
        }
    }

    const handleShowOtherInput = () => {
        if (dunnageSubcategoryData.length > 0) {
            let newData = dunnageSubcategoryData.filter((data) => {
                if (data.name === "Other") {
                    return true;
                }
            });

            let newSelectedData = dunnageSubcategoryDataContainer.filter((data: any) => {
                if (data.name === "Other" && data.dunnageId === newData[0].dunnageId) {
                    return true;
                }
            });
            if (newSelectedData.length > 0) {
                setShowInputDiv(true);
            }
            else {
                setShowInputDiv(false);
            }

        }
    }

    return (
        <>
            <div className="main-content">
                <div className="container">
                    <div className="row mt-2">
                        <ActiveSteps />
                        <div className="col-md-12">
                            <div className={"goto-back " + (editShipmentDetails?._id && 'disableDiv')}>
                                <Link to={`/dunnage`}>
                                    {!editShipmentDetails && <img className="" src={backIcon} alt="" />}
                                    <span>Dunnage Subcategory</span>
                                </Link>
                            </div>
                        </div>

                        {/* <!--------------------------------- Start Tabs Data ---------------------------------------> */}

                        <div className="mng_shipment_data">
                            {/* <!-- Tabs menu --> */}
                            <ul className="nav nav-tabs" role="tablist">
                                {dunnage.length > 0 && dunnage.map((tab) => {
                                    return <li key={tab.id} className="nav-item nav-item-cus" id={tab.id}>
                                        <button className="nav-link nav-link-cus active" onClick={() => {
                                            setSelectedDunnageSubcategory("");
                                            setDunnageDimension({
                                                height: "",
                                                length: "",
                                                width: "",
                                                psi: '',
                                                crush_strength: '',
                                                break_strength: '',
                                                specifications: ''
                                            });
                                            setSelectedDunnageName(tab.name)
                                            axios.post(`${URLs.BACKEND_URL}/api/products/get-products-icon${GetToken}`, { modelName: "dunnage-subcategories", dunnageId: tab.id, productId: tab?.productId })
                                                .then((res) => {
                                                    let arr = res.data.message;
                                                    arr = arr.filter((data) => {
                                                        if (data.dunnageId === tab?.id) {
                                                            return true;
                                                        }
                                                    });
                                                    let data = [];
                                                    let newProducts = arr.filter((product) => {
                                                        if (product.name !== 'Other') {
                                                            return true;
                                                        }
                                                    });
                                                    data = newProducts.sort((a: any, b: any) => a.name.localeCompare(b.name));
                                                    let otherData = arr.filter((product) => {
                                                        if (product.name === 'Other') {
                                                            return true;
                                                        }
                                                    });
                                                    data.push(otherData[0]);
                                                    // Scenario for setting edit subcategory
                                                    if (editShipmentDetails && editShipmentDetails?.dunnageSubcategories?.length > 0) {
                                                        editShipmentDetails?.dunnageSubcategories.map((item: any) => {
                                                            arr.filter((newData: any) => {
                                                                if (item._id === newData.id || item.id === newData.id) {
                                                                    if (item._id) {
                                                                        setSelectedDunnageSubcategory(item._id);
                                                                        setShowDimensionInput(true);
                                                                    }
                                                                    else {
                                                                        setSelectedDunnageSubcategory(item.id);
                                                                        setShowDimensionInput(true);
                                                                    }
                                                                }
                                                            })
                                                        });
                                                    }
                                                    setDunnageSubcategoryData(data);
                                                    document.getElementById(buttonClicked).classList.remove("active-button");
                                                    setButtonClicked(tab.id);
                                                    document.getElementById(tab.id).classList.add("active-button");
                                                    setShowLoader(false)
                                                })
                                                .catch((e) => {
                                                    setShowLoader(false)
                                                    if (e.response.data.error.message === 'could not find accessToken') {
                                                        localStorage.clear();
                                                        navigate('/login');
                                                    }
                                                    else {
                                                        setDunnageSubcategoryData([]);
                                                        document.getElementById(buttonClicked).classList.remove("active-button");
                                                        setButtonClicked(tab.id);
                                                        document.getElementById(tab.id).classList.add("active-button");
                                                    }
                                                });
                                        }} >
                                            {tab.name}
                                        </button>
                                    </li>
                                })}
                            </ul>
                            {/* <!-- End Tabs menu --> */}
                            {showLoader && <div style={{ marginTop: 100 }}>
                                <Loader active inline='centered' size='big' />
                            </div>}
                            {!showLoader && <div className="tab-content tab-content-cus">
                                <div className="tab-pane active" id="tabs-1" role="tabpanel">
                                    {dunnageSubcategoryData.length > 0 &&
                                        <div className="row mt-5">
                                            {dunnageSubcategoryData.map((productSubcategory) => {
                                                // return <DunnageSubcategoryData dunnageSubcategoryData={productSubcategory} key={productSubcategory?.id} />
                                                return <div className="col-md-2 col-sm-6 col-6 d-flex justify-content-center">
                                                    <div className="item d-flex flex-column justify-content-center align-items-center">
                                                        <div className="item-details d-flex justify-content-center align-items-center" onClick={() => handleClick(productSubcategory)}>
                                                            {(selectedDunnageSubcategory === productSubcategory.id) && <div style={{ position: "absolute", top: 0, right: 7 }} ><img src={checkMarkIcon} alt='' /></div>}
                                                            <img src={productSubcategory.icon.iconUrl} alt="" id={productSubcategory.id} />
                                                        </div>
                                                        <div className="text-center mt-2">{productSubcategory.name}</div>
                                                    </div>
                                                </div>
                                            })
                                            }
                                        </div>}
                                    {showInputDiv && <div className="col-lg-8">
                                        <form >
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group custom-input">
                                                        <label >Name <span className="text-danger">*</span></label>
                                                        <input type="string" className="form-control" name="name" id="firstName"
                                                            value={input.name}
                                                            onChange={addData}
                                                            placeholder="Enter Dunnage Subcategory Name " pattern="[a-zA-Z]|[ ][a-zA-Z]+" />
                                                        <div className="invalid-feedback">
                                                            <div>Dunnage Subcategory Name is required</div>
                                                            <div >Blank space is not allowed</div>
                                                            <div >Please enter no more than 25 characters.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group custom-input">
                                                        <label >Specifications <span className="text-danger">*</span></label>
                                                        <input type="string" className="form-control" name="productSpecifications" id="firstName"
                                                            value={input.productSpecifications}
                                                            onChange={addData}
                                                            placeholder="Enter Dunnage Subcategory Specifications " pattern="[a-zA-Z]|[ ][a-zA-Z]+" />
                                                        <div className="invalid-feedback">
                                                            <div>Dunnage Subcategory Specification is required</div>
                                                            <div >Blank space is not allowed</div>
                                                            <div >Please enter no more than 25 characters.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>}
                                    {showDimensionInput && <div className="row mt-2">
                                        {/* <!--------------------------------- Start dunnage dimension input ---------------------------------------> */}

                                        <div className="mng_shipment_data">
                                            <div className="tab-content tab-content-cus">
                                                <div className="tab-pane active" id="tabs-1" role="tabpanel">
                                                    <div className="package-dia">
                                                        <div className="row mt-3">
                                                            <div className="col-md-12">
                                                                <h6 className="mb-4"> Enter Dimensions Of Dunnage (Inches) </h6>
                                                                <div className="row">
                                                                    {selectedDunnageName !== 'Straps' && <div className="col-sm-3">
                                                                        <div className="form-group custom-input">
                                                                            <label> Length </label>
                                                                            <input className='form-control' disabled={editShipmentDetails && true} type='number' value={dunnageDimension.length} placeholder='Enter Length' name='length' style={{ height: 40 }} onChange={addDunnageDimension} />
                                                                        </div>
                                                                    </div>}
                                                                    {selectedDunnageName !== 'Straps' && <div className="col-sm-1 mid-con">
                                                                        <div className="text-center"> X </div>
                                                                    </div>}
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group custom-input">
                                                                            <label> Width </label>
                                                                            <input className='form-control' placeholder='Enter Width' value={dunnageDimension.width} disabled={editShipmentDetails && true} type='number' name='width' style={{ height: 40 }} onChange={addDunnageDimension} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-1 mid-con">
                                                                        <div className="text-center"> X </div>
                                                                    </div>
                                                                    {selectedDunnageName !== 'Straps' && <div className="col-sm-3">
                                                                        <div className="form-group custom-input">
                                                                            <div className="form-group custom-input">
                                                                                <label> Height</label>
                                                                                <input className='form-control' value={dunnageDimension.height} placeholder='Enter Height' disabled={editShipmentDetails && true} type='number' name='height' style={{ height: 40 }} onChange={addDunnageDimension} />
                                                                            </div>
                                                                        </div>
                                                                    </div>}
                                                                    {selectedDunnageName === 'Air Bags' && <div className="col-sm-3">
                                                                        <div className="form-group custom-input">
                                                                            <label> PSI </label>
                                                                            <input className='form-control' placeholder='Enter PSI' value={dunnageDimension.psi} type='number' disabled={editShipmentDetails && true} name='psi' style={{ height: 40 }} onChange={addDunnageDimension} />
                                                                        </div>
                                                                    </div>}
                                                                    {selectedDunnageName === 'Straps' && <div className="col-sm-3">
                                                                        <div className="form-group custom-input">
                                                                            <label> Break Strength </label>
                                                                            <input className='form-control' placeholder='Enter Break Strength' value={dunnageDimension.break_strength} type='number' disabled={editShipmentDetails && true} name='break_strength' style={{ height: 40 }} onChange={addDunnageDimension} />
                                                                        </div>
                                                                    </div>}
                                                                    {(selectedDunnageName === 'Corrugated Honeycomb' || selectedDunnageName === 'Kraft Paper Honeycomb') && <div className="col-sm-3">
                                                                        <div className="form-group custom-input">
                                                                            <label> Crush Strength </label>
                                                                            <input className='form-control' placeholder='Enter Crush Strength' value={dunnageDimension.crush_strength} type='number' disabled={editShipmentDetails && true} name='crush_strength' style={{ height: 40 }} onChange={addDunnageDimension} />
                                                                        </div>
                                                                    </div>}
                                                                    {/* {selectedDunnageName === 'Flexible Restraint System' && <div className="col-sm-3">
                                                                        <div className="form-group custom-input">
                                                                            <label> Enter Specifications </label>
                                                                            <input className='form-control' placeholder='Enter Specifications' value={dunnageDimension.specifications} type='text' disabled={editShipmentDetails && true} name='specifications' style={{ height: 40 }} onChange={addDunnageDimension} />
                                                                        </div>
                                                                    </div>} */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!---------------------------------------------  End Tab Data  ----------------------------------------------->  */}

                                    </div>}
                                </div>
                            </div>}
                        </div>

                        {/* <!---------------------------------------------  End Tab Data  ----------------------------------------------->  */}

                    </div>
                    <div className="mt-2">
                        <div className="col-md-12">
                            <div className="form-group custom-input mt-4 text-center">
                                <button className="btn-style-one text-center" onClick={handleCheck} >
                                    {!editShipmentDetails ? `NEXT` : `BACK`}
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* {editShipmentDetails?.dunnageSubcategories?.length > 0 && <div className="mt-2">
                    <div className="col-md-12">
                        <div className="form-group custom-input mt-4 text-center">
                            <button className="btn-style-one text-center" onClick={handleUpdate} >
                                UPDATE
                            </button>
                        </div>
                    </div>
                </div>} */}
                </div>
            </div>
        </>
    )
}


export default DunnageSubcategory