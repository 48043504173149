import { Link, useNavigate } from 'react-router-dom';
import './ForgotPassword.css';
import Logo from '../../Assets/img/logo.png';
import { FieldGroup, FormBuilder, Validators, AbstractControl, FieldControl } from 'react-reactive-form';
import { forgotPassword } from '../../Services/commonService';
import { useSnackbar } from 'notistack'

function ForgotPassword() {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar()
    const loginForm = FormBuilder.group({
        email: ["", [Validators.required, Validators.email]]
    });

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (loginForm.valid) {
            let data = await forgotPassword({ ...loginForm.value, roleType: "shipping_manager" });
            if (data.message.verifiedEmail) {
                enqueueSnackbar(data.message.message,
                    { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                navigate('/otp-validation');
            }
            else {
                enqueueSnackbar(data.response.data.error.message,
                    { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } }
                    )
            }
            
        } else {
            loginForm.markAsTouched();
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 d-flex align-items-center justify-content-center logo-bg">
                        <div className="logo text-center">
                            <Link to="#"><img src={Logo} alt="Logo" /></Link>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="login_right_side">
                            <div className="login_form">
                                <h2 className="text-white text-center pb-2">Forgot password?</h2>
                                <p className="text-white text-center mb-5">
                                    Don't worry. Resetting your password is easy. Just tell
                                    us <br></br> the email address that was used to create this account.
                                </p>
                                <FieldGroup
                                    control={loginForm}
                                    render={({ pristine, invalid, pending, value }: AbstractControl) => (
                                        <form onSubmit={handleSubmit}>
                                            <FieldControl
                                                name='email'
                                                render={({ handler, touched, hasError }: AbstractControl) => (
                                                    <div className="form-group">
                                                        <label htmlFor="email">Email Address</label>
                                                        <input type="email" className="form-control form-control1 mb-0" {...handler()} id="email" />
                                                        <div className='mb-2'>
                                                            <span className='errorText'>
                                                                {(touched &&
                                                                    ((hasError("required") && "Email is required") ||
                                                                        (hasError("email") &&
                                                                            "Please enter a valid email")))}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                            <div className="form-group custom-input">
                                                <button type="submit" className="btn-style-one btn btn_default text-center afterhover">
                                                    <div className=" text-light align-top" role="status" >
                                                        {/* <span>Loading...</span> */}
                                                        <span>RESET PASSWORD</span>
                                                    </div>
                                                    {/* <ng-container *ngIf="!loading">RESET PASSWORD</ng-container> */}
                                                </button>
                                            </div>
                                            <div className="text-center mt-4">
                                                <Link to='/login' className="secondry-color">Login</Link>
                                            </div>
                                        </form>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword;

