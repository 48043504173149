import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import backIcon from '../../../Assets/images/back-icon.svg'
import axios from 'axios';
import '../../../Assets/css/style.css';
import ActiveSteps from '../ActiveSteps/ActiveSteps';
import { useSelector, useDispatch } from 'react-redux';
import { addShipmentMode, emptyShipmentReducer } from '../../../Store/shipmentReducer';
import containerImage from '../../../Assets/images/container.png'
import URLs from '../../../Helpers/Constants';
import { emptyDunnageDimensionsReducer } from '../../../Store/dunnageDimensionReducer';
// import GetToken from '../../../Helpers/StorageFunction'
function TransportationMode() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let arr = JSON.parse(localStorage.getItem("storageData"));
    let GetToken = ""
    if (arr) {
        if (localStorage.getItem("refresKey")) {
            localStorage.removeItem("refresKey");
        }
        GetToken = `?access_token=${arr[0].id}`
    }
    const [shipmentMode, setShipmentMode] = useState([]);
    // let data1 = useSelector((state:any) => state.shipment.data);
    window.onload = (event: any) => {
        if (arr[0]?.user?.roleName !== "shipping_manager") navigate('/dashboard')
    };
    useEffect(() => {
        window.sessionStorage.clear();
        dispatch(emptyShipmentReducer(
            {
                products: [],
                subcategories: [],
                packagingDetails: {},
                packageDimensions: [],
                palletDetail: [],
                palletDimensions: {},
                palletWrapDetails: [],
                dunnage: [],
                dunnageSubcategory: [],
                otherSpecifications: [],
                palletWrapSpecifications: {}
            }
        ));
        dispatch(emptyDunnageDimensionsReducer(true));
        axios.post(`${URLs.BACKEND_URL}/api/products/get-products-icon${GetToken}`, { modelName: "shipment-mode" })
            .then((res) => {
                let newData = res.data.message.filter((mode) => {
                    if (mode.name === 'Container') {
                        return true;
                    }
                });
                setShipmentMode(newData);
            })
            .catch((e) => {
                if (e.response.data.error.message === 'could not find accessToken') {
                    localStorage.clear();
                    navigate('/login');
                }
            });
    }, []);
    let handleCheck = () => {
        if (shipmentMode.length > 0) {
            dispatch(addShipmentMode(shipmentMode[0]));
        }
    }

    return (
        <>
            <div className="main-content">
                <div className="container">
                    <div className="row mt-2">
                        <ActiveSteps />
                        <div className="col-md-12">
                            <div className="goto-back">
                                <Link to="/dashboard">
                                    <img className="" src={backIcon} alt="" />
                                    <span> Transportation Mode </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-center">
                                <div><img src={containerImage} width="504" height="504" alt="" className="img-fluid" /></div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="text-center">
                                <p>40 ft HC Container</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <div className="form-group custom-input mt-4 text-center">
                                <Link to='/add-new-product' style={{ color: "white", textDecoration: "none" }}>
                                    <button className="btn-style-one text-center" onClick={handleCheck} >
                                        NEXT
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TransportationMode