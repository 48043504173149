import {useEffect, useState} from 'react'
import checkMarkIcon from '../../../Assets/images/checkmark.svg'
import { useDispatch, useSelector } from 'react-redux';
import { addPalletWrapDetail,removePalletWrapDetail } from '../../../Store/shipmentReducer';

const PalletWrapData = ({palletWrap}) => {
    const [clicked, setClicked] = useState(false);
    let { palletWrapDetails } = useSelector((state: any) => state.shipment.data.shipmentData);
    let editShipmentDetails = JSON.parse(sessionStorage.getItem("editShipmentDetails"));
    // console.log(editShipmentDetails);
    
    const dispatch = useDispatch();
    let handleClick = () => {
        if(!editShipmentDetails?._id){
            setClicked(!clicked);
        }
    };

    useEffect(() => {
        if (clicked) {
            dispatch(addPalletWrapDetail(palletWrap)); 
                   }
        else{
            dispatch(removePalletWrapDetail(palletWrap));
        }
    }, [clicked]);

    useEffect(() => {
        if(palletWrapDetails.length > 0) {
            palletWrapDetails.forEach((prod: any) => {
                if (prod.id == palletWrap.id) {
                    setClicked(true);
                }
            });
        }
        // Code for edit shipment
        if (editShipmentDetails?.palletWrapdetails?.length > 0 ) {
            editShipmentDetails.palletWrapdetails.forEach((prod: any) => {
                if (prod._id === palletWrap.id) {
                    setClicked(true);
                }
            })
        }   
    }, []);

    return (
        <>
            <div className="col-md-2 col-sm-6 col-6 d-flex justify-content-center">
            <div className="item d-flex flex-column justify-content-center align-items-center">
                <div className="item-details d-flex justify-content-center align-items-center" onClick={handleClick}>
                        {clicked && <div style={{ position: "absolute", top: 0, right:7 }}><img src={checkMarkIcon} alt='' /></div>}
                        <img src={palletWrap.icon.iconUrl} alt="" id={palletWrap.id} />
                    </div>
                    <div className="text-center mt-2">{palletWrap.name}</div>
                </div>
            </div>
        </>
    )
}

export default PalletWrapData