import { useState, useEffect } from 'react'
import checkMarkIcon from '../../../Assets/images/checkmark.svg'
import { useDispatch, useSelector } from 'react-redux';
import { addSubcategories,removeSubcategories } from '../../../Store/shipmentReducer';
import './SubcategoryData.css';

function SubcategoryData({ subcategory, checkChemicals }) {
    const [clicked, setClicked] = useState(false);
    let { subcategories } = useSelector((state: any) => state.shipment.data.shipmentData);
    let editShipmentDetails = JSON.parse(sessionStorage.getItem("editShipmentDetails"));
    const dispatch = useDispatch();
    let handleClick = () => {
        if(!editShipmentDetails?._id){
            setClicked(!clicked);
        }
    };
    useEffect(() => {
        if (clicked) {
            dispatch(addSubcategories(subcategory));
        }
        else{
            dispatch(removeSubcategories(subcategory));
        }
    }, [clicked]);

    useEffect(() => {
        if (subcategories.length > 0) {
            subcategories.forEach((prod: any) => {
                if (prod.id === subcategory.id) {
                    setClicked(true);
                }
            });
        }
        // code for edit shipment content
        if (editShipmentDetails?.productSubcategories?.length > 0 ) {
            editShipmentDetails.productSubcategories.forEach((prod: any) => {
                if (prod._id === subcategory.id) {
                    setClicked(true);
                }
            })
        }
    }, []);

    return (
        <>
            <div className="col-md-2 col-sm-6 col-6 d-flex justify-content-center ">
                <div className="item d-flex flex-column justify-content-center align-items-center">
                    <div className={checkChemicals ? "item-details d-flex justify-content-center align-items-center add-border" : "item-details d-flex justify-content-center align-items-center"} onClick={handleClick}>
                        {clicked && <div style={{ position: "absolute", top: 0, right: 2 }} ><img src={checkMarkIcon} alt='' /></div>}
                        <img src={subcategory.icon.iconUrl} alt="" id={subcategory.id} />
                    </div>
                    <div className="text-center mt-2">{subcategory.name}</div>
                </div>
            </div>
        </>
    )
}

export default SubcategoryData