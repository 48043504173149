import { useEffect, useState } from 'react'
import '../../../Assets/css/style.css'
import { Link, useNavigate } from 'react-router-dom';
import backIcon from '../../../Assets/images/back-icon.svg'
import axios from 'axios';
import ProductData from './ProductData';
import ActiveSteps from '../ActiveSteps/ActiveSteps';
import { useDispatch, useSelector } from 'react-redux';
import { addOtherSpecifications, emptyShipmentReducer } from '../../../Store/shipmentReducer';
import { useSnackbar } from 'notistack'
import URLs from '../../../Helpers/Constants';
import { addEditShipmentDetails, emptyDunnageDimensionsReducer } from '../../../Store/dunnageDimensionReducer';
// import GetToken from '../../../Helpers/StorageFunction';

const Products = () => {
    let arr = JSON.parse(localStorage.getItem("storageData"));
    let { otherSpecifications, products } = useSelector((state: any) => state.shipment.data.shipmentData);
    let GetToken = ""
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [product, setProduct] = useState([]);
    const { enqueueSnackbar } = useSnackbar()
    // let [selectedProducts, setSelectedProducts] = useState({});
    const [showInputDiv, setShowInputDiv] = useState(false);
    // let { editShipmentDetails } = useSelector((state: any) => state.dunnageDimensions.data);
    let editShipmentDetails = JSON.parse(sessionStorage.getItem("editShipmentDetails"));

    const [input, setInput] = useState({
        name: "",
        productSpecifications: "",
        imageName: ""
    });

    if (arr) GetToken = `?access_token=${arr[0].id}`
    window.onload = (event: any) => {
        if (editShipmentDetails) {
            navigate(`/edit-shipment/${editShipmentDetails._id}`);
        }
        else {
            if (arr[0]?.user?.roleName === "shipping_manager") navigate('/shipment-mode')
            else navigate('/dashboard');
        }
        // if (event) {
        //     navigate('/shipment-mode')
        // }
    };

    function addData(e: any) {
        setInput({ ...input, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        axios.post(`${URLs.BACKEND_URL}/api/products/get-products-icon${GetToken}`, { modelName: "products" }).then((res) => {
            let productList = res.data.message;
            let data = [];
            let newProducts = productList.filter((product) => {
                if (product.name !== 'Other') {
                    return true;
                }
            });
            data = newProducts.sort((a: any, b: any) => a.name.localeCompare(b.name));
            let otherData = productList.filter((product) => {
                if (product.name === 'Other') {
                    return true;
                }
            });
            data.push(otherData[0]);
            setProduct(data);
            // logic for clearing all the further data from the reducer
            if (!editShipmentDetails) {
                dispatch(emptyShipmentReducer(
                    {
                        subcategories: [],
                        packagingDetails: {},
                        packageDimensions: [],
                        palletDetail: [],
                        palletDimensions: {},
                        palletWrapDetails: [],
                        dunnage: [],
                        dunnageSubcategory: [],
                        palletWrapSpecifications: {},
                        otherType: "product"
                    }
                ));
                dispatch(emptyDunnageDimensionsReducer(true));
            }
        }).catch((e) => {
            if (e.response.data.error.message === 'could not find accessToken') {
                localStorage.clear();
                navigate('/login');
            }
        });
    }, []);

    useEffect(() => {
        let newData = products.filter((data) => {
            if (data.name === "Other") {
                return true;
            }
        });
        if (newData.length > 0) {
            setShowInputDiv(true);
        } else {
            setShowInputDiv(false);
        }
    }, [products]);

    let handleCheck = () => {
        if (editShipmentDetails) {
            navigate(`/edit-shipment/${editShipmentDetails._id}`);
        }
        else {
            if (input.name && input.productSpecifications) {
                let data = {
                    "type": "product",
                    "specification": {
                        "name": input.name,
                        "data": input.productSpecifications
                    }
                }
                dispatch(addOtherSpecifications(data));
            }
            if (products.length === 0) {
                enqueueSnackbar("Please select a product",
                    { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            }
            else {
                let newData = products.filter((data: any) => {
                    if (data.name === 'Other') {
                        return true;
                    }
                });
                if (newData.length > 0) {
                    if (input.name && input.productSpecifications) {
                        navigate('/subcategories-of-product');
                    }
                    else {
                        enqueueSnackbar("Please fill the all other fields.",
                            { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    }
                }
                else {
                    navigate('/subcategories-of-product');
                }
            }
        }
    }

    useEffect(() => {
        if (otherSpecifications) {
            let newData = otherSpecifications.filter((data) => {
                if (data.type === 'product') {
                    return true;
                }
            });
            if (newData.length > 0) {
                setInput({
                    name: newData[0].specification.name,
                    productSpecifications: newData[0].specification.data,
                    imageName: ""
                })
            }
        }
    }, [otherSpecifications]);

    // const handleUpdate = ()=>{

    //     if(products.length>0){
    //         let productIds = products.map((data:any)=>data.id);
    //         let editData = {...editShipmentDetails,"products":products,"otherSpecifications":otherSpecifications};
    //         let data = {
    //             "productId": productIds,
    //         };
    //         // Update data
    //         axios.patch(`${URLs.BACKEND_URL}/api/shipments/${editShipmentDetails._id}${GetToken}`,data).then((res) => {
    //             if (res.data.productId) {
    //                 console.log('Shipment updated completely..!!');
    //                 sessionStorage.setItem("editShipmentDetails",JSON.stringify(editData));
    //             }
    //         }).catch((e)=>{
    //             if ( e.response.data.error.message === 'could not find accessToken' ) {
    //               localStorage.clear();
    //               navigate('/login');
    //             }
    //         });
    //         // Add view logs
    //         const view_data = {
    //             "userId": arr[0].userId,
    //             "subject": "Product",
    //             "shipmentId": editShipmentDetails._id
    //         }
    //         axios.post(`${URLs.BACKEND_URL}/api/view_logs/${GetToken}`, view_data).then((res) => {
    //             if (res.data.id) {
    //                 console.log('View logs added completely..!!');
    //                 navigate(`/edit-shipment/${editShipmentDetails._id}`);
    //             }
    //         }).catch((e)=>{
    //             if ( e.response.data.error.message === 'could not find accessToken' ) {
    //               localStorage.clear();
    //               navigate('/login');
    //             }
    //         });
    //     }
    //     else{
    //         enqueueSnackbar("Please Select At Least One Product.",
    //         { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } }); 
    //     }
    // }

    return (
        <>
            <div className="main-content">
                <div className="container">
                    <div className="row mt-2">
                        {/* <ActiveSteps /> */}
                        <div className="col-md-12">
                            <div className={"goto-back " + (editShipmentDetails?._id && 'disableDiv')}>
                                <Link to="/shipment-mode">
                                    {!editShipmentDetails && <img className="" src={backIcon} alt="" />}
                                    <span> Product Type </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-start mt-2">
                        {product.length > 0 && product.map((product) => {
                            return <ProductData product={product} key={product.id} />
                        })
                        }
                    </div>
                    {showInputDiv && <div className="col-lg-8">
                        <form >
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group custom-input">
                                        <label >Name <span className="text-danger">*</span></label>
                                        <input type="string" className="form-control" name="name" id="firstName"
                                            value={input.name}
                                            onChange={addData}
                                            placeholder="Enter Product Name " pattern="[a-zA-Z]|[ ][a-zA-Z]+" />
                                        <div className="invalid-feedback">
                                            <div>Product Name is required</div>
                                            <div >Blank space is not allowed</div>
                                            <div >Please enter no more than 25 characters.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group custom-input">
                                        <label >Specifications <span className="text-danger">*</span></label>
                                        <input type="string" className="form-control" name="productSpecifications" id="firstName"
                                            value={input.productSpecifications}
                                            onChange={addData}
                                            placeholder="Enter Product Specifications " pattern="[a-zA-Z]|[ ][a-zA-Z]+" />
                                        <div className="invalid-feedback">
                                            <div>Product Specification is required</div>
                                            <div >Blank space is not allowed</div>
                                            <div >Please enter no more than 25 characters.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>}
                </div>
            </div>

            <div className="mt-2">
                <div className="col-md-12">
                    <div className="form-group custom-input mt-4 text-center">
                        <button className="btn-style-one text-center" onClick={handleCheck} >
                            {!editShipmentDetails ? `NEXT` : `BACK`}
                        </button>
                    </div>
                </div>
            </div>
            {/* { editShipmentDetails?.products?.length >0 && <div className="mt-2">
                <div className="col-md-12">
                    <div className="form-group custom-input mt-4 text-center">
                            <button className="btn-style-one text-center" onClick={handleUpdate} >
                                UPDATE
                            </button>
                    </div>
                </div>
            </div>} */}
        </>
    )
}

export default Products