import { useEffect, useState } from 'react'
import AddInspectionReport from './AddInspectionReport'
import axios from 'axios'
import URLS from "../../Helpers/Constants";
import { Link, useNavigate, useParams } from 'react-router-dom';
import backIcon from '../../Assets/images/back-icon.svg'

function InspectionnReport() {
    const [inspectionReport, setInspectionreport] = useState([]);
    const { id } = useParams();
    let arr = JSON.parse(localStorage.getItem("storageData"));
    let GetToken = ""
    if (arr) GetToken = `?access_token=${arr[0].id}`;
    const navigate = useNavigate();
    const getInspectionReport = (orderId: any) => {
        axios.post(`${URLS.BACKEND_URL}/api/inspection-reports/get-all-inspection-report${GetToken}`, { orderId }).then((res) => {
            setInspectionreport(res.data.message);
            // Code for set inspection report id 
            if (res.data.message.length > 0) {
                let data1 = { ...arr[0], "inspectionReportId": res.data.message[0].id };
                localStorage.clear();
                let storageData = JSON.parse(localStorage.getItem("storageData") || "[]");
                if (storageData.length === 0) {
                    storageData.push(data1);
                }
                localStorage.setItem("storageData", JSON.stringify(storageData));
            }
        }).catch((e) => {
            if (e.response.data.error.message === 'could not find accessToken') {
                localStorage.clear();
                navigate('/login');
            }
        });
    }

    useEffect(() => {
        getInspectionReport(id);
    }, []);

    let status = (val) => {
        if (val) getInspectionReport(id);
    }

    return (
        <div className="main-content">
            {inspectionReport.length > 0 && <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="goto-back">
                            <Link to="/dashboard"><img className="" src={backIcon} alt="" />
                                <span> Inspect View </span>
                            </Link>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-9">
                            <p>Identification No.</p>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group custom-input">
                                <input type="text" className="form-control" placeholder="Identification No." value={inspectionReport[0]?.identificationNumber} disabled={true} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-12">
                        <div className="ins-report">
                            <div className="add-report">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="cmt-box">
                                            <h5>1. Did doors open and close properly?</h5>
                                            <p className="iv-and mng-id">Answer:<span> &nbsp;{inspectionReport[0].doorsAreOpen.status ? "Yes" : "No"}</span></p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <hr />
                                        <div className="pic-details">
                                            {inspectionReport[0].doorsAreOpen.images.length > 0 && <div className="no-of-pic py-0 my-0 float-start">
                                                <ul>
                                                    {
                                                        inspectionReport[0].doorsAreOpen.images.map((data) => (
                                                            <li>
                                                                <a href={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/inspection-report-images/download/${data}`} target="_blank" rel='noreferrer'>
                                                                    <img src={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/inspection-report-images/download/${data}`} alt="" />
                                                                </a>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-2">
                        <div className="ins-report">
                            <div className="add-report">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="cmt-box">
                                            <h5>2. Did floor clear  properly?</h5>
                                            <p className="iv-and mng-id">Answer:<span> &nbsp;{inspectionReport[0].floorIsClear.status ? "Yes" : "No"}</span></p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <hr />
                                        <div className="pic-details">
                                            {inspectionReport[0].floorIsClear.images.length > 0 && <div className="no-of-pic py-0 my-0 float-start">
                                                <ul>
                                                    {
                                                        inspectionReport[0].floorIsClear.images.map((data) => (
                                                            <li>
                                                                <a href={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/inspection-report-images/download/${data}`} target="_blank" rel='noreferrer'>
                                                                    <img src={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/inspection-report-images/download/${data}`} alt="" />
                                                                </a>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-2">
                        <div className="ins-report">
                            <div className="add-report">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="cmt-box">
                                            <h5>3. Did any visible light from the ceiling?</h5>
                                            <p className="iv-and mng-id">Answer:<span> &nbsp;{inspectionReport[0].anyVisibleLightFromCeiling.status ? "Yes" : "No"}</span></p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <hr />
                                        <div className="pic-details">
                                            {inspectionReport[0].anyVisibleLightFromCeiling.images.length > 0 && <div className="no-of-pic py-0 my-0 float-start">
                                                <ul>
                                                    {
                                                        inspectionReport[0].anyVisibleLightFromCeiling.images.map((data) => (
                                                            <li>
                                                                <a href={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/inspection-report-images/download/${data}`} target="_blank" rel='noreferrer'>
                                                                    <img src={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/inspection-report-images/download/${data}`} alt="" />
                                                                </a>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-2">
                        <div className="ins-report">
                            <div className="add-report">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="cmt-box">
                                            <h5>4. Did any light from the sidewalls?</h5>
                                            <p className="iv-and mng-id">Answer:<span> &nbsp;{inspectionReport[0].anyVisibleLightFromSidewalls.status ? "Yes" : "No"}</span></p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <hr />
                                        <div className="pic-details">
                                            {inspectionReport[0].anyVisibleLightFromSidewalls.images.length > 0 && <div className="no-of-pic py-0 my-0 float-start">
                                                <ul>
                                                    {
                                                        inspectionReport[0].anyVisibleLightFromSidewalls.images.map((data) => (
                                                            <li>
                                                                <a href={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/inspection-report-images/download/${data}`} target="_blank" rel='noreferrer'>
                                                                    <img src={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/inspection-report-images/download/${data}`} alt="" />
                                                                </a>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-2">
                        <div className="ins-report">
                            <div className="add-report">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="cmt-box">
                                            <h5>5. Did any holes on the floor?</h5>
                                            <p className="iv-and mng-id">Answer:<span> &nbsp;{inspectionReport[0].holesOnFlooring.status ? "Yes" : "No"}</span></p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <hr />
                                        <div className="pic-details">
                                            {inspectionReport[0].holesOnFlooring.images.length > 0 && <div className="no-of-pic py-0 my-0 float-start">
                                                <ul>
                                                    {
                                                        inspectionReport[0].holesOnFlooring.images.map((data) => (
                                                            <li>
                                                                <a href={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/inspection-report-images/download/${data}`} target="_blank" rel='noreferrer'>
                                                                    <img src={data.length > 50 ? data : `${URLS.BACKEND_URL}/api/profileImages/inspection-report-images/download/${data}`} alt="" />
                                                                </a>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {inspectionReport.length > 0 && (arr[0].user.roleName === 'shipping_manager' || arr[0].user.roleName === 'origin_loading_manager') && <div className="row mt-2">
                        <div className="col-md-12">
                            <div className="form-group custom-input mt-4 text-center">
                                <button className="btn-style-one text-center" onClick={() => navigate(`/inspection-report/${id}/edit`)} >
                                    Update Report
                                </button>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>}
            {inspectionReport.length === 0 && (arr[0].user.roleName === 'shipping_manager' || arr[0].user.roleName === 'origin_loading_manager') && <AddInspectionReport id={id} statusValue={status} />}
            {/* Code for inspection report has not been added. */}
            {inspectionReport.length === 0 && arr[0].user.roleName === 'destination_loading_manager' && <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="goto-back">
                            <Link to="/dashboard"><img className="" src={backIcon} alt="" />
                                <span> Inspect View </span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-12">
                        <div className="ins-report">
                            <div className="add-report">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="cmt-box">
                                            <h5>Inspection Report Not Added Yet.</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default InspectionnReport